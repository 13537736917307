import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateVendorSettings } from "store/vendor/vendorActions";
import { setVendorSettings } from "store/vendor/vendorSlice";
import img_1 from "../../../../assets/images/dashboardImages/pay/01.svg";
import img_2 from "../../../../assets/images/dashboardImages/pay/02.svg";
import img_3 from "../../../../assets/images/dashboardImages/pay/03.svg";

const SettingsPay = () => {
  const dispatch = useDispatch();
  const user = useGetAuthUser();
  const isVendor = user?.type === "vendor";

  const vendorSettings = useSelector((state) => state.vendor.vendorSettings);
  const [formValues, setFormValues] = useState({
    online_payment: false,
    cash_payment: false,
  });

  useEffect(() => {
    if (vendorSettings) {
      const { accept_payment } = vendorSettings; // cash, online or both
      if (accept_payment === "cash") {
        setFormValues({
          online_payment: false,
          cash_payment: true,
        });
      } else if (accept_payment === "online") {
        setFormValues({
          online_payment: true,
          cash_payment: false,
        });
      } else if (accept_payment === "both") {
        setFormValues({
          online_payment: true,
          cash_payment: true,
        });
      }
    }
  }, [vendorSettings]);

  const handleCheckboxChange = (field) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const accept_payment =
      formValues.online_payment && formValues.cash_payment
        ? "both"
        : formValues.online_payment
          ? "online"
          : "cash";
    console.log("accept_payment: ", accept_payment);

    const values = {
      ...vendorSettings,
      accept_payment,
    };
    console.log("values: ", values);

    const response = await dispatch(updateVendorSettings(values)).unwrap();
    if (response.success === true) {
      dispatch(setVendorSettings(values));
      toast.success("تم تحديث الإعدادات بنجاح");
      if (isVendor) {
        navigate("/dashboard/vendor/settings");
      } else {
        navigate("/dashboard/admin/settings");
      }
    }
  };

  return (
    <>
      <HelmetInfo titlePage={"طريقة الدفع"} />
      <div className="header-page-w" data-aos="fade-left">
        <HeaderPageInfo
          title={"طريقة الدفع"}
          isShowLeftContent={true}
          contentPageLeft={
            <div className="link-back">
              <Link
                className="size-text-color-dark main-text-color fs-5"
                to={isVendor ? "/dashboard/vendor/settings" : "/dashboard/admin/settings"}>
                عودة
              </Link>
            </div>
          }
        />
      </div>
      <form onSubmit={handleSubmit} className="all-cards-pay mt-3" data-aos="fade-up">
        <>
          <label
            className={`card-pay-1 mb-3 d-flex align-items-center gap-3`}
            htmlFor="flexCheckDefault">
            <input
              type="checkbox"
              className="form-check-input"
              checked={formValues.online_payment}
              onChange={() => handleCheckboxChange("online_payment")}
              id="flexCheckDefault"
            />
            <div className="card-content d-flex  align-items-center  flex-wrap gap-3">
              <h2 className="title">الدفع اون لاين</h2>
              <div className="img-pays d-flex  align-items-center  gap-2 flex-wrap ">
                <div className="img-1 ">
                  <img src={img_1} alt="img pay" />
                </div>
                <div className="img-1">
                  <img src={img_2} alt="img pay" />
                </div>
                <div className="img-1">
                  <img src={img_3} alt="img pay" />
                </div>
              </div>
            </div>
          </label>
          <label
            className={`card-pay-1 mb-3 d-flex  align-items-center gap-3`}
            htmlFor="flexCheckDefault2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={formValues.cash_payment}
              onChange={() => handleCheckboxChange("cash_payment")}
              id="flexCheckDefault2"
            />
            <div className="card-content d-flex  align-items-center  flex-wrap gap-3">
              <h2 className="title">الدفع كاش</h2>
            </div>
          </label>
          <button
            type="submit"
            className="main-btn w-20 mt-4"
            disabled={!formValues.online_payment && !formValues.cash_payment}>
            حفظ التغييرات
          </button>
        </>
      </form>
    </>
  );
};

export default SettingsPay;

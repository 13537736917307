import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getServices } from "../../../../store/admin/adminActions";

const AdminReportServices = ({ bookingDay, setReportData, setReportHeaders }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [totalServicesCount, setTotalServicesCount] = useState(0);

  const fetchServicesReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["booking"],
      per_page: 500,
      limit: 500,
      sort: "DESC",
      paginate: "true",
      from_date: bookingDay,
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name_ar"],
      columns: ["created_at"],
      operand: [">="],
      column_values: [bookingDay],
    };
    dispatch(getServices(params))
      .then(unwrapResult)
      .then((res) => {
        setServices(res.data.data);
        setTotalServicesCount(res.data.total);
        console.log({ result: res.data.data });

        // Prepare data for PDF
        const reportData = res.data.data.map((service) => ({
          name: service.name_ar || "",
          price: service.price || 0,
          bookings_count: service.bookings_count || 0,
          status: service.is_active === "1" ? "نشط" : "غير نشط",
        }));

        setReportData(reportData);
        setReportHeaders(["الخدمة", "السعر", "عدد الحجوزات", "الحالة"]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, bookingDay, setReportData, setReportHeaders]);

  useEffect(() => {
    fetchServicesReport();
  }, [fetchServicesReport]);

  if (isLoading) return <Loader />;
  if (!services.length) return null;

  const sortedServices = [...new Map(services.map((s) => [s.id, s])).values()].sort(
    (a, b) => b.bookings_count - a.bookings_count,
  );
  const newServices = sortedServices.filter(
    (service) => new Date(service.created_at) >= new Date(bookingDay),
  );

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{newServices.length}</h3>
            <p>عدد الخدمات</p>
          </div>
          <FontAwesomeIcon icon={faList} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">الخدمات</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الخدمة</th>
              <th>عدد الحجوزات</th>
              <th>الحالة</th>
            </tr>
          </thead>
          <tbody>
            {newServices.map((service, index) => (
              <tr key={service.id}>
                <td>{index + 1}</td>
                <td>{service.name_ar}</td>
                <td>{service.bookings_count}</td>
                <td>{service.is_active === "1" ? "نشط" : "غير نشط"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportServices;

import PrivacyContent from "Components/Ui/PrivacyContent/PrivacyContent";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getVendorPolicy } from "store/customer/customerActions";

export const ProviderPolicy = ({ bookingDetails }) => {
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const branchDetails = bookingDetails?.branchDetails;
  const vendorId = branchDetails?.vendor?.id;

  useEffect(() => {
    const fetchPolicies = async () => {
      if (!vendorId) {
        setError("معرف مزود الخدمة غير متوفر");
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);
        const response = await dispatch(getVendorPolicy(vendorId)).unwrap();
        const { data } = response;
        const { privacy, terms } = data;

        const termsContent = terms?.content;
        if (!termsContent) {
          setError("لا توجد سياسات متاحة");
          return;
        }

        const lines = termsContent.split("\n");
        const filteredLines = lines.filter((line) => line.trim() !== "");
        setPolicies(filteredLines);
      } catch (err) {
        setError("حدث خطأ أثناء تحميل السياسات");
        toast.error("فشل في تحميل سياسات مزود الخدمة");
        console.error("Error fetching vendor policies:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPolicies();
  }, [dispatch, vendorId]);

  if (isLoading) {
    return (
      <div className="bottom-text text-center">
        <p className="text size-text-color-gray">جاري تحميل السياسات...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bottom-text">
        <p className="text size-text-color-gray">{error}</p>
      </div>
    );
  }

  if (!policies.length) {
    return (
      <div className="bottom-text text-center">
        <p className="text size-text-color-gray">لا توجد سياسات متاحة</p>
      </div>
    );
  }

  return (
    <div className="bottom-text">
      <PrivacyContent
        titleContent="سياسة الحجز"
        textContent={
          <>
            {policies.map((policy, index) => (
              <p
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontSize: "14px",
                  color: "#666",
                }}
                key={index}>
                {policy}
              </p>
            ))}
          </>
        }
      />
    </div>
  );
};

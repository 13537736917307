import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import PageTilte from "Components/PageTilte/PageTilte";
import CardProduct from "Components/Ui/CardProduct/ProviderCard";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { listWishList } from "store/customer/customerActions";
import locationIcon from "../../assets/images/icons/location.svg";
import starIcon from "../../assets/images/icons/star.svg";
import imgCard from "../../assets/images/products/01.png";
import { extractFavoriteProductInfo } from "../../utlis";

const FavoriteProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const wishlist = useSelector((state) => state.customer.wishList);
  const user = useSelector((state) => state.auth.user);

  const getFavoriteProducts = useCallback(
    (params) => {
      dispatch(listWishList(params))
        .then(unwrapResult)
        .then((res) => {
          const vendorsFavResponse = res.data.data;
          const vendorsFav = vendorsFavResponse.map((item) => extractFavoriteProductInfo(item));
          console.log("vendorsFav", vendorsFav);
          setFavoriteProducts(vendorsFav);
        })
        .catch((err) => {
          toast.error(err.message);
          console.log("err", err);
        });
    },
    [dispatch, user?.id],
  );

  useEffect(() => {
    if (!user?.id) return;
    if (!wishlist || !wishlist.length) {
      const params = {
        with: ["vendor", "vendor.branches"],
        limit: 50,
        offset: 0,
        sort: "DESC",
        paginate: "true",
        field: "id",
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["created_by", "deleted_at"],
        operand: ["=", "="],
        column_values: [user.id, null],
      };
      getFavoriteProducts(params);
    } else {
      const vendorsListFav = wishlist.map(extractFavoriteProductInfo);
      setFavoriteProducts(vendorsListFav);
    }
  }, [getFavoriteProducts, wishlist, user]);

  return (
    <>
      <HelmetInfo titlePage={"المفضلة"} />
      <div className="favorite-products padding-bottom-70">
        <PageTilte titlePage={"المفضلة"} />
        <div className="container" style={{ minHeight: "100vh" }}>
          {favoriteProducts.length ? (
            <div className="all-favorite-products">
              <div className="row g-3">
                {favoriteProducts.map((product, index) => {
                  const { id: branchId } = product.branches[0];

                  return (
                    <div
                      key={index}
                      className="col-12 col-sm-6 col-md-4 col-lg-3"
                      onClick={() => navigate(`/servicesproviders/${branchId}`)}>
                      <CardProduct
                        bgColorBadge={false}
                        textBadge={false}
                        newClassBadge={"badge-product-fav d-none"}
                        isIconFavorite={true}
                        imgCard={product.productImage ?? imgCard}
                        cardTitle={product.productName}
                        cardSubTitle={product.productBranchAddress}
                        cardPrice={product.isProductActive ? "متاح" : "غير متاح"}
                        locationIcon={locationIcon}
                        textLocation={product.productBranchAddress}
                        starIcon={starIcon}
                        numRate={"4.5"}
                        routeCard={`/serviceproviders/${branchId}`}
                        textButton={"القسم"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="text-center">
              <h3 className="text-center">لا يوجد منتجات مفضلة.</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FavoriteProducts;

import { faEdit, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "Components/CustomModal/CustomModal";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteAdminBranch, getAdminBranches, updateAdminBranch } from "store/admin/adminActions";
import { getCities, getCountries, getRegions } from "store/general/generalActions";
import { setCities, setCountries, setRegions } from "store/general/generalSlice";

const BranchesAdmin = () => {
  const { cities, regions, countries } = useSelector((state) => state.general);

  const [branches, setBranches] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [branchToDelete, setBranchToDelete] = useState(null);

  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useMemo(
    () => ({
      with: ["manager", "vendor"],
      deleted: "undeleted", //all,deleted
      resource: "all", //all,custom
      per_page: limit,
      limit: limit,
      offset: (currentPage - 1) * limit,
      paginate: "true",
      sort: "DESC",
      page: currentPage,
      columns: ["name"],
      operand: ["like"],
      column_values: [searchText],
    }),
    [limit, currentPage, searchText],
  );

  const fetchBranches = useCallback(
    async (params) => {
      setIsLoading(true);
      try {
        const res = await dispatch(getAdminBranches(params)).then(unwrapResult);
        console.log("branches", res.data.data);
        setBranches(res.data.data || []);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links?.length - 2 || 0);
      } catch (error) {
        toast.error("Failed to fetch branches. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  const handleToggleSwitch = useCallback(
    async (newValue, itemId) => {
      setIsLoading(true);
      try {
        const res = await dispatch(
          updateAdminBranch({
            id: itemId,
            is_active: newValue ? "1" : "0",
          }),
        ).unwrap();

        if (res.success) {
          toast.success("تم التعديل بنجاح.");
          fetchBranches(params);
        }
      } catch (error) {
        toast.error("فشل في تحديث الفرع. يرجى المحاولة مرة أخرى.");
      }
      setIsLoading(false);
    },
    [dispatch, params, fetchBranches],
  );

  useEffect(() => {
    fetchBranches(params);
  }, [fetchBranches, params]);

  useEffect(() => {
    if (!cities || !Object.keys(cities)?.length) {
      const cities = dispatch(getCities()).unwrap();
      cities.then((res) => {
        dispatch(setCities(res.data.data));
      });
    }
    if (!regions || !Object.keys(regions)?.length) {
      const regions = dispatch(getRegions()).unwrap();
      regions.then((res) => {
        dispatch(setRegions(res.data.data));
      });
    }
    if (!countries || !Object.keys(countries)?.length) {
      const countries = dispatch(getCountries()).unwrap();
      countries.then((res) => {
        dispatch(setCountries(res.data.data));
      });
    }
  }, [dispatch]);

  const handleDeleteBranch = useCallback(async (id) => {
    setBranchToDelete(id);
    setShowDeleteModal(true);
  }, []);

  const confirmDelete = useCallback(async () => {
    if (!branchToDelete) return;

    setIsLoading(true);
    try {
      await dispatch(deleteAdminBranch(branchToDelete)).unwrap();
      fetchBranches(params);
      toast.success("تم حذف الفرع بنجاح");
    } catch (error) {
      toast.error("فشل في حذف الفرع. يرجى المحاولة مرة أخرى.");
    } finally {
      setIsLoading(false);
      setShowDeleteModal(false);
      setBranchToDelete(null);
      fetchBranches(params);
    }
  }, [branchToDelete, dispatch, params, fetchBranches]);

  if (!countries || !countries?.length) return <Loader />;
  if (!regions || !regions?.length) return <Loader />;
  if (!cities || !cities?.length) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage={"الفروع"} />
      {/* Delete Confirmation Modal */}
      <CustomModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        title={"تأكيد الحذف"}
        newClass={"delete-confirmation-modal"}>
        <div className="text-center">
          <p>هل أنت متأكد من حذف هذا الفرع؟</p>
          <div className="d-flex justify-content-center gap-2 mt-4">
            <button className="main-btn" onClick={confirmDelete}>
              نعم، حذف
            </button>
            <button className="main-btn main-outline-btn" onClick={() => setShowDeleteModal(false)}>
              إلغاء
            </button>
          </div>
        </div>
      </CustomModal>
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"الفروع"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={true}
              typeIcon={faPlus}
              textButton={"اضافة فرع جديد"}
              functionButton={() => navigate("/dashboard/admin/admin-branches/add")}
              onSearchChange={setSearchText}
            />
          }
        />
      </div>
      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">اسم الفرع</th>
                <th>مزود الخدمة</th>
                <th scope="col">الدولة</th>
                <th scope="col">المنطقة</th>
                <th scope="col">المدينة</th>
                <th scope="col">تفعيل/الغاء</th>
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {branches.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.vendor?.name}</td>
                    <td>
                      {
                        countries?.find((country) => String(country.id) === String(item.country_id))
                          ?.name_ar
                      }
                    </td>
                    <td>
                      {
                        regions?.find((region) => String(region.id) === String(item.region_id))
                          ?.name_ar
                      }
                    </td>
                    <td>
                      {cities?.find((city) => String(city.id) === String(item.city_id))?.name_ar}
                    </td>
                    <td>
                      <Toggleswitch
                        switchId={item.id}
                        nameSwitch={`services-${item.id}`}
                        value={item.is_active === "1"}
                        onChange={(newValue) => handleToggleSwitch(newValue, item.id)}
                      />
                    </td>
                    <td>
                      <IconsTable
                        typeIcon1={faEye}
                        functionEye={() => navigate(`databranches/${item.id}`)}
                        typeIcon2={faEdit}
                        functionEdit={() => navigate(`databranches-edit/${item.id}`)}
                        isDeleteIcon={true}
                        typeIcon3={faTrash}
                        functionDelete={() => handleDeleteBranch(item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default BranchesAdmin;

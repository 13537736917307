import { Form, Formik } from "formik";

const FormField = ({ initialValues, validationSchema, onSubmit, children, innerRef }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={innerRef}>
      <Form>{children}</Form>
    </Formik>
  );
};

export default FormField;

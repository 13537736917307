import { faEye } from "@fortawesome/free-solid-svg-icons";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useCallback, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAdminWallets, updateAdminWallet } from "store/general/generalActions";
import "./ProviderWallets.css";

const ProviderWallets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [wallets, setWallets] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [requestParams, setRequestParams] = useState({
    with: ["user"],
    limit: 10,
    offset: 1,
    sort: "DESC",
    paginate: "true",
    field: "updated_at",
    deleted: "undeleted",
    resource: "all",
    resource_columns: ["id", "name_ar"],
  });

  const fetchWallets = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(getAdminWallets(requestParams)).unwrap();
      const vendorWallets = response.data.data.filter((wallet) => wallet.user.type === "vendor");
      setWallets(vendorWallets);

      setCurrentPageItemCounts(vendorWallets.length);
      setTotalPagesCount(Math.ceil(response.data.total / requestParams.limit));
      setTotalItemsCount(response.data.total);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, requestParams]);

  const handleToggleSwitch = useCallback(
    async (newValue, index) => {
      const wallet = wallets[index];
      delete wallet.user;
      try {
        await dispatch(updateAdminWallet({ ...wallet, is_active: newValue ? "1" : "0" })).unwrap();
        fetchWallets();
      } catch (err) {
        const { errors } = err;
        if (errors) {
          if (errors.balance) {
            toast.error("يجب ان يكون هناك رصيد في المحفظة");
          }
        }
        console.error(err);
      }
    },
    [dispatch, fetchWallets, wallets],
  );

  const handleSearchChange = useCallback((value) => {
    setRequestParams((prev) => ({
      ...prev,
      search: value,
      offset: 1,
    }));
  }, []);

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  return (
    <>
      <HelmetInfo titlePage={"محافظ مقدمي الخدمة"} />
      <div className="main-header-employee" data-aos="fade-down">
        <HeaderPageInfo
          title={"محافظ مقدمي الخدمة"}
          isShowLeftContent={true}
          contentPageLeft={<SearchHeaderPage onSearchChange={handleSearchChange} />}
        />

        {isLoading ? (
          <div className="loader-content">
            <Loader />
          </div>
        ) : (
          <div className="table-content mt-3 table-content-2">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">الاسم</th>
                  <th scope="col">رقم الهاتف</th>
                  <th scope="col">البريد الإلكتروني</th>
                  <th scope="col">الرصيد</th>
                  {/* <th scope="col">الحالة</th> */}
                  <th scope="col">الإجراءات</th>
                </>
              }
              dataTbody={wallets?.map((wallet, index) => (
                <tr key={wallet.id}>
                  <td>{wallet.user.name}</td>
                  <td>{wallet.user.phone}</td>
                  <td>{wallet.user.email}</td>
                  <td>{wallet.balance}</td>
                  {/* <td>
                    <Toggleswitch
                      switchId={wallet.id}
                      nameSwitch={`wallet-${wallet.id}`}
                      value={wallet.is_active === "1"}
                      onChange={(newValue) => handleToggleSwitch(newValue, index)}
                    />
                  </td> */}
                  <td>
                    <IconsTable typeIcon1={faEye} functionEye={() => navigate(`${wallet.id}`)} />
                  </td>
                </tr>
              ))}
            />
            <Pagination
              limit={requestParams.limit}
              setLimit={(limit) =>
                setRequestParams((prev) => ({
                  ...prev,
                  limit,
                  offset: 1,
                }))
              }
              currentPage={requestParams.offset}
              setCurrentPage={(page) =>
                setRequestParams((prev) => ({
                  ...prev,
                  offset: page,
                }))
              }
              limitOptions={[5, 10, 20, 30, 40]}
              currentPageItemsCount={currentPageItemCounts}
              totalItemsCount={totalItemsCount}
              totalPagesCount={totalPagesCount}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ProviderWallets;

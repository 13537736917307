import { faEdit, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { deleteCategory, getCategories, updateCategory } from "store/general/generalActions";
import AddCategoryModal from "./AddCategoryModal";

const ServiceCategories = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCategoryDetailsModal, setShowCategoryDetailsModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCategories = useCallback(
    async (
      params = {
        limit,
        offset: currentPage,
        paginate: "true",
        page: currentPage,
        per_page: limit,
      },
    ) => {
      setIsLoading(true);
      try {
        const res = unwrapResult(await dispatch(getCategories(params)));
        setCategories(res.data.data || []);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(Math.ceil(res.data.total / limit) || 0);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("فشل في جلب الفئات. يرجى المحاولة مرة أخرى.");
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, currentPage, limit],
  );

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories, currentPage, limit]);

  const handleToggleSwitch = useCallback(
    async (newValue, categoryId) => {
      setIsLoading(true);
      try {
        const category = categories.find((cat) => cat.id === categoryId);
        if (!category) throw new Error("فئة غير موجودة");
        const res = await dispatch(
          updateCategory({
            id: categoryId,
            name_ar: category.name_ar,
            name_en: category.name_en,
            is_active: newValue ? "1" : "0",
          }),
        ).unwrap();
        if (res.success) {
          toast.success("تم التعديل بنجاح.");
          fetchCategories();
        } else {
          throw new Error("فشل تحديث الفئة");
        }
      } catch (error) {
        console.error("Error updating category:", error);
        toast.error("فشل في تحديث الفئة. يرجى المحاولة مرة أخرى.");
      } finally {
        setIsLoading(false);
      }
    },
    [categories, dispatch, fetchCategories],
  );

  const handleDeleteCategory = useCallback(
    async (id) => {
      if (window.confirm("هل أنت متأكد أنك تريد حذف هذه الفئة؟")) {
        setIsLoading(true);
        try {
          const res = await dispatch(deleteCategory(id)).unwrap();
          if (res.success) {
            toast.success("تم الحذف بنجاح.");
            fetchCategories();
          } else {
            throw new Error("فشل حذف الفئة");
          }
        } catch (error) {
          console.error("Error deleting category:", error);
          toast.error("فشل في حذف الفئة. يرجى المحاولة مرة أخرى.");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [dispatch, fetchCategories],
  );

  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => setShowAddModal(false);

  const renderTableContent = () => (
    <TableDataInfo
      titleTableHeader={false}
      isHashTrue={false}
      hashId={false}
      dateThead={
        <>
          <th scope="col">الرقم التعريفي</th>
          <th scope="col">الاسم</th>
          <th scope="col">تفعيل/الغاء</th>
          <th scope="col">الاجراءات</th>
        </>
      }
      dataTbody={
        <>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>#{category.id}</td>
              <td>{category.name_ar}</td>
              <td>
                <Toggleswitch
                  switchId={category.id}
                  nameSwitch={`category-${category.id}`}
                  value={category.is_active === "1"}
                  onChange={(newValue) => handleToggleSwitch(newValue, category.id)}
                />
              </td>
              <td>
                <IconsTable
                  typeIcon1={faEye}
                  functionEye={() => {
                    setSelectedCategory(category);
                    setShowCategoryDetailsModal(true);
                  }}
                  typeIcon2={faEdit}
                  functionEdit={() =>
                    navigate(`/dashboard/admin/servicesCategories/${category.id}/edit`)
                  }
                  typeIcon3={faTrash}
                  functionDelete={() => handleDeleteCategory(category.id)}
                />
              </td>
            </tr>
          ))}
        </>
      }
    />
  );

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage="الفئات" />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title="الفئات"
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              showSearch={false}
              isButtonActive={true}
              typeIcon={faPlus}
              textButton="اضافة فئة جديدة"
              functionButton={handleShowAddModal}
            />
          }
        />
      </div>
      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {renderTableContent()}
      </div>

      {/* Add Category Modal   */}
      <AddCategoryModal
        show={showAddModal}
        handleClose={handleCloseAddModal}
        refreshCategories={fetchCategories}
      />
      {/* Category Details Modal   */}
      <CategoryDetailsModal
        show={showCategoryDetailsModal}
        handleClose={() => setShowCategoryDetailsModal(false)}
        category={selectedCategory}
      />

      <Pagination
        limit={limit}
        setLimit={(value) => setLimit(value)}
        currentPage={currentPage}
        setCurrentPage={(value) => setCurrentPage(value)}
        limitOptions={[5, 10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
        isShowTotalPagesCount={true}
      />
    </>
  );
};

const CategoryDetailsModal = ({ show, handleClose, category }) => {
  if (!category) return null;
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static" autoFocus>
      <Modal.Header closeButton className="border-1">
        <Modal.Title>تفاصيل الفئة</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="d-flex flex-column gap-3">
          <p className="fw-bold">الاسم بالعربية: {category.name_ar}</p>
          <p className="fw-bold">الاسم بالإنجليزية: {category.name_en}</p>
          <p className="fw-bold">الحالة: {category.is_active === "1" ? "مفعل" : "غير مفعل"}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceCategories;

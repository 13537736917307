import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getSearchDetails } from "store/customer/customerActions";
import BranchMap from "../../Dashboard/Components/maps/BranchMap";
import FilterContent from "./FilterContent";
import "./Services.css";

const Services = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentProvider, setCurrentProvider] = useState(null);
  const [servicesProviders, setServicesProviders] = useState([]);
  const fetchRequestParms = useMemo(() => {
    return {
      with: ["vendor", "vendor.branches", "vendor.branches.services"],
      search_text: searchText,
      sort: "DESC",
      field: "id",
      paginate: "true",
      per_page: 50,
      offset: 0,
      order: "DESC",
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name"],
      columns: ["is_active"],
      operand: ["="],
      column_values: ["1"],
    };
  }, [searchText]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getSearchDetails(fetchRequestParms))
      .then(unwrapResult)
      .then((result) => {
        const vendors = result.data.vendors.data;
        const activeVendors = vendors.filter((vendor) => vendor.is_active === "1");
        const vendorsWithServices = activeVendors.filter((vendor) => vendor.services.length > 0);
        setServicesProviders(vendorsWithServices);
        setCurrentProvider(vendorsWithServices[0]);
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchRequestParms]);

  return (
    <>
      <HelmetInfo titlePage={"خدماتنا"} />
      <div className="services-page padding" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="all-services-page">
            <div className="row g-3 flex-wrap-reverse">
              <div className="col-12 col-md-7">
                {isLoading ? (
                  <Loader />
                ) : (
                  <div data-aos="fade-left">
                    <FilterContent
                      servicesProviders={servicesProviders}
                      setSearchText={setSearchText}
                      currentProvider={currentProvider}
                      setCurrentProvider={setCurrentProvider}
                    />
                  </div>
                )}
              </div>
              <div className="col-12 col-md-5 mt-4">
                <div data-aos="fade-left">
                  <BranchMap
                    lat={currentProvider?.branches[0]?.lat}
                    long={currentProvider?.branches[0]?.long}
                    zoom={4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;

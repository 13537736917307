import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customerLogIn } from "../../../store/auth/authActions";
import LoginComponent from "./LoginComponent";

const CustomerLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state) => state.auth.isLogedIn);

  const loginCustomer = useCallback(
    (username, password) => {
      dispatch(customerLogIn({ username, password }))
        .then(unwrapResult)
        .catch((error) => {
          toast.error(error.message);
        });
    },
    [dispatch],
  );

  useEffect(() => {
    if (isLoggedIn) {
      const redirectData = localStorage.getItem("authRedirect");
      if (redirectData) {
        const { path, state } = JSON.parse(redirectData);
        localStorage.removeItem("authRedirect"); // Clean up
        navigate(path, { state });
      } else {
        navigate("/"); // Default fallback
      }
    }
  }, [isLoggedIn, navigate]);

  return (
    <LoginComponent key={1} isWebsite={true} isDashboard={false} loginAndNavigate={loginCustomer} />
  );
};

export default CustomerLogin;

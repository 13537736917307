import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";

const CustomDropdown = ({
  textSelect,
  options = [],
  isShowNumberOption = true,
  isIdShow = false,
  onSelect,
  userType,
  selectedOption: selectedItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedOption(selectedItem || null);
  }, [selectedItem]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  // Memoized label for selected option
  const selectedLabel = useMemo(() => {
    if (selectedOption) {
      return userType === "employee"
        ? `${selectedOption?.user?.first_name} ${selectedOption?.user?.last_name}`
        : `${selectedOption?.name} - ${selectedOption?.phone}`;
    }
    return textSelect;
  }, [selectedOption, userType, textSelect]);

  return (
    <div className={`custom-dropdown ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      <div
        className="selected-option d-flex justify-content-between align-items-center"
        onClick={toggleDropdown}>
        {selectedLabel}
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </div>
      {isOpen && (
        <div className="options">
          {options.length > 0 ? (
            options.map((option, index) => (
              <div
                key={index}
                className="option d-flex align-items-center gap-3"
                onClick={() => handleOptionClick(option)}>
                {isIdShow && <div className="option-id">{option?.id}</div>}
                <div className="option-label">
                  {userType === "employee"
                    ? `${option?.user?.first_name} ${option?.user?.last_name}`
                    : `${option?.name} - ${option?.phone}`}
                </div>
              </div>
            ))
          ) : (
            <div className="option">No options available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BgBody from "Components/Ui/BgBody/BgBody";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import Logo from "Dashboard/Components/Logo/Logo";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./RegisterServiceProviderAccount.css";
import RegisterServicesStepOne from "./RegisterServicesStepOne";
import RegisterServicesStepThree from "./RegisterServicesStepThree";
import RegisterServicesStepTwo from "./RegisterServicesStepTwo";

const RegisterServiceProviderAccount = () => {
  const [registerData, setRegisterData] = useState({
    commercial_no: "",
    provider_name: "",
    tax_number: "",
    // category_id: [],
    description: "",
    website_url: "",
    twitter: "",
    instagram: "",
    snapchat: "",
    registered_tax: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    password: "",
    password_confirmation: "",
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [activeSteps, setActiveSteps] = useState([]);

  const steps = [
    { number: 1, label: "التحقق من بيانات الشركة", Component: RegisterServicesStepOne },
    { number: 2, label: "بيانات النشاط", Component: RegisterServicesStepTwo },
    { number: 3, label: "بيانات مالك العلامة التجارية", Component: RegisterServicesStepThree },
  ];

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
      setActiveSteps([...activeSteps, currentStep]);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setActiveSteps(activeSteps.filter((step) => step !== currentStep - 1));
    }
  };

  const renderStepIndicators = () => (
    <div className="progress-bar">
      {steps.map(({ number, label }) => (
        <div
          key={number}
          className={`step step-${number} ${activeSteps.includes(number) ? "active1" : ""} ${
            currentStep === number ? "active" : ""
          }`}>
          <div className="num-step position-relative">
            <span>{number}</span>
          </div>
          <div className="opaque">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div className="step-label">{label}</div>
        </div>
      ))}
    </div>
  );

  const renderCurrentStep = () => {
    const compIndex = currentStep - 1;
    const { Component } = steps[compIndex];
    return (
      <Component
        currentStep={currentStep}
        handleNext={handleNext}
        handlePrev={handlePrev}
        setRegisterData={setRegisterData}
        registerData={registerData}
      />
    );
  };

  return (
    <>
      <HelmetInfo titlePage={"تسجيل حساب مزود الخدمة"} />
      <BgBody />
      <div className="container">
        <Logo routePage={"/"} />
        <div className="header-info-top header-info-top-1 d-flex justify-content-between align-items-center flex-wrap gap-3">
          <HeaderPageTitle
            isActiveLinkTwo={false}
            routePage={false}
            titlePage={false}
            titleInfoPage={"تسجيل حساب مزود الخدمة"}
            routeHomePage={"/"}
          />
          <div className="link-back">
            <Link className="size-text-color-dark main-text-color fs-5" to="/logindashboard">
              عودة
            </Link>
          </div>
        </div>
      </div>
      <div
        className="all-steps-info all-steps-register-services padding-bottom-70"
        data-aos="fade-up">
        <div className="container">
          <div
            className={`step-${currentStep}`}
            id="checkout-progress"
            data-current-step={currentStep}>
            {renderStepIndicators()}
          </div>
          <div className="main-info-steps-content">{renderCurrentStep()}</div>
        </div>
      </div>
    </>
  );
};

export default RegisterServiceProviderAccount;

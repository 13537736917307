import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img from "../../../../assets/images/booking/01.png";
import iconLocation from "../../../../assets/images/icons/location.svg";
import imgUser from "../../../../assets/images/userImg/user.png";
import "./InfoBookingDetails.css";
import MainOneInfo from "./MainOneInfo";

const HeaderInfoBookingsDetails = ({ booking }) => {
  const totalDuration = booking.servicesList.reduce(
    (total, bookingSvc) => total + parseInt(bookingSvc.service.service_time),
    0,
  );

  return (
    <div className="header-info-booking-details style-card-details">
      <div className="header-top-details  d-flex  align-items-center gap-3 flex-wrap flex-lg-nowrap justify-content-between ">
        <div className="right-details-one d-flex  align-items-center gap-3">
          <div className="img-details">
            <img src={img} alt="img" />
          </div>

          <div className="info-content">
            <h2 className="title">{booking.vendor.name}</h2>

            <div className="rates pb-2 d-flex align-items-center gap-2">
              <div className="stars-rate d-flex  align-items-center gap-1">
                {[...Array(4)].map((_, index) => (
                  <div className="icon-star" key={index}>
                    <FontAwesomeIcon icon={solidStar} />
                  </div>
                ))}
              </div>
              <div className="info-rate-details d-flex  align-items-center gap-1">
                <div className="num-rate text-size">4.3</div>
                {/* <div className="all-rates text-size">(120 تقيم)</div> */}
              </div>
            </div>

            <div className="location-details d-flex  align-items-center gap-1">
              <div className="icon-location">
                <img src={iconLocation} alt="icon location" width={"25px"} height={"25px"} />
              </div>
              <p className="text-location text-size">{booking.vendor.branches[0].address}</p>
            </div>
          </div>
        </div>

        <div className="left-details-one">
          <h2 className="title text-size-02">الموظفين</h2>

          <div className="info-employee d-flex align-items-center gap-3">
            <div className="img-employee">
              <img
                src={imgUser}
                alt="img employee"
                width={"80px"}
                height={"80px"}
                className=" object-fit-cover "
              />
            </div>

            <div className="details-employee">
              <h2 className="name-employee text-size-02">{booking.employee.user.name}</h2>
              <p className="title-job text-size">{booking.employee.job_title || "موظف للفرع"}</p>
            </div>
            {/* 
            <div className="details-rate-employee d-flex  align-items-center gap-2">
              <div className="icon-star text-size-02">
                <FontAwesomeIcon icon={solidStar} />
              </div>
              <div className="num-rate-employee text-size-02">4.5</div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="all-info-main-one pt-4 d-flex  align-items-center ">
        <MainOneInfo
          title={"رقم الحجز"}
          textInfo={booking.id.toString()}
          newClass={"card-info-detials-main"}
        />
        <MainOneInfo
          title={"طريقة الدفع"}
          textInfo={booking.payment_way}
          newClass={"card-info-detials-main"}
        />
        <MainOneInfo
          title={"حالة الدفع"}
          textInfo={booking.payment_status === "0" ? "غير مدفوع" : "مدفوع"}
          newClass={"card-info-detials-main"}
        />
      </div>

      <div className="all-info-main-one pt-4 d-flex  align-items-center">
        <MainOneInfo
          title={"تاريخ انشاء الحجز"}
          textInfo={new Date(booking.created_at).toLocaleString("ar-EG")}
          newClass={"card-info-detials-main"}
        />
        <MainOneInfo
          title={"موعد الحجز"}
          textInfo={`${booking.booking_day} ${booking.booking_time}`}
          newClass={"card-info-detials-main"}
        />
        {/* <MainOneInfo
          title={"حالة الحجز"}
          textInfo={
            <div
              className={`badge-card ${statusList.find((status) => status.value === booking.status)?.badge ?? ""}`}>
              {statusList.find((status) => status.value === booking.status)?.text ?? ""}
            </div>
          }
          newClass={"card-info-detials-main"}
        /> */}
        <MainOneInfo
          title={"مده الحجز"}
          textInfo={`${totalDuration} دقيقة`}
          newClass={"card-info-detials-main"}
        />
      </div>

      {booking.notes !== "no_notes" && (
        <div className="comments-details pt-4">
          <h2 className="title text-size-02">ملاحظات</h2>
          <p className="text text-size">{booking.notes}</p>
        </div>
      )}
    </div>
  );
};

export default HeaderInfoBookingsDetails;

import { useState } from "react";
import iconSearch from "../../../assets/images/icons/search.svg";
import "./SearchBar.css";

const SerachBar = ({ isText, searchProviders }) => {
  const [search, setSearch] = useState("");
  const [section, setSection] = useState("");

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleSectionChange = (e) => setSection(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      search_text: search,
      section: section,
    };
    
    searchProviders(params);
  };

  return (
    <>
      <div className="search-content">
        {isText && <h2 className="title-search">بحث</h2>}
        <form action="">
          <div className="main-search d-flex align-items-center flex-wrap gap-2">
            <div className="form-input-one">
              <label htmlFor="searchForm" className="form-label d-none"></label>
              <div className="input-info d-flex align-items-center">
                <div className="icon">
                  <img src={iconSearch} alt="icon search" />
                </div>
                <input
                  type="search"
                  className="form-control"
                  id="searchForm"
                  placeholder="اسم مزودى الخدمة"
                  onChange={handleSearchChange}
                  value={search}
                />
              </div>
            </div>

            <div className="form-input-one">
              <label htmlFor="sectionInput" className="form-label d-none"></label>
              <div className="input-info d-flex align-items-center">
                <input
                  type="text"
                  className="form-control"
                  id="sectionInput"
                  placeholder=" القسم او الخدمة.."
                  onChange={handleSectionChange}
                  value={section}
                />
              </div>
            </div>

            <button className="main-btn" type="submit" onClick={handleSubmit}>
              بحث
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SerachBar;

import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getVendorWallet, getWalletTransactions } from "store/general/generalActions";
import { getBranches } from "store/vendor/vendorActions";
import { formatDateToArabic } from "utlis";
import imgWallet from "../../../assets/images/dashboardImages/icons/wallet.svg";
import "./Wallet.css";

const noteTypes = new Map([
  ["credit", "اضافة"],
  ["debit", "طرح"],
]);

const Wallet = () => {
  const user = useGetAuthUser();
  const [data, setData] = useState();
  const [transactions, setTransactions] = useState();
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchBranches = async () => {
    try {
      const params = {
        with: ["manager"],
        deleted: "undeleted",
        sort: "DESC",
        resource: "all",
        paginate: "true",
        per_page: 200,
        resource_columns: ["id", "name"],
        columns: ["vendor_id", "is_active"],
        operand: ["=", "="],
        column_values: [user?.model_id, "1"],
      };
      const res = await dispatch(getBranches(params)).then(unwrapResult);
      setBranches(res.data.data || []);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetchWalletData = async (params) => {
    setIsLoading(true);
    try {
      const walletResponse = await dispatch(getVendorWallet(params)).then(unwrapResult);
      const walletData = walletResponse.data.data[0];
      setData(walletData);

      const transactionParams = {
        with: ["branch"],
        limit,
        offset: currentPage,
        paginate: "true",
        sort: "DESC",
        field: "id",
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["wallet_id", "is_active"],
        operand: ["=", "="],
        column_values: [walletData.id, "1"],
      };

      if (selectedBranch !== "") {
        transactionParams.columns = ["branch_id"];
        transactionParams.operand = ["="];
        transactionParams.column_values = [selectedBranch];
      }

      const transactionResponse = await dispatch(getWalletTransactions(transactionParams)).then(
        unwrapResult,
      );

      const { data, total } = transactionResponse.data;
      setTransactions(data);
      setCurrentPageItemCounts(data?.length || 0);
      setTotalItemsCount(total || 0);
      setTotalPagesCount(Math.ceil(total / limit) || 0);
    } catch (error) {
      console.error("Error fetching wallet data:", error);
      // Here you can add toast notification or error handling UI
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    const params = {
      with: ["transactions"],
      limit,
      field: "id",
      paginate: "true",
      resource: "all",
      deleted: "undeleted",
      resource_columns: ["id", "created_at"],
      columns: ["user_id", "is_active"],
      operand: ["=", "="],
      column_values: [user?.id, "1"],
    };

    fetchWalletData(params);
  }, [limit, currentPage, selectedBranch]);

  return (
    <>
      <HelmetInfo titlePage={"المحفظة"} />
      <div data-aos="fade-left">
        <HeaderPageInfo title={"المحفظة"} isShowLeftContent={false} contentPageLeft={false} />
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3 px-3 gap-3">
        <div
          className="header-top-wallet bg-dark-blue rounded d-flex align-items-center"
          style={{ backgroundColor: "#003647" }}>
          <div className="d-flex align-items-center gap-4">
            <div className="content-wallet-top text-white">
              <h2 className="title mb-2">رصيد المتجر</h2>
              <h2 className="info-num">{data?.balance} ريال</h2>
            </div>
            <div className="image-wallet">
              <img src={imgWallet} alt="img wallet" width="80" />
            </div>
          </div>
        </div>

        <select
          className="form-select"
          style={{ width: "200px" }}
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}>
          <option value="">كل الفروع</option>
          {branches.map((branch) => (
            <option key={branch.id} value={branch.id}>
              {branch.name}
            </option>
          ))}
        </select>
      </div>

      <div className="table-content mt-3 table-content-3" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">وصف المعاملة</th>
                <th scope="col">الفرع</th>
                <th scope="col">قيمة المعاملة</th>
                <th scope="col">تاريخ المعاملة</th>
                <th scope="col">نوع المعاملة</th>
              </>
            }
            dataTbody={
              <>
                {transactions?.map((item, index) => {
                  return (
                    <tr key={`${item.id}-${index}`}>
                      <td>{item?.description}</td>
                      <td>{item?.branch?.name ?? "---"}</td>
                      <td>{item?.value} ر.س</td>
                      <td>{formatDateToArabic(item?.created_at)}</td>
                      <td>
                        <span
                          className={` text-${item?.type === "credit" ? "success" : "danger"} `}>
                          {noteTypes.get(item?.type)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default Wallet;

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBookings as getVendorBookings } from "store/vendor/vendorActions";
import "./TaxInvoices.css";

const TaxInvoices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useGetAuthUser();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [selectedBranch, setSelectedBranch] = useState("");
  const branches = useSelector((state) => state.vendor.branches);

  const fetchBookings = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["services", "user", "vendor", "branch"],
      limit: limit,
      offset: (currentPage - 1) * limit,
      sort: "DESC",
      per_page: limit,
      page: currentPage,
      paginate: "true",
      field: "id",
      columns: ["vendor_id", "payment_status"],
      operand: ["=", "="],
      column_values: [user?.model_id, "1"],
    };

    // Add branch filter if selected
    if (selectedBranch) {
      params.columns.push("branch_id");
      params.operand.push("=");
      params.column_values.push(selectedBranch);
    }

    dispatch(getVendorBookings(params))
      .then(unwrapResult)
      .then((res) => {
        setBookings(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, currentPage, limit, user?.model_id, selectedBranch]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage={"الفواتير الضريبية"} />

      <div data-aos="fade-left">
        <HeaderPageInfo title={"الفواتير الضريبية"} isShowLeftContent={true} />
      </div>

      <div className="d-flex justify-content-end mb-3">
        <select
          className="form-select"
          style={{ width: "200px" }}
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}>
          <option value="">كل الفروع</option>
          {branches.map((branch) => (
            <option key={branch.id} value={branch.id}>
              {branch.name}
            </option>
          ))}
        </select>
      </div>

      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        <TableDataInfo
          titleTableHeader={false}
          isHashTrue={false}
          hashId={false}
          dateThead={
            <>
              <th scope="col">كود الفاتورة</th>
              <th scope="col">نوع الفاتورة</th>
              <th scope="col">الفرع</th>
              <th scope="col">تاريخ الفاتورة</th>
              <th scope="col"> اجمالى قيمة الفاتورة</th>
              <th scope="col"> الاجراءات</th>
            </>
          }
          dataTbody={
            <>
              {bookings?.map((booking) => (
                <tr key={booking.id}>
                  <td>{booking.id}</td>
                  <td>{booking.service_type || "خدمة"}</td>
                  <td>{booking.branch?.name || "---"}</td>
                  <td>{booking.created_at}</td>
                  <td>{booking.total}</td>
                  <td>
                    <IconsTable
                      typeIcon1={faEye}
                      functionEye={() => {
                        navigate(`${booking.id}`, {
                          state: {
                            booking,
                          },
                        });
                      }}
                    />
                  </td>
                </tr>
              ))}
            </>
          }
        />
      </div>
      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[5, 10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default TaxInvoices;

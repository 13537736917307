import { faEdit, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteAdminEmployee,
  getAdminEmployees,
  getEmployee as getEmpReport,
  updateAdminEmployee,
} from "store/admin/adminActions";
import Swal from "sweetalert2";

const AdminEmployees = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [empReports, setEmpReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [requestParams, setRequestParams] = useState({
    with: ["user", "vendor", "branches", "services"],
    limit: 10,
    offset: 0,
    sort: "DESC",
    page: currentPage,
    paginate: true,
    field: "updated_at",
    deleted: "undeleted",
  });

  const fetchEmployees = useCallback(() => {
    const params = {
      ...requestParams,
      columns: ["job_title"],
      operand: ["like"],
      column_values: [searchText],
    };

    setIsLoading(true);
    dispatch(getAdminEmployees(params))
      .then(unwrapResult)
      .then((res) => {
        console.log("res: ", res);
        setData(res.data.data);

        const reportPromises = res.data.data.map((emp) =>
          dispatch(getEmpReport(emp.id))
            .unwrap()
            .then((res) => res.data)
            .catch((err) => {
              console.error(`Error fetching report for employee ${emp.id}:`, err);
              return null;
            }),
        );

        Promise.all(reportPromises).then((reports) => {
          setEmpReports(reports.filter(Boolean));
        });

        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
        toast.error("Failed to fetch employees");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, requestParams, searchText]);

  const debouncedSearch = useMemo(() => {
    return debounce((value) => {
      setSearchText(value);
      setRequestParams((prev) => ({
        ...prev,
        offset: 1,
      }));
    }, 500);
  }, []);

  const handleSearchChange = useCallback(
    (value) => {
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchEmployees();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText, fetchEmployees]);

  const toggleActivation = (newValue, userId, index) => {
    setIsLoading(true);
    const employee = data[index];
    delete employee.user;
    delete employee.job_title;
    const newEmpData = { ...employee, is_active: newValue === true ? "1" : "0" };
    if (newEmpData.salary == null) {
      newEmpData.salary = 0;
    }
    console.log({ newEmpData });

    dispatch(updateAdminEmployee(newEmpData))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم التعديل بنجاح.");
        fetchEmployees();
      })
      .catch((error) => {
        console.error("Error updating employee:", error);
        toast.error("Failed to update employee");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteAdminEmployee = (id) => {
    Swal.fire({
      title: "هل أنت متأكد من حذف الموظف؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAdminEmployee(id))
          .then(unwrapResult)
          .then(() => {
            toast.success("تم حذف الموظف بنجاح");
            fetchEmployees();
          });
      }
    });
  };
  return (
    <>
      <HelmetInfo titlePage={"طاقم العمل"} />
      <div className="main-header-employee" data-aos="fade-down">
        <HeaderPageInfo
          title={"طاقم العمل"}
          isShowLeftContent={true}
          contentPageLeft={
            <>
              <SearchHeaderPage
                onSearchChange={handleSearchChange}
                isButtonActive={true}
                typeIcon={faPlus}
                placeholder={"ادخل العنوان الوظيفي"}
                textButton={"اضافة موظف جديد"}
                functionButton={() => navigate("/dashboard/admin/employees/add")}
              />
            </>
          }
        />
      </div>
      {isLoading ? (
        <div className="loader-content">
          <Loader />
        </div>
      ) : (
        <>
          <div className="table-content mt-3 table-content-2" data-aos="fade-up">
            <TableDataInfo
              titleTableHeader={false}
              isHashTrue={false}
              hashId={false}
              dateThead={
                <>
                  <th scope="col">الاسم بالكامل</th>
                  <th>عنوان الوظيفة</th>
                  <th>مقدم الخدمة</th>
                  <th>الفرع</th>
                  <th scope="col">الحجوزات</th>
                  <th scope="col">الفروع</th>
                  <th scope="col">الخدمات</th>
                  <th scope="col">تفعيل/الغاء</th>
                  <th scope="col">الاجراءات</th>
                </>
              }
              dataTbody={
                <>
                  {data.map((empUser, index) => (
                    <tr key={empUser.id}>
                      <td>{empUser.user?.first_name + " " + empUser.user?.last_name}</td>
                      <td>{empUser.job_title || "-"}</td>
                      <td>{empUser.vendor?.name || "-"}</td>
                      <td>{empUser.branches[0]?.name || "-"}</td>
                      <td>
                        {empReports.find((report) => report.id === empUser.id)?.bookings.length ||
                          0}
                      </td>
                      <td>
                        {empReports.find((report) => report.id === empUser.id)?.branches.length ||
                          0}
                      </td>
                      <td>
                        {empReports.find((report) => report.id === empUser.id)?.services.length ||
                          0}
                      </td>
                      <td>
                        <Toggleswitch
                          key={empUser.id + "switch"}
                          switchId={empUser.id}
                          nameSwitch={`question-${empUser.id}`}
                          value={empUser.is_active === "1"}
                          onChange={(newValue) => {
                            toggleActivation(newValue, empUser.id, index);
                          }}
                        />
                      </td>
                      <td>
                        <IconsTable
                          typeIcon1={faEye}
                          functionEye={() => navigate(`${empUser.id}`)}
                          typeIcon2={faEdit}
                          functionEdit={() => navigate(`edit/${empUser.id}`)}
                          isDeleteIcon={true}
                          typeIcon3={faTrash}
                          functionDelete={() => {
                            handleDeleteAdminEmployee(empUser.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              }
            />
          </div>
          <Pagination
            limit={requestParams.limit}
            setLimit={(limit) => setRequestParams((prev) => ({ ...prev, limit, offset: 0 }))}
            currentPage={requestParams.page}
            setCurrentPage={(page) => {
              setCurrentPage(page);
              setRequestParams((prev) => ({ ...prev, page }));
            }}
            limitOptions={[5, 10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
          />
        </>
      )}
    </>
  );
};

export default AdminEmployees;

import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";

const SectionModal = ({
  editMode = false,
  showModalAddTab,
  hideModalAddTab,
  onSave,
  onClose,
  section,
  onSaveEdit,
}) => {
  const [tabName, setTabName] = useState("");
  const [tabDescription, setTabDescription] = useState("");

  useEffect(() => {
    if (editMode) {
      setTabName(section.name_ar);
      setTabDescription(section.description_ar);
    }
  }, [editMode, section]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSection = {
      name: tabName,
      description: tabDescription,
    };
    onSave(newSection);
    hideModalAddTab();
  };

  const handleSaveEdit = (e) => {
    e.preventDefault();
    const newSection = {
      id: section.id,
      name: tabName,
      description: tabDescription,
    };
    onSaveEdit(newSection);
    hideModalAddTab();
  };

  return (
    <CustomModal
      show={showModalAddTab}
      onHide={hideModalAddTab}
      onClose={onClose}
      title={"اضافة تبويب جديد"}
      newClass={"modal-add-tap"}>
      <form onSubmit={editMode ? handleSaveEdit : handleSubmit}>
        <div className="form-one mb-3">
          <label htmlFor="inputTab" className="form-label">
            اسم التبويب
          </label>
          <input
            type="text"
            className="form-control"
            id="inputTab"
            placeholder="اسم التبويب"
            value={tabName}
            onChange={(e) => setTabName(e.target.value)}
            required
          />
        </div>

        <div className="form-one mb-3">
          <label htmlFor="textAreaTab" className="form-label">
            وصف التبويب
          </label>
          <textarea
            className="form-control"
            id="textAreaTab"
            rows={5}
            placeholder="وصف التبويب"
            value={tabDescription}
            onChange={(e) => setTabDescription(e.target.value)}
            required></textarea>
        </div>
        <button type="submit" className="main-btn w-100 mt-3">
          حفظ
        </button>
      </form>
    </CustomModal>
  );
};

export default SectionModal;

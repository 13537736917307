import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "Apis/axiosService";
import axios from "axios";

export const getProfileDetails = createAsyncThunk(
  "customer/getProfileDetails",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/profile");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProfileDetails = createAsyncThunk(
  "customer/updateProfileDetails",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/update-profile", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePassword = createAsyncThunk(
  "customer/updatePassword",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/change-password", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const listBookings = createAsyncThunk(
  "customer/listBookings",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/bookings", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookingById = createAsyncThunk(
  "customer/getBookingById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/bookings/" + id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createBooking = createAsyncThunk(
  "customer/createBooking",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/bookings", body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const payForBooking = createAsyncThunk(
  "customer/payForBooking",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/bookings/pay", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const cancelBooking = createAsyncThunk(
  "customer/cancelBooking",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/bookings/cancel/" + bookingId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const listWishList = createAsyncThunk(
  "customer/listWishList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/wish_lists", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addVendorToWishList = createAsyncThunk(
  "customer/addVendorToWishList",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/wish_lists", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeVendorFromWishList = createAsyncThunk(
  "customer/removeVendorFromWishList",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.delete("/customer/v1/wish_lists/" + id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSearchDetails = createAsyncThunk(
  "customer/getSearchDetails",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/search", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const sendContactUs = createAsyncThunk(
  "customer/sendContactUs",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/contact_messages", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getVendorDetails = createAsyncThunk(
  "customer/getVendorDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/vendor_details?vendor_id=" + id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const checkPaymentStatus = createAsyncThunk(
  "customer/checkPaymentStatus",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/bookings/check_is_paid", {
        booking_id: bookingId,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getVendorSettings = createAsyncThunk(
  "customer/getVendorSettings",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/vendor_settings?vendor_id=" + id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const generateBookingCustomerInvoice = createAsyncThunk(
  "customer/generateBookingCustomerInvoice",
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(
        "/customer/v1/booking_customer_invoice",
        {
          booking_id: bookingId,
        },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        },
      );
      console.log("response", response);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// reset password
// body: {username: "email or phone"}
export const resetPassword = createAsyncThunk(
  "customer/resetPassword",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/reset", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// body: {code: "code", username: "email or phone"}
export const resetPasswordCheckCode = createAsyncThunk(
  "customer/resetPasswordCheckCode",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/check-code", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// body: { username: "email or phone", password: "password", password_confirmation}
export const resetPasswordConfirm = createAsyncThunk(
  "customer/resetPasswordConfirm",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/customer/v1/confirm-reset", body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addReview = createAsyncThunk(
  "customer/addReview",
  async (values, { rejectWithValue }) => {
    try {
      const url = "https://api.streamline.com.sa/api/v1/reviews";
      const token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOThmODgxZWQyNjIzY2NjYWNjMTdhYzc2MGRiMDFmZGZmMWVmODdhOThiYTIzNGQyNTM4ODM3N2NmMzQ4NzU1Yzg1NmNmNGM3MTBlMWQ5MTEiLCJpYXQiOjE3MjUzMTMwODguNDAxMTU5LCJuYmYiOjE3MjUzMTMwODguNDAxMTYzLCJleHAiOjE3NTY4NDkwODguMzk5NTcxLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.VvWgOXN4qhqTLWbIUJs69u8V_7HzWTu8QoaX1tSvhMviQ0waL6Fxe9TO69yFxJ_1jRZtDAZ5FjVqi7rgSKwduhEN7DfywcZUXLdKPxxEDaD20_CLyk7F3WdR_xD45pEbkARxMBPdPCtdpPfm2UnjknGsp_sG4slYepYeK0kUb2ohvNvZWX-sbijAtCrB6JUsmg19u8Db4hGi_CDjwJJjc72uhYjQ2S7QU4aqM4JTw1R9LltdULxQpPg8PFEcL5TdYDmjZx27iPv7fn_Nco51Ur_JYFobqhihb_yfeF7UbDUIN8HZk9c5RKIWHqcmZv96BVxOgnUTZONJtLoXp_W46EZEdV_cospsIQw_rhd8fs1XePaLs8ptfND1zTn8JIUawNrXoCSF2FVMpHhyCZ3qkpMF57jcT3V02uSp28gW5FpSoNaXRRByT4QB6HVBeJPFPkSuZpBCozL9Od6lD2SYm9YJmH_n9IC3_wG18dxLYVFthA3W0s8PT1Z2eZR0L5Uxmmtxmw4bOqj0oBzOTUA-LOe8xGJbMvdOXP0tJ72H26XWlq5vNZFC9QpiqVXZGMYkdFeWqvHXZyABtQiQVH98Vt80LPVwYhw5qfsX6vwjN-_pfkP2_a-VIBawc4bjE9VuqcJTTOcxx2RK9xRaUzxVWetpAjS2GlvGOD7tEKU46Ys";
      const response = await axios.post(url, values, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getVendorPolicy = createAsyncThunk(
  "customer/getVendorPolicy",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/customer/v1/vendor_policy?vendor_id=" + id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCities, getCountries, getRegions } from "store/general/generalActions";
import { getBranch } from "store/vendor/vendorActions";
import "./Branches.css";

const BranchData = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [branch, setBranch] = useState(null);
  console.log({ branch });

  const [isLoading, setIsLoading] = useState(false);
  const { cities, regions, countries } = useSelector((state) => state.general);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const branch = await dispatch(getBranch(id)).then(unwrapResult);
        setBranch(branch.data);

        if (cities && cities?.length === 0) dispatch(getCities());
        if (regions && regions?.length === 0) dispatch(getRegions());
        if (countries && countries?.length === 0) dispatch(getCountries());
      } catch (error) {
        console.error("Error fetching branch data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, dispatch]);

  const navigate = useNavigate();

  const renderEmployeeList = () => (
    <ul className="m-0 p-0 d-flex align-items-center flex-wrap gap-3">
      {branch?.employees?.map((employee) => (
        <li key={`employee-${employee.id}`}>
          <div className="card p-2 shadow-sm">
            <div
              className="d-flex align-items-center gap-2 cursor-pointer text-decoration-none"
              onClick={() => navigate(`/dashboard/vendor/employees/${employee.id}`)}>
              <span>{employee.user.name}</span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  const renderWorkingHours = () => (
    <ul className="m-0 p-0">
      {branch?.official_hours?.map((time) => (
        <li
          key={`time-${time.id}`}
          className="d-flex flex-row align-items-center p-1 gap-2 mb-2 card shadow-sm">
          <span>{time.start_time}</span>
          <span>-</span>
          <span>{time.end_time}</span>
        </li>
      ))}
    </ul>
  );

  const renderMapDetails = () => (
    <div className="d-flex flex-column card shadow-sm p-2">
      <span>{`${branch?.lat} - ${branch?.long}`}</span>
    </div>
  );

  const branchInfo = [
    { title: "اسم الفرع", value: branch?.name },
    { title: "موظفى الفرع", value: renderEmployeeList() },
    { title: "عنوان الفرع", value: branch?.address },
    { title: "الدولة", value: countries?.find((c) => c.id === branch?.country_id)?.name_ar },
    { title: "المدينة", value: cities?.find((c) => c.id === branch?.city_id)?.name_ar },
    { title: "الموقع على الخريطة", value: renderMapDetails() },
    { title: "ساعات العمل الرسمية", value: renderWorkingHours() },
  ];

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage="التفاصيل" />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          routePage={false}
          routeHomePage="/dashboard/vendor/homedashboard"
          titlePage="الرئيسية"
          titleInfoPage="التفاصيل"
        />
        <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/vendor/branches">
          عودة
        </Link>
      </div>

      <div className="basic-information-content account-info-details mt-3">
        <div className="all-basic-info all-details-branch">
          {/* Manager Info */}
          <div className="header-info-profile">
            {/* Branch Info */}
            <div className="content-user-info">
              {branchInfo.map((item, index) => (
                <div className="all-info-content d-flex" key={index}>
                  <div className="main-info-one d-flex align-items-center">
                    <h2 className="title">{item.title}</h2>
                  </div>
                  <div className="main-info-one d-flex align-items-center">{item.value}</div>
                </div>
              ))}
            </div>

            {/* Branch Images */}
            <div className="bottom-details-branch mt-4">
              <div className="details-branch-one d-flex flex-column gap-3 mb-3">
                <h2 className="title">صورة الفرع البارزة</h2>
                <img src={branch?.photo} alt="branch" width="121px" height="91px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchData;

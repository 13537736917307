import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVendorDetails, getVendorSettings } from "store/customer/customerActions";
import { clearCart } from "store/customer/customerSlice";
import BranchWokringDays from "./BranchWorkingDays/BranchWorkingDays";
import CostDetails from "./CostDetails/CostDetails";
import HeaderServicesName from "./HeaderServicesName";
import ProviderImages from "./ProviderImages";
import "./ServiceName.css";
import { default as ServicesFilter } from "./ServicesNameFilter/ServicesFilter";
import EmployeesList from "./TeamRate/TeamRate";
import VendorSocials from "./VendorSocials";

const ServiceProviderDetails = () => {
  const dispatch = useDispatch();
  const { idCardService: id } = useParams();

  const branches = useSelector((state) => state.customer.vendorsBranches);
  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const sections = useSelector((state) => state.customer.sections);
  const services = useSelector((state) => state.customer.servicesList);
  const offers = useSelector((state) => state.customer.offers);
  const [vendorSections, setVendorSections] = useState([]);

  const [currentBranch, setCurrentBranch] = useState(null);
  const [currentServices, setCurrentServices] = useState([]);
  const [currentOffers, setCurrentOffers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [vendorSettings, setVendorSettings] = useState({});
  const [vendorInfo, setVendorInfo] = useState({});

  useEffect(() => {
    const getVendorInfo = async () => {
      const vendorId = currentBranch?.vendor_id;
      if (!vendorId) return;
      const vendorDetails = await dispatch(getVendorDetails(vendorId)).unwrap();
      setVendorInfo(vendorDetails.data);
    };
    getVendorInfo();
  }, [currentBranch, dispatch]);

  useEffect(() => {
    if (!currentBranch?.vendor_id) return;
    dispatch(getVendorSettings(currentBranch?.vendor_id))
      .unwrap()
      .then((response) => {
        setVendorSettings(response.data);
      });
    const vendorSections = sections.filter(
      (section) => section?.vendor_id === currentBranch?.vendor_id,
    );
    setVendorSections(vendorSections);
  }, [id, dispatch, currentBranch?.vendor_id]);

  useEffect(() => {
    if (!id || !branches.length) {
      console.warn("no id or branches");
      return;
    }

    setIsLoading(true);
    const branchDetails = branches.find((branch) => branch.id === parseInt(id));
    setCurrentBranch(branchDetails || null);

    if (cartDetails.branch_id !== branchDetails?.id) {
      dispatch(clearCart());
    }
    setIsLoading(false);
  }, [id, branches, cartDetails.branch_id, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    if (!currentBranch) return;

    const branchServices = [];
    const branchOffers = [];

    services.forEach((service) => {
      const isSectionActive = vendorSections.find((section) => section.id === service.section_id);
      const hasEmployee = service.employees.length > 0;
      if (isSectionActive && hasEmployee) {
        service.branches.forEach((branch) => {
          if (branch.id === currentBranch.id && branch.is_active === "1") {
            branchServices.push(service);
          }
        });
      }
    });
    setCurrentServices(branchServices);

    offers.forEach((offer) => {
      const isSectionActive = vendorSections.find(
        (section) => section.id === offer.offerService?.section_id,
      );
      const isOfferActive = offer.is_active === "1";
      const offerBelongsToService = branchServices.some(
        (service) => service.id === offer.offerService?.id,
      );
      if (isSectionActive && isOfferActive && offerBelongsToService) {
        branchOffers.push(offer);
      }
    });
    setCurrentOffers(branchOffers);
    setIsLoading(false);
  }, [currentBranch, services, vendorSections, offers]);

  if (!currentBranch) return null;
  if (!currentBranch) return null;

  return (
    <section>
      <HelmetInfo titlePage={"الخدمة"} />
      <div className="all-services-name padding-bottom-70">
        <HeaderPageTitle
          isActiveLinkTwo={true}
          routePage={"/servicesproviders"}
          titlePage={"الخدمات"}
          titleInfoPage={currentBranch.name}
          routeHomePage={"/"}
        />

        <div className="container">
          <div className="main-services-page-name">
            <HeaderServicesName currentBranch={currentBranch} />
            <ProviderImages currentBranch={currentBranch} />
            <div className="main-all-serv mt-5">
              <div className="row g-3">
                {/* services name filter */}
                <div className="col-12 col-lg-12 col-xl-8">
                  <ServicesFilter
                    currentServices={currentServices}
                    currentOffers={currentOffers}
                    currentBranch={currentBranch}
                    isLoading={isLoading}
                    vendorSections={vendorSections}
                  />
                </div>

                {/* cost details */}
                <div className="col-12 col-lg-12 col-xl-4">
                  <CostDetails
                    currentBranch={currentBranch}
                    currentCartDetails={cartDetails}
                    vendorSettings={vendorSettings}
                  />
                </div>
              </div>
            </div>

            {/* branch working days */}
            <BranchWokringDays
              currentBranch={currentBranch}
              vendorSettings={vendorSettings}
              vendorInfo={vendorInfo}
            />

            {/* employees list */}
            <div className="container">
              <EmployeesList currentBranch={currentBranch} vendorInfo={vendorInfo} />
            </div>
          </div>
        </div>

        {/* vendor socials */}
        <div className="container">
          <VendorSocials vendorInfo={vendorInfo} />
        </div>
      </div>
    </section>
  );
};

export default React.memo(ServiceProviderDetails);

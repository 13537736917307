import { faEdit, faEllipsisVertical, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Components imports
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import SectionModal from "./SectionModal";

// Actions and utils
import {
  addSection,
  deleteSection,
  getSections,
  getServices,
  updateSection,
  updateService,
} from "store/vendor/vendorActions";
import { createImageBlob } from "utlis";
import "./ServicesPage.css";

const ServicesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const vendorServices = useSelector((state) => state.vendor.services);

  const [services, setServices] = useState([]);
  const [sections, setSections] = useState([]);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("services");
  const [isLoading, setIsLoading] = useState(false);

  const [sectionToEdit, setSectionToEdit] = useState(null);

  const [pagination, setPagination] = useState({
    limit: 10,
    currentPage: 1,
    currentPageItemCounts: 0,
    totalPagesCount: 0,
    totalItemsCount: 0,
  });

  const handleShowAddSectionModal = () => {
    setShowSectionModal(true);
  };

  const handleCloseAddSectionModal = () => {
    setShowSectionModal(false);
    setSectionToEdit(null);
  };

  const handleSaveNewSection = async (sectionData) => {
    try {
      const { name, description, colors } = sectionData;
      console.log("sectionData", sectionData);
      const imageBlob = await createImageBlob("cyan");
      const imageFile = new File([imageBlob], `section-${name}.jpg`, { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("name_ar", name);
      formData.append("name_en", name);
      formData.append("description_ar", description);
      formData.append("description_en", JSON.stringify(colors));
      formData.append("image", imageFile);

      await dispatch(addSection(formData)).then(unwrapResult);
      toast.success("تم إضافة القسم الجديد بنجاح");
      handleCloseAddSectionModal();
      if (activeTab === "sections") fetchSections();
    } catch (error) {
      console.error("Error adding new section:", error);
      toast.error("فشل في إضافة القسم الجديد");
    }
  };

  const handleSaveEditSection = async (sectionData) => {
    console.log("sectionData", sectionData);
    try {
      const { id, name, description } = sectionData;
      const updates = {
        id,
        name_ar: name,
        name_en: name,
        description_ar: description,
      };
      await dispatch(updateSection(updates)).then(unwrapResult);
      toast.success("تم التعديل بنجاح");
      handleCloseAddSectionModal();
      fetchSections();
    } catch (error) {
      toast.error("فشلت العملية");
    }
  };

  const handleToggleSwitch = async (newValue, index) => {
    try {
      await dispatch(
        updateService({
          ...services[index],
          is_active: newValue ? "1" : "0",
        }),
      ).then(unwrapResult);

      setServices((prev) =>
        prev.map((service, i) =>
          i === index ? { ...service, is_active: newValue ? "1" : "0" } : service,
        ),
      );

      toast.success("تم التعديل بنجاح");
    } catch (error) {
      toast.error("فشلت العملية");
    }
  };

  const handleSectionToggle = async (newValue, section) => {
    try {
      const updates = {
        id: section.id,
        is_active: newValue ? "1" : "0",
      };

      await dispatch(updateSection(updates)).then(unwrapResult);

      setSections((prev) =>
        prev.map((s) => (s.id === section.id ? { ...s, is_active: newValue ? "1" : "0" } : s)),
      );

      toast.success("تم التعديل بنجاح");
      fetchSections(); // Refresh sections after update
    } catch (error) {
      console.error("Error updating section:", error);
      toast.error("فشلت العملية");
    }
  };

  const handleDeleteSection = async (sectionId) => {
    console.log(sectionId);

    try {
      const hasServices = vendorServices.some((service) => service.section_id === sectionId);
      if (hasServices) {
        toast.error("لا يمكن حذف التبويب لوجود خدمات مرتبطة به");
        return;
      }
      await dispatch(deleteSection(sectionId)).then(unwrapResult);
      toast.success("تم حذف التبويب بنجاح");
      fetchSections();
    } catch (error) {
      console.log(error, "error");
      toast.error("فشلت العملية");
    }
  };
  // Data fetching
  const fetchServices = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = {
        with: [
          "category",
          "vendor",
          "createdBy",
          "branches",
          "section",
          "employees",
          "employees.user",
        ],
        limit: pagination.limit,
        offset: pagination.currentPage,
        sort: "DESC",
        paginate: "true",
        field: "id",
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["name_ar", "vendor_id"],
        operand: ["like", "="],
        column_values: [`%${search}%`, user.model_id],
      };

      const result = await dispatch(getServices(params)).then(unwrapResult);
      console.log("result", result.data.data);
      setServices(result.data.data);
      setPagination((prev) => ({
        ...prev,
        currentPageItemCounts: result.data.data?.length || 0,
        totalItemsCount: result.data.total || 0,
        totalPagesCount: result.data.links.length - 2 || 0,
      }));
    } catch (error) {
      toast.error("فشل في تحميل الخدمات");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, pagination.limit, pagination.currentPage, search, user.model_id]);

  const fetchSections = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = {
        with: [],
        limit: pagination.limit,
        offset: 0,
        sort: "DESC",
        paginate: "true",
        field: "id",
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["vendor_id"],
        operand: ["="],
        column_values: [user.model_id],
      };
      const result = await dispatch(getSections(params)).then(unwrapResult);
      const undeletedSections = result.data.data.filter((section) => section.deleted_at === null);
      setSections(undeletedSections || []);
    } catch (error) {
      toast.error("فشل في تحميل التبويبات");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, pagination.limit, user.model_id]);

  // Effects
  useEffect(() => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  }, [search]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  useEffect(() => {
    if (activeTab === "sections") {
      fetchSections();
    }
  }, [activeTab, fetchSections]);

  // Render helpers
  const renderServiceActions = (item) => {
    if (item.isEmpty) {
      return (
        <Dropdown className="menu-drop-1">
          <Dropdown.Toggle variant="transparent">
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigate(`editaddServicesDetails/${item.id}`)}>
              تعديل
            </Dropdown.Item>
            <Dropdown.Item>حذف</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <IconsTable
        typeIcon1={faEye}
        functionEye={() => navigate(`servicesDetails/${item.id}`)}
        typeIcon2={faEdit}
        functionEdit={() => navigate(`editaddServicesDetails/${item.id}`)}
        isDeleteIcon={false}
      />
    );
  };

  return (
    <>
      <HelmetInfo titlePage={"الخدمات"} />
      <SectionModal
        editMode={sectionToEdit !== null}
        section={sectionToEdit}
        showModalAddTab={showSectionModal}
        hideModalAddTab={handleCloseAddSectionModal}
        onClose={handleCloseAddSectionModal}
        onSave={handleSaveNewSection}
        onSaveEdit={handleSaveEditSection}
      />

      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"الخدمات"}
          isShowLeftContent={true}
          contentPageLeft={
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <SearchHeaderPage
                typeIcon={false}
                textButton={false}
                functionButton={false}
                isButtonActive={false}
                onSearchChange={setSearch}
              />
              <Dropdown>
                <Dropdown.Toggle className="main-btn mx-2">اضافة جديد</Dropdown.Toggle>
                <Dropdown.Menu className="drop-menu-head">
                  <Dropdown.Item onClick={() => navigate("editaddServicesDetails")}>
                    خدمة جديدة
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleShowAddSectionModal}>تبويب جديد</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
        />
      </div>

      <div className="mt-3">
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3 custom-tabs">
          <Tab eventKey="services" title="الخدمات">
            <div className="table-content table-content-2">
              {isLoading ? (
                <Loader />
              ) : (
                <TableDataInfo
                  key="services"
                  titleTableHeader={false}
                  isHashTrue={false}
                  hashId={false}
                  dateThead={
                    <>
                      <th scope="col">اسم الخدمة</th>
                      <th scope="col">حالة الخدمة</th>
                      <th scope="col">التبويب</th>
                      <th scope="col">السعر</th>
                      <th scope="col">خدمة مميزة</th>
                      <th scope="col">تفعيل/الغاء</th>
                      <th scope="col">الاجراءات</th>
                    </>
                  }
                  dataTbody={
                    <>
                      {services.length > 0 &&
                        services.map((item, index) => (
                          <tr key={`${item.id}-${index}`}>
                            <td>{item?.name_ar}</td>
                            <td>
                              <p
                                className={
                                  item?.is_active === "1" ? "text-success" : "text-danger"
                                }>
                                {item?.is_active === "1" ? "نشط" : "غير نشط"}
                              </p>
                            </td>
                            <td>{item.section?.name_ar || "-"}</td>
                            <td>{item.price}</td>
                            <td>{item.featured === "1" ? "نعم" : "لا"}</td>
                            {!item.isEmpty && (
                              <>
                                <td>
                                  <Toggleswitch
                                    switchId={`services-${item.id}`}
                                    nameSwitch={`services-${item.id}`}
                                    value={item.is_active === "1"}
                                    onChange={(newValue) => handleToggleSwitch(newValue, index)}
                                  />
                                </td>
                                <td>{renderServiceActions(item)}</td>
                              </>
                            )}
                            {item.isEmpty && (
                              <>
                                <td></td>
                                <td>{renderServiceActions(item)}</td>
                                <td></td>
                              </>
                            )}
                          </tr>
                        ))}
                    </>
                  }
                />
              )}
            </div>
          </Tab>
          {/* sections tab */}
          <Tab eventKey="sections" title="التبويبات">
            <div className="table-content table-content-2">
              {isLoading ? (
                <Loader />
              ) : (
                <TableDataInfo
                  key="sections"
                  titleTableHeader={false}
                  isHashTrue={false}
                  dateThead={
                    <>
                      <th scope="col">اسم التبويب</th>
                      <th scope="col">الوصف</th>
                      <th scope="col">حالة التبويب</th>
                      <th scope="col">الإجراءات</th>
                    </>
                  }
                  dataTbody={
                    <>
                      {sections.map((section, index) => (
                        <tr key={`section-${section.id}-${index}`}>
                          <td>{section.name_ar}</td>
                          <td>{section.description_ar}</td>
                          <td>
                            <Toggleswitch
                              switchId={`section-${section.id}`}
                              nameSwitch={`section-${section.id}`}
                              value={section.is_active === "1"}
                              onChange={(newValue) => handleSectionToggle(newValue, section)}
                            />
                          </td>
                          <td>
                            <IconsTable
                              typeIcon2={faEdit}
                              functionEdit={() => {
                                setSectionToEdit(section);
                                handleShowAddSectionModal();
                              }}
                              typeIcon3={faTrashAlt}
                              isDeleteIcon={true}
                              functionDelete={() => handleDeleteSection(section.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>

      {activeTab === "services" ? (
        <Pagination
          limit={pagination.limit}
          setLimit={(limit) => setPagination((prev) => ({ ...prev, limit }))}
          currentPage={pagination.currentPage}
          setCurrentPage={(currentPage) => setPagination((prev) => ({ ...prev, currentPage }))}
          limitOptions={[10, 20, 30, 40]}
          currentPageItemsCount={pagination.currentPageItemCounts}
          totalItemsCount={pagination.totalItemsCount}
          totalPagesCount={pagination.totalPagesCount}
          isShowTotalPagesCount={true}
        />
      ) : (
        <Pagination
          limit={pagination.limit}
          setLimit={(limit) => setPagination((prev) => ({ ...prev, limit }))}
          currentPage={1}
          setCurrentPage={() => {}}
          limitOptions={[10, 20, 30, 40]}
          currentPageItemsCount={sections.length}
          totalItemsCount={sections.length}
          totalPagesCount={Math.ceil(sections.length / pagination.limit)}
          isShowTotalPagesCount={true}
        />
      )}
    </>
  );
};

export default ServicesPage;

import { useEffect, useMemo } from "react";
import "./ReportDetails.css";

const FinancialReport = ({ bookingDay, setReportData, setReportHeaders }) => {
  const financialData = useMemo(
    () => [
      { title: "تكاليف المنتجات", amount: Math.floor(Math.random() * 10000) + 500 },
      { title: "تكاليف المرتبات", amount: Math.floor(Math.random() * 10000) + 500 },
      { title: "تكاليف متنوعة", amount: Math.floor(Math.random() * 5000) + 200 },
      { title: "تكاليف التمويلات", amount: Math.floor(Math.random() * 8000) + 1000 },
      { title: "تكاليف المبيعات", amount: Math.floor(Math.random() * 15000) + 2000 },
      { title: "تكاليف الأرباح", amount: Math.floor(Math.random() * 20000) + 5000 },
    ],
    [],
  );

  useEffect(() => {
    setReportData(financialData);
    setReportHeaders(["التكاليف", "المبلغ"]);
  }, []);

  return (
    <div className="financial-report">
      <div className="financial-grid">
        {financialData.map((item, index) => (
          <div key={index} className="financial-card">
            <div className="card-content">
              <h3>{item.amount} ر.س</h3>
              <p>{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinancialReport;

import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionTitle from "Components/SectionTitle/SectionTitle";
import BadgeText from "Components/Ui/BadgeText/BadgeText";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import locationIcon from "../../../assets/images/icons/location.svg";
import imgCard from "../../../assets/images/products/01.png";
import Products from "../Products/Products";

const SpecialOffersHome = ({ offers, isLogged, user }) => {
  const [offersList, setOffersList] = useState([]);
  const [, setIsUserLoggedIn] = useState(false);
  const categories = useSelector((state) => state.customer.categories);

  useEffect(() => {
    if (isLogged && user.type === "customer") {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, [isLogged, user]);

  useEffect(() => {
    if (!offers) return;
    setOffersList(offers);
  }, [offers]);

  return (
    <Products newClassection={"products-offer"}>
      <SectionTitle
        title={"عروض حصرية"}
        text={"افضل العروض بأسعار تنافسية"}
        dataAos={"fade-left"}
      />
      <div className="main-products" data-aos="fade-up">
        <Swiper
          slidesPerView={1}
          spaceBetween={15}
          modules={[Navigation]}
          navigation={true}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          className="mySwiper">
          {offersList.map((offer, index) => {
            const percentage = parseInt(offer.discount_percentage);
            return (
              <SwiperSlide key={index}>
                <OfferCard
                  bgColorBadge={"red-badge"}
                  textBadge={`${percentage !== 0 ? `${percentage}%` : "مجانا"}`}
                  newClassBadge={"badge-product"}
                  imgCard={offer?.vendor?.photo ?? imgCard}
                  cardTitle={offer.name_ar || ""}
                  locationIcon={locationIcon}
                  textLocation={offer.branch_address}
                  isIconFavorite={false}
                  category={categories.find((category) => category.id === offer.category_id)}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Products>
  );
};

const OfferCard = ({
  bgColorBadge,
  textBadge,
  newClassBadge,
  isIconFavorite,
  imgCard,
  cardTitle,
  locationIcon,
  textLocation,
  category,
}) => {
  const [activeFav, setActiveFav] = useState(false);
  const actitiveIconFav = () => {
    setActiveFav(!activeFav);
  };

  return (
    <div className="card-product">
      <BadgeText bgColorBadge={bgColorBadge} textBadge={textBadge} newClass={newClassBadge} />
      {isIconFavorite && (
        <div className={`icon-favorite ${activeFav ? "" : "active"}`} onClick={actitiveIconFav}>
          <FontAwesomeIcon icon={faHeart} />
        </div>
      )}
      <div className="img-card">
        <img src={imgCard} className="w-100 h-100 object-fit-cover" alt="img product" />
      </div>

      <div className="card-body">
        <h2 className="card-title">{cardTitle}</h2>
        {/* <div className="main-text-info d-flex align-items-center gap-2"> */}
        {/* <div className="icon d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faBars} width={"22px"} height={"22px"} />
            <h2 className="text-info">{category?.name_ar}</h2>
          </div> */}
        {/* <h2 className="text-info">{textLocation}</h2> */}
        {/* </div> */}
        <div className="main-text-info d-flex align-items-center gap-2"></div>
      </div>
    </div>
  );
};

export default SpecialOffersHome;

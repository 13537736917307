import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CartServices = ({ services, onRemoveService }) => {
  if (!services || services.length === 0) return null;

  return (
    <div className="all-details-products border-bottom pb-3 border-2 pt-3">
      {services.map((item, index) => (
        <div
          key={index}
          className="details-product-info d-flex justify-content-between align-items-center gap-2 flex-wrap mt-3">
          <div className="right-details">
            <h2 className="title">{item.titleService}</h2>
            <p className="price pt-2">{item.price} ر.س</p>
          </div>
          <div className="buttons-action d-flex align-items-center gap-3">
            <div
              onClick={() => onRemoveService(item.serviceId)}
              className="icon-trash text-danger cursor-pointer-1 fs-5">
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getPackage } from "store/admin/adminActions";
import iconImg from "../../../assets/images/dashboardImages/icons/providerSubscription.svg";

const translations = {
  payments: {
    percentage: "اونلاين",
    cash: "كاش",
    percentage_and_cash: "اونلاين وكاش",
  },
  subscription_type: {
    subscribe: "اشتراك",
    commission: "عمولة",
    subscribe_and_commission: "اشتراك وعمولة",
  },
  type: {
    public: "عامة",
    private: "خاصة",
  },
};

const PackageInfo = () => {
  const isLoading = useSelector((state) => state.admin.isLoading);
  const [packageInfo, setPackageInfo] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchPackage = () => {
    dispatch(getPackage(id))
      .then(unwrapResult)
      .then((res) => {
        const info = res.data;
        console.log(info, "info");
        setPackageInfo(info);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (id) {
      fetchPackage();
    }
  }, [id]);

  return (
    <>
      <HelmetInfo titlePage={"تفاصيل الباقة"} />
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/packages">
            عودة
          </Link>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="provider-subscription mt-4" data-aos="fade-up">
          <div
            className="card-provider-subscription"
            style={{
              transition: "all 0.3s ease",
              cursor: "pointer",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: "10px 10px 10px rgba(0,0,0,0.1)",
              },
            }}>
            <div className="header-card-provider d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="left-card d-flex align-items-center flex-wrap gap-3">
                <div className="icon">
                  <img src={iconImg} alt="icon" width={"60px"} height={"60px"} />
                </div>
                <div className="info-content-head d-flex flex-column gap-2">
                  <h2 className="title">{packageInfo?.name_ar}</h2>
                </div>
              </div>
              <div className="status-badge">
                <span
                  className={`badge ${packageInfo?.is_active === "1" ? "bg-success" : "bg-danger"}`}>
                  {packageInfo?.is_active === "1" ? "نشط" : "غير نشط"}
                </span>
              </div>
            </div>

            <div className="main-card-provider mt-4">
              <h3 className="section-title mb-4">الأسعار والاشتراكات</h3>
              <div className="d-flex flex-wrap gap-5">
                <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                  <div className="data-info d-flex align-items-center gap-3">
                    <h2 className="title">سعر الاشتراك اليومي</h2>
                    <p className="text">{packageInfo?.days_price} ر.س</p>
                  </div>
                  <div className="data-info d-flex align-items-center gap-3">
                    <h2 className="title">سعر الاشتراك الربع سنوي</h2>
                    <p className="text">{packageInfo?.quarter_price} ر.س</p>
                  </div>
                </div>

                <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                  <div className="data-info d-flex align-items-center gap-3">
                    <h2 className="title">سعر الاشتراك الشهري</h2>
                    <p className="text">{packageInfo?.month_price} ر.س</p>
                  </div>
                  <div className="data-info d-flex align-items-center gap-3">
                    <h2 className="title">سعر الاشتراك النصف سنوي</h2>
                    <p className="text">{packageInfo?.half_year_price} ر.س</p>
                  </div>
                </div>

                <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                  <div className="data-info d-flex align-items-center gap-3">
                    <h2 className="title">سعر الاشتراك السنوي</h2>
                    <p className="text">{packageInfo?.year_price} ر.س</p>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h3 className="section-title mb-4">تفاصيل الباقة</h3>
                <div className="d-flex flex-wrap gap-5">
                  <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                    <div className="data-info d-flex align-items-center gap-3">
                      <h2 className="title">نوع الباقة</h2>
                      <p className="text">{translations.type[packageInfo?.type] || "غير محدد"}</p>
                    </div>
                    <div className="data-info d-flex align-items-center gap-3">
                      <h2 className="title">نوع الاشتراك</h2>
                      <p className="text">
                        {translations.subscription_type[packageInfo?.subscription_type] ||
                          "غير محدد"}
                      </p>
                    </div>
                    <div className="data-info d-flex align-items-center gap-3">
                      <h2 className="title">نسبة العمولة</h2>
                      <p className="text">{packageInfo?.commission}%</p>
                    </div>
                  </div>

                  <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                    <div className="data-info d-flex align-items-center gap-3">
                      <h2 className="title">عدد الفروع</h2>
                      <p className="text">{packageInfo?.branches}</p>
                    </div>
                    <div className="data-info d-flex align-items-center gap-3">
                      <h2 className="title">عدد الموظفين</h2>
                      <p className="text">{packageInfo?.employees}</p>
                    </div>
                    <div className="data-info d-flex align-items-center gap-3">
                      <h2 className="title">عدد العملاء</h2>
                      <p className="text">{packageInfo?.customers}</p>
                    </div>
                  </div>

                  <div className="info-card-provivder-one d-flex flex-column gap-3 mb-3">
                    <div className="data-info d-flex align-items-center gap-3">
                      <h2 className="title">عدد الرسائل النصية</h2>
                      <p className="text">{packageInfo?.sms_messages}</p>
                    </div>
                    <div className="data-info d-flex align-items-center gap-3">
                      <h2 className="title">طرق الدفع</h2>
                      <p className="text">
                        {translations.payments[packageInfo?.payments] || "غير محدد"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PackageInfo;

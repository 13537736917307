import CustomModal from "Components/CustomModal/CustomModal";
import { useState } from "react";
import iconSearch from "../../../assets/images/icons/search.svg";
import "./ModalFilter.css";

const ModalFilter = ({ showModalFilter, hideModelFilter, setSearchText }) => {
  const [textInput, setTextInput] = useState("");

  const handleTextInput = (e) => {
    setTextInput(e.target.value);
  };

  const handleSaveFilter = () => {
    setSearchText(textInput);
    setTimeout(() => {
      hideModelFilter();
    }, 600);
  };

  return (
    <CustomModal
      show={showModalFilter}
      onHide={hideModelFilter}
      title={"تصفية نتائج البحث"}
      newClass={"modal-filter"}>
      <div className="modal-filter-content">
        <div className="header-input-filter">
          <div className="input-filter">
            <label htmlFor="inputFilterSearch" className="form-label d-none">
              {" "}
            </label>
            <input
              type="search"
              className="form-control"
              id="inputFilterSearch"
              placeholder="اسم الصالون"
              onChange={handleTextInput}
            />
          </div>

          <div className="icon-search">
            <img src={iconSearch} alt="icon search" width={"24px"} height={"24px"} />
          </div>
        </div>

        <div className="main-content-modal">
          <div className="content-one-info">
            <h2 className="title">ترتيب من خلال</h2>
            <div className="filter-radio">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  defaultValue="option1"
                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                  الأعلى تقيما
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  defaultValue="option2"
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  الأقرب
                </label>
              </div>
            </div>
          </div>

          {/* <div className="rang-slider my-3">
            <div className="info-range d-flex align-text-center gap-2 mb-2">
              <p className="text">اعلى سعر </p>
              <h1 className="num-price">{data} ر.س</h1>
            </div>
            <input
              className="input-range"
              type="range"
              min="0"
              max="1800"
              step="0"
              value={data}
              onChange={(e) => setData(e.target.value)}
            />

            <div></div>
          </div> */}

          <div className="content-one-info">
            <h2 className="title"> مزودي الخدمة </h2>
            <div className="filter-radio">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios1"
                  id="exampleRadios3"
                  defaultValue="option3"
                />
                <label className="form-check-label" htmlFor="exampleRadios3">
                  عادى
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios1"
                  id="exampleRadios4"
                  defaultValue="option4"
                />
                <label className="form-check-label" htmlFor="exampleRadios4">
                  مميز
                </label>
              </div>
            </div>
          </div>

          <button onClick={handleSaveFilter} className="main-btn w-100 mt-3">
            حفظ
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalFilter;

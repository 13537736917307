import { faClock as regularIcon } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";

const AddDateTime = ({ name, handleOnChange }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = (index, key, value) => {
    const currentShift = values.officialHours[index];

    // Check if end time is being set and it's before or equal to start time
    if (key === "end_time" && currentShift.start_time && value <= currentShift.start_time) {
      return;
    }

    // Check if start time is being set and it's after or equal to end time
    if (key === "start_time" && currentShift.end_time && value >= currentShift.end_time) {
      return;
    }

    // If we're setting end time first, ensure any existing start time is valid
    if (key === "end_time" && !currentShift.start_time) {
      handleOnChange && handleOnChange(index, key, value);
      setFieldValue(`officialHours.${index}.${key}`, value);
      return;
    }

    // If we're setting start time first, ensure any existing end time is valid
    if (key === "start_time" && !currentShift.end_time) {
      handleOnChange && handleOnChange(index, key, value);
      setFieldValue(`officialHours.${index}.${key}`, value);
      return;
    }

    handleOnChange && handleOnChange(index, key, value);
    setFieldValue(`officialHours.${index}.${key}`, value);
  };

  const isTimeInvalid = (index, time, type) => {
    const currentShift = values.officialHours[index];

    // For end time field
    if (type === "end") {
      // Only validate if start time exists and current end time exists
      if (currentShift.start_time && time) {
        return time <= currentShift.start_time;
      }
    }

    // For start time field
    if (type === "start") {
      // Only validate if end time exists and current start time exists
      if (currentShift.end_time && time) {
        return time >= currentShift.end_time;
      }
    }

    return false;
  };

  const renderTooltip = (props, message) => <Tooltip {...props}>{message}</Tooltip>;

  return (
    <div className="row g-3">
      <div className="col-12">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <>
              {values.officialHours.length > 0 &&
                values.officialHours?.map((item, index) => (
                  <div key={index} className="mt-3">
                    <div className="row align-items-center g-3">
                      <div className={`col`}>
                        <div className="form-group">
                          <OverlayTrigger
                            placement="top"
                            overlay={(props) =>
                              renderTooltip(props, "وقت البداية يجب أن يكون قبل وقت النهاية")
                            }
                            show={isTimeInvalid(
                              index,
                              values.officialHours[index]?.start_time,
                              "start",
                            )}>
                            <InputGroup className="input-group input-clock">
                              <DatePicker
                                selected={
                                  values.officialHours[index] &&
                                  values.officialHours[index].start_time
                                }
                                onChange={(date) => handleChange(index, "start_time", date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className={`form-control w-100 ${
                                  isTimeInvalid(
                                    index,
                                    values.officialHours[index]?.start_time,
                                    "start",
                                  )
                                    ? "text-muted bg-light"
                                    : ""
                                }`}
                                placeholderText="بداية الشفت"
                                name={`officialHours.${index}.start_time`}
                                popperPlacement="top-start"
                                onBlur={() =>
                                  setFieldTouched(`officialHours.${index}.start_time`, true)
                                }
                              />
                              <InputGroup.Text className="input-group-text">
                                <FontAwesomeIcon icon={regularIcon} />
                              </InputGroup.Text>
                            </InputGroup>
                          </OverlayTrigger>
                          <ErrorMessage name={`officialHours.${index}.start_time`}>
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      {/*  */}
                      <div className={`col`}>
                        <div className="form-group">
                          <OverlayTrigger
                            placement="top-start"
                            overlay={(props) =>
                              renderTooltip(props, "وقت النهاية يجب أن يكون بعد وقت البداية")
                            }
                            show={isTimeInvalid(
                              index,
                              values.officialHours[index]?.end_time,
                              "end",
                            )}>
                            <InputGroup className="input-group input-clock">
                              <DatePicker
                                selected={
                                  values.officialHours[index] &&
                                  values.officialHours[index].end_time
                                }
                                onChange={(date) => handleChange(index, "end_time", date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className={`form-control w-100 ${
                                  isTimeInvalid(index, values.officialHours[index]?.end_time, "end")
                                    ? "text-muted bg-light"
                                    : ""
                                }`}
                                name={`officialHours.${index}.end_time`}
                                placeholderText="نهاية الشفت"
                                popperPlacement="top-start"
                                onBlur={() =>
                                  setFieldTouched(`officialHours.${index}.end_time`, true)
                                }
                              />
                              <InputGroup.Text className="input-group-text">
                                <FontAwesomeIcon icon={regularIcon} />
                              </InputGroup.Text>
                            </InputGroup>
                          </OverlayTrigger>
                          <ErrorMessage name={`officialHours.${index}.end_time`}>
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                      {/*  */}
                      <div className={`col`}>
                        <div className="form-group select-one">
                          <select
                            className="form-select form-select-lg"
                            name={`officialHours.${index}.day`}
                            onChange={(e) => {
                              handleChange(index, "day", e.target.value);
                            }}
                            value={values.officialHours[index].day}>
                            {[
                              { id: undefined, label: "اليوم" },
                              { id: "sat", label: "السبت" },
                              { id: "sun", label: "الاحد" },
                              { id: "mon", label: "الاثنين" },
                              { id: "tue", label: "الثلاثاء" },
                              { id: "wed", label: "الاربعاء" },
                              { id: "thu", label: "الخميس" },
                              { id: "fri", label: "الجمعه" },
                            ].map((option, index) => (
                              <option key={index} value={option.id}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <ErrorMessage name={`officialHours.${index}.day`}>
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      {/*  */}
                      {index >= 1 ? (
                        <div className="col-2">
                          <div
                            className="remove-inputs text-color-num cursor-pointer-1 d-flex align-items-center gap-2"
                            onClick={() => {
                              remove(index);
                              handleOnChange && handleOnChange(index, "delete", null);
                            }}>
                            <FontAwesomeIcon icon={faTimesCircle} /> حذف
                          </div>
                        </div>
                      ) : (
                        <div className="col-2">
                          <div
                            className="add-new-inputs mt-3 text-color-num cursor-pointer-1 d-flex align-items-center gap-2"
                            onClick={() => push({})}>
                            <FontAwesomeIcon icon={faPlus} /> اضافة
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default AddDateTime;

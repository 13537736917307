import BranchMap from "Dashboard/Components/maps/BranchMap";
import React, { useEffect, useState } from "react";
import icon2 from "../../../../assets/images/icons/bank-note-04.svg";
import icon1 from "../../../../assets/images/icons/card-01.svg";

const arabicDays = {
  sun: "الأحد",
  mon: "الاثنين",
  tue: "الثلاثاء",
  wed: "الأربعاء",
  thu: "الخميس",
  fri: "الجمعة",
  sat: "السبت",
};

const BranchWorkingDays = ({ currentBranch, vendorSettings, vendorInfo }) => {
  const [workingHours, setWorkingHours] = useState([]);
  const paymentMethod = vendorSettings.accept_payment;

  const formatTime = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    let period = "ص";
    let formattedHours = parseInt(hours);
    if (formattedHours >= 12) {
      period = "م";
      if (formattedHours > 12) formattedHours -= 12;
    }
    if (formattedHours === 0) formattedHours = 12;
    return `${formattedHours}:${minutes} ${period}`;
  };

  useEffect(() => {
    if (vendorInfo && vendorInfo.branches) {
      const branches = vendorInfo.branches;
      const currentBranchOfficialHours = branches.find(
        (branch) => branch.id === currentBranch.id,
      ).official_hours;
      setWorkingHours(currentBranchOfficialHours);
    }
  }, [vendorInfo]);

  if (!workingHours || workingHours.length === 0) return null;

  return (
    <div className="all-banner-serv">
      <div className="banner-home">
        <div className="banner-info">
          <div className="row gx-3 flex-wrap-reverse">
            <div className="col-md-12 col-lg-7">
              <div className="content-banner-info">
                <div className="heade-one" data-aos="fade-up">
                  <h2 className="head-title">مواعيد العمل</h2>
                  <div className="main-content-banner mt-3">
                    <div className="row g-3">
                      {workingHours &&
                        workingHours.map((item, index) => {
                          return (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-6" key={index}>
                              <div className="info-content-one d-flex align-items-center gap-3">
                                <h2 className="name-day size-text-color-gray">
                                  {arabicDays[item.day]}
                                </h2>
                                <p className="date-time size-text-color-gray text-white">
                                  {formatTime(item.start_time)}
                                </p>
                                <span className="size-text-color-gray text-white">:</span>
                                <p className="date-time size-text-color-gray text-white">
                                  {formatTime(item.end_time)}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="bootom-info-banner mt-4" data-aos="fade-up">
                  <h2 className="head-title size-text-color-gray text-white">معلومات إضافية</h2>
                  <div className="all-info-bottom flex-wrap style-card-details d-flex align-items-center gap-3">
                    {paymentMethod != null && (
                      <>
                        {(paymentMethod === "online" || paymentMethod === "both") && (
                          <div className="info-one d-flex align-items-center gap-2">
                            <div className="icon">
                              <img src={icon1} alt="icon" width={"31px"} height={"31px"} />
                            </div>
                            <p className="text-info-1 size-text-color-gray text-white">
                              اقبل الدفع اون لاين
                            </p>
                          </div>
                        )}

                        {(paymentMethod === "cash" || paymentMethod === "both") && (
                          <div className="info-one d-flex align-items-center gap-2">
                            <div className="icon">
                              <img src={icon2} alt="icon" width={"31px"} height={"31px"} />
                            </div>
                            <p className="text-info-1 size-text-color-gray text-white">
                              اقبل الدفع كاش
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-5">
              <div className="image-banner-1">
                <BranchMap lat={currentBranch?.lat} long={currentBranch?.long} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BranchWorkingDays);

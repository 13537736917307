import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import iconLocation from "../../../../assets/images/icons/location.svg";

const CostDetails = ({ currentBranch, vendorSettings }) => {
  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const [bookedServices, setBookedServices] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [offerDetails, setOfferDetails] = useState({});
  const offers = useSelector((state) => state.customer.offers);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (cartDetails) {
      setBookedServices(cartDetails.services);
      setTotalPrice(
        cartDetails.services.reduce((acc, service) => acc + parseInt(service.price), 0),
      );
      const offerId = cartDetails.offer_id;
      const offer = offers.find((offer) => offer.id === offerId);
      setOfferDetails(offer);
    }
  }, [cartDetails]);

  const navigate = useNavigate();
  const goToBooking = () => {
    if (user && user.type === "customer") {
      navigate("/stepsservicescontent", {
        state: {
          bookedServices: bookedServices,
          totalPrice: 0,
          currentBranch: currentBranch,
          vendorSettings: vendorSettings,
        },
      });
    } else {
      localStorage.setItem(
        "authRedirect",
        JSON.stringify({
          path: "/stepsservicescontent",
          state: {
            bookedServices: bookedServices,
            totalPrice: 0,
            currentBranch: currentBranch,
            vendorSettings: vendorSettings,
          },
        }),
      );
      navigate("/login");
    }
  };

  return (
    <div className="cost-details style-card-details" data-aos="fade-right">
      <HeaderDetails currentBranch={currentBranch} />

      <div className="main-info-cost pt-4">
        <BookedServices bookedServices={bookedServices} />
        <PricesDetails totalPrice={totalPrice} />
        <OfferDetails offerDetails={offerDetails} />
        {currentBranch && bookedServices.length > 0 && (
          <button onClick={goToBooking} className="main-btn w-100 mt-3">
            احجز الآن
          </button>
        )}
      </div>
    </div>
  );
};

const BookedServices = ({ bookedServices }) => {
  return (
    <>
      <div className="title-header">
        <h2 className="title size-text-color-dark main-text-color">تفاصيل</h2>
      </div>
      {bookedServices.map((service, index) => (
        <div
          key={`service-${index}`}
          className="head-details d-flex gap-3 justify-content-between  pt-3 pb-3">
          <div className="info-right">
            <h2 className="title size-text-color-gray">{service.name_ar}</h2>
            <div className="time-info size-text-color-gray pt-2">{service.service_time} دقيقة</div>
          </div>
          <div className="num-price size-text-color-gray ">{service.price} ر.س</div>
        </div>
      ))}
    </>
  );
};

const OfferDetails = ({ offerDetails }) => {
  if (!offerDetails) return null;
  return (
    <div className="head-details d-flex gap-3 justify-content-between border-top  pt-3">
      <div className="info-right">
        <h2 className="title size-text-color-gray main-text-color">الخصم</h2>
      </div>
      <div className="num-price size-text-color-gray main-text-color">
        {offerDetails.discount_percentage} %
      </div>
    </div>
  );
};

const PricesDetails = ({ totalPrice }) => {
  return (
    <div className="head-details d-flex gap-3 justify-content-between border-top  pt-3">
      <div className="info-right">
        <h2 className="title size-text-color-gray main-text-color">المجموع</h2>
      </div>
      <div className="num-price size-text-color-gray main-text-color">{totalPrice} ر.س</div>
    </div>
  );
};

const HeaderDetails = ({ currentBranch }) => {
  return (
    <div className="head-details-one d-flex align-items-center gap-2 p-2">
      <div className="info-content d-flex flex-column gap-1 justify-content-center">
        <h2 className="title-info size-text-color-dark"> {currentBranch.name}</h2>

        <div className="location-details d-flex  align-items-center gap-1">
          <div className="icon-location">
            <img src={iconLocation} alt="icon location" width={"25px"} height={"25px"} />
          </div>
          <p className="text-location size-text-color-gray">{currentBranch.address}</p>
        </div>
      </div>
    </div>
  );
};

export default CostDetails;

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { memo, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

const months_ar = [
  "يناير",
  "فبراير",
  "مارس",
  "أبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

const DaySelection = memo(({ employeeDays, setSelectedDay }) => {
  const currrentMonth = months_ar[new Date().getUTCMonth()];
  const [daysOptions, setDaysOptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (employeeDays.length !== 0) {
      const daysOptions = [];
      const today = new Date();

      for (let i = 0; i < 45; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        const dayOfWeekLabel = date.toLocaleDateString("en-US", { weekday: "short" }).toLowerCase();
        if (employeeDays.includes(dayOfWeekLabel)) {
          const formattedDate = date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          });
          daysOptions.push({ label: `${dayOfWeekLabel} ${formattedDate}` });
        }
      }
      setDaysOptions(daysOptions);
    }
  }, [employeeDays]);

  const handleDaySelect = (dateString, index) => {
    const [dayOfWeek, month, day] = dateString.split(" ");
    const currentYear = new Date().getFullYear();
    const date = new Date(`${month} ${day}, ${currentYear}`);

    if (isNaN(date)) {
      throw new Error("Invalid date string format.");
    }
    const localDateString = date.toLocaleDateString("en-EG", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });

    const formattedDate = localDateString;
    const dayOfWeekLabel = dayOfWeek.toLowerCase();
    setSelectedDay(formattedDate + "*" + dayOfWeekLabel);
    setSelectedIndex(index);
  };

  return (
    <>
      <div className="main-serv-date main-buttons-serv">
        <h2 className="name-date-month">شهر {currrentMonth}</h2>
        <OwlCarousel dir="ltr" className="owl-theme" {..._options}>
          {daysOptions.map((item, index) => {
            return (
              <div className="item" key={index}>
                <button
                  className={`btn-filter-one main-btn main-outline-btn ${index === selectedIndex ? "active" : ""}`}
                  style={{
                    minHeight: "80px",
                    textTransform: "capitalize",
                    padding: "10px 15px",
                  }}
                  onClick={() => handleDaySelect(item.label, index)}>
                  <span className="text-date">{item.label}</span>
                </button>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    </>
  );
});

const _options = {
  items: 5,
  loop: false,
  margin: 10,
  autoplay: false,
  dots: false,
  nav: true,
  rtl: false,
  responsive: {
    0: {
      items: 4,
    },
    450: {
      itemd: 5,
    },
    768: {
      items: 8,
    },
  },
};
export default DaySelection;

import HeaderHome from "./HeaderHome";
import "./HomeIndex.css";

const HomeIndex = ({ infoDetailsBookings, period, setPeriod }) => {
  return (
    <>
      <HeaderHome infoDetailsBookings={infoDetailsBookings} period={period} setPeriod={setPeriod} />
    </>
  );
};

export default HomeIndex;

import { unwrapResult } from "@reduxjs/toolkit";
import CardBooking from "Components/BookingsInfo/CardBooking/CardBooking";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderInfoPage from "Components/Ui/HeaderInfoPage/HeaderInfoPage";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFormattedDateForBooking } from "utlis";
import { listBookings } from "../../store/customer/customerActions";
import "./Bookings.css";

export const statusList = [
  { value: "confirmed", text: "مؤكد", badge: "bg-01" },
  { value: "notconfirmed", text: "غير مؤكد", badge: "bg-02" },
  { value: "completed", text: "مكتمل", badge: "bg-03" },
  { value: "canceled", text: "ملغي", badge: "bg-04" },
  { value: "notshown", text: "غير معروض", badge: "bg-05" },
];

const Bookings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const branches = useSelector((state) => state.customer.vendorsBranches);
  console.log({ branches });

  const providersMap = useSelector((state) =>
    state.customer.serviceProviders.reduce((acc, provider) => {
      acc[provider.id] = {
        name: provider.name,
      };
      return acc;
    }, {}),
  );

  const branchesMap = useSelector((state) =>
    state.customer.vendorsBranches.reduce((acc, branch) => {
      acc[branch.id] = {
        name: branch.name,
      };
      return acc;
    }, {}),
  );

  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [bookingsList, setBookingsList] = useState([]);
  const [status, setStatus] = useState("");
  const [confirmationStatus, setConfirmationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getCustomerBookings = useCallback(
    (param) => {
      setIsLoading(true);
      dispatch(listBookings(param))
        .then(unwrapResult)
        .then((result) => {
          const processedBookings = result.data.data.map((item) => {
            if (item.status === "notconfirmed" && item.confirmation === "confirmed") {
              return { ...item, status: "confirmed" };
            }
            if (item.status === "confirmed" && item.confirmation === "not_confirmed") {
              return { ...item, status: "notconfirmed" };
            }

            return item;
          });
          setBookingsList(processedBookings);
          setCurrentPageItemCounts(processedBookings.length || 0);
          setTotalItemsCount(result.data.total || 0);
          setTotalPagesCount(result.data.links.length - 2 || 0);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const params = {
      columns: ["user_id"],
      operand: ["="],
      column_values: [user.id],
      paginate: true,
      field: "created_at",
      sort: "DESC",
      limit: limit,
      offset: currentPage,
    };

    if (status) {
      params.columns.push("status");
      params.operand.push("=");
      params.column_values.push(status);
    }

    if (confirmationStatus !== "") {
      delete params.columns[1];
      delete params.operand[1];
      delete params.column_values[1];

      params.columns.push("confirmation");
      params.operand.push("=");
      params.column_values.push(confirmationStatus);
    }

    getCustomerBookings(params);
  }, [getCustomerBookings, status, confirmationStatus, currentPage, limit]);

  return (
    <>
      <HelmetInfo titlePage={"حجوزاتي"} />
      <div className="main-bookings padding-bottom-70">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={false}
          titleInfoPage={"حجوزاتى"}
        />
        <div className="container">
          <HeaderInfoPage
            title={"حجوزاتي"}
            isActiveLink={false}
            routePage={false}
            textLink={false}
            isNewContent={true}
            Newcontent={
              <div className="select-booking">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    e.preventDefault();
                    const value = e.target.value;
                    setStatus(value);
                    if (value === "confirmed") {
                      setConfirmationStatus("confirmed");
                    } else if (value === "notconfirmed") {
                      setConfirmationStatus("not_confirmed");
                    }

                    setCurrentPage(1);
                  }}>
                  <option selected value="">
                    اختر الحالة
                  </option>
                  <option value="confirmed">مؤكد</option>
                  <option value="notconfirmed">غير مؤكد</option>
                  <option value="completed">مكتمل</option>
                  <option value="canceled">ملغي</option>
                </select>
              </div>
            }
          />
          {/*  */}
          <div
            className="all-cards-bookings"
            data-aos="fade-up"
            style={{ marginTop: "20px", minHeight: "500px" }}>
            {isLoading ? (
              <Loader />
            ) : (
              bookingsList.map((item, index) => (
                <Link key={item.id} to={`/bookings/${item.id}`}>
                  <CardBooking
                    key={index}
                    numBooking={`حجز رقم#` + item.id}
                    title={branchesMap[item.branch_id]?.name}
                    dateBooking={getFormattedDateForBooking(item.booking_day)}
                    BgBadgeType={statusList.find((status) => status.value === item.status)?.badge}
                    typeBooking={statusList.find((status) => status.value === item.status)?.text}
                  />
                </Link>
              ))
            )}
          </div>
          {/*  */}
          <Pagination
            limit={limit}
            setLimit={setLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limitOptions={[1, 5, 10, 20, 30, 40]}
            currentPageItemsCount={currentPageItemCounts}
            totalItemsCount={totalItemsCount}
            totalPagesCount={totalPagesCount}
            isShowTotalPagesCount={true}
          />
        </div>
      </div>
    </>
  );
};

export default Bookings;

import { faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getVendors } from "../../../../store/admin/adminActions";

const AdminReportVendors = ({ bookingDay, setReportData, setReportHeaders }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [totalVendorsCount, setTotalVendorsCount] = useState(0);

  const fetchVendorsReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["booking"],
      per_page: 500,
      limit: 500,
      sort: "DESC",
      paginate: "true",
      from_date: bookingDay,
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name_ar"],
      columns: ["created_at"],
      operand: [">="],
      column_values: [bookingDay],
    };
    dispatch(getVendors(params))
      .then(unwrapResult)
      .then((res) => {
        setVendors(res.data.data);
        setTotalVendorsCount(res.data.total);

        // Prepare data for PDF
        const reportData = res.data.data.map((vendor) => ({
          name: vendor.name || "",
          commercial_no: vendor.commercial_no || "",
          tax_number: vendor.tax_number || "",
          bookings_count: vendor.bookings_count || 0,
          services_count: vendor.services_count || 0,
          branches_count: vendor.branches_count || 0,
          user_count: vendor.user_count || 0,
          status: vendor.is_active === "1" ? "نشط" : "غير نشط",
        }));

        setReportData(reportData);
        setReportHeaders([
          "الاسم",
          "رقم السجل التجاري",
          "الرقم الضريبي",
          "عدد الحجوزات",
          "عدد الخدمات",
          "عدد الفروع",
          "عدد المستخدمين",
          "الحالة",
        ]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, bookingDay, setReportData, setReportHeaders]);

  useEffect(() => {
    fetchVendorsReport();
  }, [fetchVendorsReport]);

  if (isLoading) return <Loader />;
  if (!vendors.length) return null;

  const sortedVendors = [...new Map(vendors.map((v) => [v.id, v])).values()].sort(
    (a, b) => b.bookings_count - a.bookings_count,
  );
  const newVendors = sortedVendors.filter(
    (vendor) => new Date(vendor.created_at) >= new Date(bookingDay),
  );

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{newVendors.length}</h3>
            <p>عدد مزودي الخدمة</p>
          </div>
          <FontAwesomeIcon icon={faStore} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">مزودي الخدمة</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الاسم</th>
              <th>رقم السجل التجاري</th>
              <th>الرقم الضريبي</th>
              <th>عدد الحجوزات</th>
              <th>عدد الخدمات</th>
              <th>عدد الفروع</th>
              <th>عدد المستخدمين</th>
              <th>الحالة</th>
            </tr>
          </thead>
          <tbody>
            {newVendors.map((vendor, index) => (
              <tr key={vendor.id}>
                <td>{index + 1}</td>
                <td>{vendor.name}</td>
                <td>{vendor.commercial_no}</td>
                <td>{vendor.tax_number}</td>
                <td>{vendor.bookings_count}</td>
                <td>{vendor.services_count}</td>
                <td>{vendor.branches_count}</td>
                <td>{vendor.user_count}</td>
                <td>{vendor.is_active === "1" ? "نشط" : "غير نشط"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportVendors;

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import imgBg from "../../../assets/images/main/04.png";
import { getProfileData } from "../../../store/vendor/vendorActions";
import BasicData from "./BasicData";
import ChangePassword from "./ChangePassword";
import "./ProfileDashboard.css";

const ProfileDashboard = () => {
  const dispatch = useDispatch();
  const [vendorProfileData, setVendorProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchProfileDetails = async () => {
      setIsLoading(true);
      const result = await dispatch(getProfileData()).unwrap();
      setVendorProfileData(result.data.vendor);
      setIsLoading(false);
    };
    fetchProfileDetails();
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <>
      <HelmetInfo titlePage={"الملف الشخصى"} />
      <div className="profile-page-info profile-page-info-dash mt-4 mb-3">
        <div
          className="header-page-profile img-bg"
          style={{ backgroundImage: `url(${imgBg})` }}></div>

        <div className="all-info-profile-content" data-aos="fade-up">
          <Tabs
            defaultActiveKey="basicInformation"
            id="uncontrolled-tab-example"
            className="mb-3 tabs-profile">
            <Tab className="tab-one" eventKey="basicInformation" title="البيانات الأساسية">
              <BasicData vendorProfileData={vendorProfileData} />
            </Tab>

            <Tab className="tab-one" eventKey="passwords" title="كلمة المرور">
              <ChangePassword />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;

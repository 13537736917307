// @ts-nocheck
import { faInstagram, faSnapchat, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faGlobe, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateProfile } from "store/vendor/vendorActions";
import iconEmail from "../../../assets/images/dashboardImages/icons/email2.svg";
import iconPhone from "../../../assets/images/dashboardImages/icons/phone.svg";

const BasicData = ({ vendorProfileData }) => {
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [editing, setEditing] = useState(false);
  const [user, setUser] = useState({});
  const [formValues, setFormValues] = useState(user);
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUser(JSON.parse(user));
  }, []);

  useEffect(() => {
    if (user) {
      const cloneFormValues = {
        first_name: user.first_name,
        last_name: user.last_name,
        birthdate: user.birthdate,
        country_id: user.country_id,
        city_id: user.city_id,
        phone: user.phone,
        email: user.email,
      };

      if (user.type === "vendor") {
        cloneFormValues.website_url = vendorProfileData?.website_url || "";
        cloneFormValues.twitter = vendorProfileData?.twitter || "";
        cloneFormValues.instagram = vendorProfileData?.instagram || "";
        cloneFormValues.snapchat = vendorProfileData?.snapchat || "";
      }

      setFormValues(cloneFormValues);
    }
  }, [vendorProfileData, user]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSubmit = async () => {
    dispatch(updateProfile(formValues))
      .then(unwrapResult)
      .then((res) => {
        toast.success(res.message);
        setEditing(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleInputChange = (name, newValue) => {
    setFormValues((old) => ({
      ...old,
      [name]: newValue,
    }));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="basic-information-content basic-information-content-dash">
          <div className="all-basic-info">
            <div className="header-info-profile position-relative">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="m-0">البيانات الأساسية</h3>
                {!editing && user.type === "vendor" && (
                  <button
                    className="btn d-flex align-items-center gap-2 px-3 py-2  main-btn"
                    onClick={handleEditClick}>
                    تعديل
                    <FontAwesomeIcon icon={faPen} className="ms-2" />
                  </button>
                )}
              </div>

              <div className="all-info-content d-flex mb-3">
                <div className="main-info-one d-flex align-items-center">
                  <h2 className="title">الاسم الأول</h2>
                </div>
                <div className="main-info-one d-flex align-items-center">
                  {editing ? (
                    <input
                      type="text"
                      className="form-control"
                      value={formValues.first_name}
                      onChange={(e) => handleInputChange("first_name", e.target.value)}
                    />
                  ) : (
                    <p className="text">{formValues.first_name}</p>
                  )}
                </div>
              </div>

              <div className="all-info-content d-flex mb-4">
                <div className="main-info-one d-flex align-items-center">
                  <h2 className="title">الاسم الأخير</h2>
                </div>
                <div className="main-info-one d-flex align-items-center">
                  {editing ? (
                    <input
                      type="text"
                      className="form-control"
                      value={formValues.last_name}
                      onChange={(e) => handleInputChange("last_name", e.target.value)}
                    />
                  ) : (
                    <p className="text">{formValues.last_name}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="bottom-basic-info mt-4">
              <div className="info-one-basic d-flex align-items-center gap-3 justify-content-between mb-1">
                <div className="content-info d-flex align-items-center gap-2">
                  <div className="icon-img">
                    <img src={iconPhone} alt="icon phone" />
                  </div>
                  <div className="info-icon">{formValues.phone}</div>
                </div>
              </div>

              <div className="info-one-basic d-flex align-items-center gap-3 justify-content-between">
                <div className="content-info d-flex align-items-center gap-2">
                  <div className="icon-img">
                    <img src={iconEmail} alt="icon email" />
                  </div>
                  <div className="info-icon">{formValues.email}</div>
                </div>
              </div>

              {user.type === "vendor" && (
                <div className="social-media-section mt-4">
                  <h3 className="title mb-4">التواصل الاجتماعي</h3>

                  <div className="social-media-inputs d-flex flex-column gap-3">
                    <div className="social-input-group">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faGlobe} className="social-icon" />
                        {editing ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="https://www.example.com"
                            value={formValues.website_url}
                            onChange={(e) => handleInputChange("website_url", e.target.value)}
                          />
                        ) : (
                          <span className="social-text">{formValues.website_url || ""}</span>
                        )}
                      </div>
                    </div>

                    <div className="social-input-group">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faXTwitter} className="social-icon" />
                        {editing ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="https://www.twitter.com/username"
                            value={formValues.twitter}
                            onChange={(e) => handleInputChange("twitter", e.target.value)}
                          />
                        ) : (
                          <span className="social-text">{formValues.twitter || ""}</span>
                        )}
                      </div>
                    </div>

                    <div className="social-input-group">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                        {editing ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="https://www.instagram.com/username"
                            value={formValues.instagram}
                            onChange={(e) => handleInputChange("instagram", e.target.value)}
                          />
                        ) : (
                          <span className="social-text">{formValues.instagram || ""}</span>
                        )}
                      </div>
                    </div>

                    <div className="social-input-group">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faSnapchat} className="social-icon" />
                        {editing ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="https://www.snapchat.com/username"
                            value={formValues.snapchat}
                            onChange={(e) => handleInputChange("snapchat", e.target.value)}
                          />
                        ) : (
                          <span className="social-text">{formValues.snapchat || ""}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {editing && (
                <div className="action-buttons d-flex justify-content-end gap-3 mt-2 pt-4 ">
                  <button className="btn px-4 main-btn" onClick={handleCancel}>
                    إلغاء
                  </button>
                  <button className="btn px-4 main-btn" onClick={handleSubmit}>
                    حفظ التغييرات
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BasicData;

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import clockIcon from "../../assets/images/icons/clock.svg";

import { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import locationIcon from "../../assets/images/icons/location.svg";
import imgCardSlider from "../../assets/images/main/04.png";

const CardsFilter = ({ servicesProviders, currentProvider, setCurrentProvider }) => {
  const [selectedServiceProviderIdx, setSelectedServiceProviderIdx] = useState(0);
  const [providerDetails, setProviderDetails] = useState(null);

  useEffect(() => {
    if (selectedServiceProviderIdx >= servicesProviders.length) {
      setSelectedServiceProviderIdx(0);
      setProviderDetails(servicesProviders[0]);
    }
  }, [servicesProviders, selectedServiceProviderIdx]);

  const handleSetCurrentProvider = (index) => {
    setProviderDetails(servicesProviders[index]);
    setCurrentProvider(servicesProviders[index]);
  };

  return (
    <div className="card-filter-one h-100 mb-4 mx-2">
      <div className="slider-card">
        <Swiper
          navigation={true}
          loop={true}
          spaceBetween={15}
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
          onSlideChange={(swiper) => {
            const index = swiper.realIndex;
            setSelectedServiceProviderIdx(index);
            handleSetCurrentProvider(index);
          }}>
          {servicesProviders.map((provider, index) => {
            const branchImage = provider.branches[0]?.photo;

            return (
              <SwiperSlide key={index}>
                <div className="img-slider">
                  <img
                    src={branchImage || imgCardSlider}
                    alt="img card slider"
                    className="w-100 object-fit-cover"
                    style={{ objectFit: "cover", borderRadius: "10px", height: "250px" }}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="info-content-card-slider">
        <div className="header-card">
          <h2 className="title">{providerDetails?.name}</h2>
          {providerDetails?.branches[0]?.address && (
            <div className="main-text-info d-flex align-items-center gap-2">
              <div className="icon">
                <img src={locationIcon} alt="icon location" width={"22px"} height={"22px"} />
              </div>
              <h2 className="text-info">
                {providerDetails?.branches.map((branch) => branch.address).join(", ")}
              </h2>
            </div>
          )}
        </div>

        <div className="bottom-card">
          {providerDetails?.services
            .filter((service) => service.is_active === "1")
            .map((service, index) => (
              <div
                key={index}
                className="main-bottom-card card mb-3 d-flex justify-content-between p-3 border-bottom border-2">
                <div className="right-bottom-card">
                  <h2 className="title">{service.name_ar}</h2>
                  <div className="info-time d-flex  align-items-center  gap-2">
                    <div className="icon">
                      <img src={clockIcon} alt="icon star" width={"22px"} height={"22px"} />
                    </div>
                    <span className="text-time">{service.service_time} دقيقة</span>
                  </div>
                </div>

                <div className="left-bottom-card">
                  <h2 className="num-price">{service.price} ر.س</h2>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CardsFilter;

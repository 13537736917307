import {
  faBox,
  faHandshake,
  faPercent,
  faTruck,
  faUsers,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { Link } from "react-router-dom";
import "./StatisticsReports.css";

const StatisticsReports = () => {
  const user = useGetAuthUser();
  const role = user.type;

  const reports = [
    { id: "services", name: "services", title: "تقارير الخدمات", icon: faHandshake },
    { id: "offers", name: "offers", title: "تقارير العروض", icon: faPercent },
    { id: "packages", name: "packages", title: "تقارير الباقات", icon: faBox },
    { id: "customers", name: "customers", title: "تقارير العملاء", icon: faUsers },
    { id: "employees", name: "employees", title: "تقارير الموظفين", icon: faUserTie },
    // { id: "financial", name: "financial", title: "تقارير مالية", icon: faMoneyBillWave }, // TODO: check if we need this
  ];

  const adminReports = [
    { id: "services-admin", name: "services", title: "تقارير الخدمات", icon: faHandshake },
    { id: "offers-admin", name: "offers", title: "تقارير العروض", icon: faPercent },
    { id: "packages-admin", name: "packages", title: "تقارير الباقات", icon: faBox },
    { id: "employees-admin", name: "employees", title: "تقارير الموظفين", icon: faUserTie },
    { id: "vendors-admin", name: "vendors", title: "تقارير الموردين", icon: faTruck },
    { id: "clients-admin", name: "clients", title: "تقارير العملاء", icon: faUsers },
  ];
  if (!role) return null;

  return (
    <section>
      <HelmetInfo titlePage="تقارير الإحصاءات" />
      <HeaderPageInfo title={"تقارير الإحصاءات"} />
      <div className="statistics-reports-container">
        <div className="reports-grid">
          {role === "admin"
            ? adminReports.map((report) => (
                <Link
                  to={`/dashboard/admin/statisticsreports/${report.id}`}
                  className="report-card"
                  key={report.id}>
                  <div className="report-icon">
                    <FontAwesomeIcon icon={report.icon} color="#000000" />
                  </div>
                  <h3>{report.title}</h3>
                </Link>
              ))
            : reports.map((report) => (
                <Link
                  to={`/dashboard/vendor/statisticsreports/${report.id}`}
                  className="report-card"
                  key={report.id}>
                  <div className="report-icon">
                    <FontAwesomeIcon icon={report.icon} color="#000000" />
                  </div>
                  <h3>{report.title}</h3>
                </Link>
              ))}
        </div>
      </div>
    </section>
  );
};

export default StatisticsReports;

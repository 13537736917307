import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ModalBookingDifference = ({ showModalBooking, hideModalBooking, setSettings }) => {
  const vendorSettings = useSelector((state) => state.vendor.vendorSettings);

  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);
  const [bookingDifferance, setBookingDifferance] = useState(null);

  useEffect(() => {
    if (Object.keys(vendorSettings).length > 0) {
      const { booking_differance } = vendorSettings;
      setBookingDifferance(booking_differance);
    }
  }, [vendorSettings]);

  useEffect(() => {
    if (bookingDifferance) {
      setIsSubmitAllowed(true);
    } else {
      setIsSubmitAllowed(false);
    }
  }, [bookingDifferance]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSettings = {
      ...vendorSettings,
      booking_differance: bookingDifferance,
      calender_differance_type: "fixed",
    };
    setSettings(newSettings);
    hideModalBooking();
  };

  return (
    <CustomModal
      show={showModalBooking}
      onHide={hideModalBooking}
      title={"فارق الحجز"}
      newClass={"modal-booking-diff"}>
      <div className="form-modal">
        <form action="">
          <div className="form-input-one mb-3">
            <label htmlFor="exampleInput1" className="form-label">
              فارق التقويم(بالدقيقة)
            </label>
            <select
              className="form-control"
              id="exampleInput1"
              value={bookingDifferance ?? ""}
              onChange={(e) => {
                setBookingDifferance(e.target.value);
              }}>
              <option value="">اختر الفارق الزمني</option>
              {Array.from({ length: 12 }, (_, i) => (i + 1) * 5).map((minutes) => (
                <option key={minutes} value={minutes}>
                  {minutes} دقيقة
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={handleSubmit}
            className="main-btn w-100 mt-4"
            type={"submit"}
            disabled={!isSubmitAllowed}>
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalBookingDifference;

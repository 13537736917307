import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import PasswordInput from "Components/Forms/PasswordInput";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { registerServiceProvider, sendRegisterOtp } from "store/vendor/vendorActions";
import { useAuthActions } from "./hooks/useAuthActions";
import OTPModal from "./OTPModal";
import { registerValidationSchema } from "./validationSchema";

const RegisterServicesStepThree = ({ registerData, setRegisterData, handlePrev }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleServiceProviderLogin } = useAuthActions();

  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpStep, setOtpStep] = useState(1);
  const [otp, setOtp] = useState("");
  const otpValue = useRef("");

  const initialValues = {
    email: registerData.email || "",
    first_name: registerData.first_name || "",
    last_name: registerData.last_name || "",
    phone: registerData.phone || "",
    password: "",
    password_confirmation: "",
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      setFormValues({ ...registerData, ...values });
      const response = await dispatch(sendRegisterOtp(values.email)).unwrap();
      if (response.success) {
        setShowOtpModal(true);
        otpValue.current = response.data[0].toString();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = useCallback(async () => {
    if (!formValues?.email) return;
    setIsLoading(true);
    try {
      const response = await dispatch(sendRegisterOtp(formValues.email)).unwrap();
      if (response.success) {
        setOtpStep(1);
        otpValue.current = response.data[0].toString();
        toast.success("تم إرسال رمز التحقق مرة أخرى");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, formValues?.email]);

  const handleSubmitFinalDetails = async () => {
    if (!isValidOtp(otp, otpValue.current)) {
      toast.error("رمز التحقق غير صحيح");
      return;
    }

    try {
      const lastNameShortned = formValues.last_name.slice(0, 20);
      const formUpdated = { ...formValues, last_name: lastNameShortned };
      // console.log(formUpdated, "formUpdated");
      formUpdated.category_id = [1]; // TODO: change this to the category id

      const response = await dispatch(registerServiceProvider(formUpdated)).unwrap();
      if (response.success) {
        await handleSuccessfulRegistration();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSuccessfulRegistration = async () => {
    setOtpStep(2);
    setTimeout(async () => {
      setShowOtpModal(false);
      toast.success("تم تسجيل مزود الخدمة بنجاح");
      await handleServiceProviderLogin(formValues.email, formValues.password, navigate);
    }, 2500);
  };

  const handleError = (error) => {
    setShowOtpModal(false);
    const errors = Object.values(error.errors || {}).flat();
    errors.forEach((error) => toast.error(error));
  };

  return (
    <>
      {showOtpModal == true && (
        <OTPModal
          showOtpModal={showOtpModal}
          otpStep={otpStep}
          formValues={formValues}
          otp={otp}
          setOtp={setOtp}
          handleVerifyOtp={handleSubmitFinalDetails}
          handleResendOtp={handleResendOtp}
          setShowOtpModal={setShowOtpModal}
          isLoading={isLoading}
        />
      )}

      <div className="all-step-three bg-step">
        <FormField
          initialValues={initialValues}
          validationSchema={registerValidationSchema}
          onSubmit={handleSubmit}>
          <div className="row g-2">
            <div className="col-12 col-md-6">
              <InputField
                label={"الاسم الأول"}
                name="first_name"
                type="text"
                placeholder={"الاسم الأول"}
                success
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, first_name: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={"الاسم الأخير"}
                name="last_name"
                type="text"
                placeholder={"الاسم الأخير"}
                success
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, last_name: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={"الرقم الجوال"}
                name="phone"
                type="tel"
                placeholder={"الرقم الجوال"}
                success
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, phone: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={"البريد الألكتروني"}
                name="email"
                type="email"
                placeholder={"البريد الألكتروني"}
                success
                handleOnChange={(value) => {
                  setRegisterData((prevData) => ({ ...prevData, email: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <PasswordInput
                label="كلمة المرور"
                name="password"
                placeholder="كلمة المرور"
                handleOnChange={(value) => {
                  setRegisterData((prev) => ({ ...prev, password: value }));
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <PasswordInput
                label="تاكيد كلمة المرور"
                name="password_confirmation"
                placeholder="تأكيد كلمة المرور"
                handleOnChange={(value) => {
                  setRegisterData((prev) => ({ ...prev, password_confirmation: value }));
                }}
              />
            </div>
          </div>

          <div className="button-container mt-4">
            <button type="button" className="btn main-btn btn-prev" onClick={handlePrev}>
              السابق
            </button>
            <button
              type="submit"
              className="btn main-btn btn-submit"
              disabled={!isFormValid(registerData)}>
              تسجيل
            </button>
          </div>
        </FormField>
      </div>
    </>
  );
};

const isFormValid = (data) => {
  return (
    data.email &&
    data.first_name &&
    data.last_name &&
    data.phone &&
    data.password &&
    data.password_confirmation
  );
};

const isValidOtp = (userOtp, correctOtp) => userOtp === correctOtp;

export default RegisterServicesStepThree;

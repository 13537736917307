import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";

const ModalCancellationReservation = ({
  vendorSettings,
  showModalCancel,
  hideModalCancel,
  setSettings,
}) => {
  const [allowCancel, setAllowCancel] = useState(vendorSettings?.cancel_booking || "0");

  const [timeDifference, setTimeDifference] = useState(vendorSettings?.calender_differance);
  const [submitAllowed, setSubmitAllowed] = useState(false);

  useEffect(() => {
    if (vendorSettings) {
      setAllowCancel(vendorSettings.cancel_booking);
      setTimeDifference(vendorSettings.calender_differance);
    }
  }, [vendorSettings]);

  useEffect(() => {
    if (allowCancel === "1" || allowCancel === 1) {
      setSubmitAllowed(true);
    }
  }, [allowCancel]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSettings = {
      ...vendorSettings,
      cancel_booking: allowCancel,
      calender_differance: timeDifference,
    };
    setSettings(newSettings);
    hideModalCancel();
  };

  return (
    <CustomModal
      show={showModalCancel}
      onHide={hideModalCancel}
      title={"إدارة إلغاء الحجز"}
      newClass={"modal-booking-diff"}>
      <div className="form-modal">
        <form onSubmit={handleSubmit}>
          <div className="form-input-one mb-4">
            <label htmlFor="allowCancel" className="form-label">
              هل يمكن إلغاء الحجز قبل موعد الحجز؟
            </label>
            <select
              className="form-select form-select-lg select-box-option-1"
              id="allowCancel"
              value={allowCancel}
              onChange={(e) => setAllowCancel(e.target.value)}>
              <option value="1">نعم</option>
              <option value="0">لا</option>
            </select>
          </div>

          {(allowCancel == "1" || allowCancel === 1) && (
            <div className="form-input-one mb-4">
              <label htmlFor="timeDifference" className="form-label">
                الوقت المسموح للإلغاء قبل موعد الحجز (بالدقائق)
              </label>
              <select
                className="form-select form-select-lg select-box-option-1"
                id="timeDifference"
                value={timeDifference || ""}
                onChange={(e) => setTimeDifference(e.target.value)}>
                <option value="">اختر الوقت المسموح</option>
                {Array.from({ length: 6 }, (_, i) => (i + 1) * 5).map((minutes) => (
                  <option key={minutes} value={minutes}>
                    {minutes} دقيقة
                  </option>
                ))}
              </select>
            </div>
          )}

          <button type="submit" className="main-btn w-100 mt-4" disabled={!submitAllowed}>
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalCancellationReservation;

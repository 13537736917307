import { useSelector } from "react-redux";
import CategoryFilter from "./CategoryFilter";

const CardsProducts = () => {
  const sectionsList = useSelector((state) => state.vendor.sections);
  const servicesList = useSelector((state) => state.vendor.services);

  if (sectionsList.length === 0 || servicesList.length === 0) {
    return <div className="text-center text-muted my-5 py-5 fs-5">ليس هناك خدمات للعرض</div>;
  }

  return (
    <div className="all-cards-products-pos mt-3">
      <div className="category-filter">
        <CategoryFilter sections={sectionsList} services={servicesList} />
      </div>
    </div>
  );
};

export default CardsProducts;

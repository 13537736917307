import { Document, Font, Page, StyleSheet, Text, View, pdf } from "@react-pdf/renderer";
import FormField from "Components/Forms/FormFiled";
import SelectField from "Components/Forms/SelectField";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import amiriRegular from "../../../../../Components/BookingsInfo/DetailsOrderBooking/BookingCoastDetails/Amiri-Regular.ttf";
import iconExport from "../../../../../assets/images/dashboardImages/icons/export.svg";

// Register Font
Font.register({
  family: "Amiri",
  src: amiriRegular,
});

// Validation Schema
const validationSchema = Yup.object().shape({
  period: Yup.string().required("الفترة مطلوبة"),
});

// Styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: "Amiri",
    fontSize: 12,
    direction: "rtl",
    color: "#333",
    backgroundColor: "#fff",
  },
  title: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    color: "#1e293b",
    padding: "10 0",
    borderBottom: "2px solid #e2e8f0",
  },
  dateBox: {
    border: "1px solid #e2e8f0",
    borderRadius: 6,
    padding: 12,
    marginBottom: 15,
    backgroundColor: "#f8fafc",
  },
  statsContainer: {
    marginTop: 15,
    border: "1px solid #e2e8f0",
    borderRadius: 8,
    padding: 15,
    backgroundColor: "#fff",
  },
  statRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "8 0",
    borderBottom: "1px solid #e2e8f0",
  },
  lastRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "8 0",
    borderBottom: "1px solid #e2e8f0",
  },
  label: {
    fontSize: 14,
    color: "#64748b",
    flex: 1,
  },
  value: {
    fontSize: 14,
    color: "#1e293b",
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
  },
  dateText: {
    fontSize: 14,
    color: "#64748b",
    textAlign: "center",
  },
  headerBox: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: "#f1f5f9",
    borderRadius: 8,
    border: "1px solid #e2e8f0",
  },
  headerTitle: {
    fontSize: 18,
    color: "#1e293b",
    textAlign: "center",
    fontWeight: "bold",
  },
});

const HeaderHome = ({ infoDetailsBookings, period, setPeriod }) => {
  const generatePDF = async () => {
    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerBox}>
            <Text style={styles.headerTitle}>تقرير إحصائيات النظام</Text>
          </View>

          <View style={styles.dateBox}>
            <Text style={styles.dateText}>
              تاريخ التقرير: {new Date().toLocaleDateString("ar-EG")}
            </Text>
          </View>

          <View style={styles.statsContainer}>
            <View style={styles.statRow}>
              <Text style={styles.value}>{infoDetailsBookings.booking_count}</Text>
              <Text style={styles.label}>عدد الحجوزات </Text>
            </View>

            <View style={styles.statRow}>
              <Text style={styles.value}>{infoDetailsBookings.booking_total} ر.س</Text>
              <Text style={styles.label}>إجمالي الحجوزات</Text>
            </View>

            <View style={styles.statRow}>
              <Text style={styles.value}>{infoDetailsBookings.offer_count}</Text>
              <Text style={styles.label}>عدد العروض المستخدمة</Text>
            </View>

            <View style={styles.statRow}>
              <Text style={styles.value}>{infoDetailsBookings.offer_total} ر.س</Text>
              <Text style={styles.label}>إجمالي العروض المستخدمة</Text>
            </View>

            <View style={styles.lastRow}>
              <Text style={styles.value}>{infoDetailsBookings.customer_count}</Text>
              <Text style={styles.label}>عدد العملاء </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

    const blob = await pdf(MyDocument()).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "system-report.pdf";
    link.click();
    URL.revokeObjectURL(url);
  };

  const [selectedPeriod, setSelectedPeriod] = useState("اليوم");
  const user = useSelector((state) => state.auth.user);
  const isVendor = user.type === "vendor";

  const handleChangePeriod = (value) => {
    setSelectedPeriod(value);
    setPeriod(value);
  };

  const initialValues = {
    period: selectedPeriod,
  };

  return (
    <div className="header-home" data-aos="fade-left">
      <HeaderPageInfo
        title={"الصفحة الرئيسية"}
        isShowLeftContent={true}
        contentPageLeft={
          <div className="info-content-head d-flex align-items-center gap-3">
            {isVendor && (
              <FormField initialValues={initialValues} validationSchema={validationSchema}>
                <SelectField
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid #e2e8f0",
                    padding: "10px",
                    fontSize: "14px",
                    color: "#64748b",
                    backgroundColor: "#f8fafc",
                    marginBottom: "15px",
                  }}
                  name="period"
                  options={[
                    { value: "today", label: "يوم" },
                    { value: "week", label: "أسبوع" },
                    { value: "month", label: "شهر" },
                    { value: "year", label: "سنة" },
                  ]}
                  valueSelected={"المدة"}
                  handleOnChange={handleChangePeriod}
                />
              </FormField>
            )}
            <button className="main-btn" onClick={generatePDF}>
              <div className="icon-export">
                <img src={iconExport} alt="icon export" width={"25px"} height={"25px"} />
              </div>
              تصدير التقرير
            </button>
          </div>
        }
      />
    </div>
  );
};

export default HeaderHome;

import { unwrapResult } from "@reduxjs/toolkit";
import { Formik } from "formik";
import { useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addVendorPOSBooking } from "store/vendor/vendorActions";
import { clearCart, removeServiceFromCart } from "store/vendor/vendorSlice";
import * as Yup from "yup";
import { BookingForm } from "./components/BookingForm";
import { CartServices } from "./components/CartServices";
import { PaymentDetails } from "./components/PaymentDetails";
import { useBookingForm } from "./hooks/useBookingForm";

const validationSchema = Yup.object().shape({
  selectedEmployee: Yup.number().required("يرجى اختيار الموظف"),
  selectedClient: Yup.number().required("يرجى اختيار العميل"),
  selectedPaymentMethod: Yup.string().required("يرجى اختيار طريقة الدفع"),
  bookingDate: Yup.date().required("يرجى اختيار تاريخ الحجز"),
  bookingTime: Yup.string().required("يرجى اختيار وقت الحجز"),
});

const PosCartDetails = ({ detailsPay }) => {
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.vendor.cartDetails);
  // const branches = useSelector((state) => state.vendor.branches);

  const {
    formState,
    handlers,
    availableTimeSlots,
    availableEmployees,
    allCustomers,
    allEmployees,
    workingDays,
  } = useBookingForm();

  const handleSubmit = async (values) => {
    try {
      const { selectedEmployee, selectedClient, bookingDate, bookingTime } = values;
      const { services, payment } = cartDetails;
      const { method, total, discount } = payment;
      const [hours, minutes] = bookingTime.split(":");

      const employeeBranchId = allEmployees.find((employee) => employee.id === selectedEmployee)
        ?.branches[0]?.id;

      const order = {
        employee_id: selectedEmployee,
        customer_id: selectedClient,
        services: services.map((service) => service.serviceId),
        attendance: 1,
        sub_total: total,
        discount: discount,
        total: total,
        payment_way: method,
        branch_id: employeeBranchId || 1,
        notes: "no_notes",
        booking_day: bookingDate.toISOString().split("T")[0],
        booking_time: `${hours}:${minutes}`,
      };

      console.log({ order });
      if (!order.booking_time) {
        toast.error("يرجى اختيار وقت الحجز");
        return;
      }
      if (!order.booking_day) {
        toast.error("يرجى اختيار تاريخ الحجز");
        return;
      }
      if (!order.customer_id) {
        toast.error("يرجى اختيار العميل");
        return;
      }
      if (!order.employee_id) {
        toast.error("يرجى اختيار الموظف");
        return;
      }
      if (!order.services) {
        toast.error("يرجى اختيار الخدمات");
        return;
      }
      if (!order.payment_way) {
        toast.error("يرجى اختيار طريقة الدفع");
        return;
      }
      console.log("order", order);
      const result = await dispatch(addVendorPOSBooking(order)).then(unwrapResult);

      console.log({ result });
      handlers.resetForm();
      dispatch(clearCart());
      toast.success("تم إضافة الطلب بنجاح");
    } catch (error) {
      console.error("Booking failed:", error);
      toast.error("حدث خطأ ما");
      dispatch(clearCart());
    }
  };

  const initialValues = useMemo(
    () => ({
      selectedEmployee: "",
      selectedClient: "",
      selectedPaymentMethod: "",
      bookingDate: new Date(),
      bookingTime: "",
    }),
    [],
  );

  return (
    <div className="details-order">
      <h2 className="title-top">تفاصيل الطلب</h2>

      <CartServices
        services={cartDetails.services}
        onRemoveService={(id) => dispatch(removeServiceFromCart(id))}
      />

      <PaymentDetails detailsPay={detailsPay} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ setFieldValue, isValid, dirty, values }) => (
          <BookingForm
            values={values}
            setFieldValue={setFieldValue}
            formState={formState}
            handlers={handlers}
            availableTimeSlots={availableTimeSlots}
            availableEmployees={availableEmployees}
            allCustomers={allCustomers}
            workingDays={workingDays}
            isValid={isValid}
            dirty={dirty}
          />
        )}
      </Formik>
    </div>
  );
};

export default PosCartDetails;

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAssignedRandmonRatings } from "store/customer/customerSlice";
import BannerHome from "./BannerHome/BannerHome";
import Header from "./Header/Header";
import ServiceProvidersHome from "./ServiceProviders/ServiceProvidersHome";
import SpecialOffersHome from "./SpecialOffers/SpecialOffers";

const randomTotalRatings = () => {
  return Math.floor(Math.random() * 30) + 1;
};

const randomRating = () => {
  return (Math.random() * (5 - 4) + 4).toFixed(1);
};

const HomeInfo = ({
  serviceProviders,
  vendorsBranches,
  servicesList,
  offers,
  searchProviders,
  isLoading,
}) => {
  const isLogged = useSelector((state) => state.auth.isLogedIn);
  const user = useSelector((state) => state.auth.user);
  const assignedRandmonRatings = useSelector((state) => state.customer.assignedRandmonRatings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (assignedRandmonRatings.length === 0) {
      const ratings = [];
      for (let i = 0; i < serviceProviders.length; i++) {
        ratings.push({
          id: serviceProviders[i].id,
          rating: randomRating(),
          totalRatings: randomTotalRatings(),
        });
      }

      dispatch(setAssignedRandmonRatings(ratings));
    }
  }, [assignedRandmonRatings.length, dispatch, serviceProviders]);

  return (
    <>
      <HelmetInfo titlePage={"الرئيسية"} />
      <>
        <Header searchProviders={searchProviders} />
      </>

      <main>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <SpecialOffersHome offers={offers} isLogged={isLogged} user={user} />

            <ServiceProvidersHome
              serviceProviders={serviceProviders}
              vendorsBranches={vendorsBranches}
              servicesList={servicesList}
              assignedRandmonRatings={assignedRandmonRatings}
              isLogged={isLogged}
              user={user}
            />

            <BannerHome />
          </>
        )}
      </main>
    </>
  );
};

export default HomeInfo;

import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./ReportDetails.css";

const ProductsReport = ({ bookingDay }) => {
  const dummyData = {
    totalProducts: 1000,
    mostRequestedProducts: [
      { name: "منتج 1", sales: 10, revenue: 1000 },
      { name: "منتج 2", sales: 20, revenue: 2000 },
      { name: "منتج 3", sales: 15, revenue: 1500 },
      { name: "منتج 4", sales: 25, revenue: 2500 },
      { name: "منتج 5", sales: 30, revenue: 3000 },
      { name: "منتج 6", sales: 18, revenue: 1800 },
    ],
  };

  return (
    <div className="product-report">
      <div className="report-summary">
        <div className="summary-card">
          <div className="card-content">
            <h3>{dummyData.totalProducts}</h3>
            <p>عدد المنتجات</p>
          </div>
          <FontAwesomeIcon icon={faList} className="icon" />
        </div>
      </div>

      <div className="report-table">
        <h3>المنتجات الأكثر مبيعًا</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>المنتج</th>
              <th>عدد المبيعات</th>
              <th>الإيرادات</th>
            </tr>
          </thead>
          <tbody>
            {dummyData.mostRequestedProducts.map((product, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{product.name}</td>
                <td>{product.sales}</td>
                <td>{product.revenue} ريال</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsReport;

const DisplayField = ({ label = "", value = "", disabled = true, className = "" }) => {
  return (
    <div
      className={`form-group input-field-info d-flex flex-column gap-1 position-relative form-one`}>
      {label && <label className="form-label">{label}</label>}
      <input
        value={value}
        className={`input-field form-control ${className}`}
        disabled={disabled}
        readOnly
      />
    </div>
  );
};

export default DisplayField;

// @ts-nocheck
import "@fortawesome/fontawesome-free/css/all.min.css";
import AosAnimation from "Components/AosAnimation/AosAnimation";
import ScrollToTopButton from "Components/ButtonScroll/ButtonScroll";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import STORE from "store";
import "./App.css";
import "./assets/CairoFont/CairoFont.css";
import ToastContainerApp from "./Components/ToastContainerApp/ToastContainerApp";
import routers from "./Routers/Routers";
import "/node_modules/bootstrap/dist/css/bootstrap.rtl.min.css";
import "/node_modules/bootstrap/dist/js/bootstrap.bundle";
import "/node_modules/bootstrap/dist/js/bootstrap.min";
``;

function App() {
  return (
    <Provider store={STORE}>
      <AosAnimation>
        <div className="App">
          <ToastContainerApp />
          <ScrollToTopButton />
          <HelmetProvider>
            <RouterProvider router={routers} />
          </HelmetProvider>
        </div>
      </AosAnimation>
    </Provider>
  );
}

export default App;

import { faClock as faClockReg } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonCounter from "../ButtonCounter";

const CardProductDetails = ({
  serviceDetails,
  serviceId,
  imgProduct,
  titleService,
  timeServ,
  price,
  handleAddToCart,
  handleRemoveFromCart,
}) => {
  const addCartService = () => {
    handleAddToCart({
      serviceId,
      price,
      titleService,
      serviceDetails,
    });
  };

  const removeCartService = () => {
    handleRemoveFromCart(serviceId);
  };

  return (
    <div className="card-product-category">
      <div className="img-product">
        <img src={imgProduct} alt="img product" />
      </div>
      <div className="info-content">
        <h2 className="title">{titleService}</h2>
        <div className="time-add d-flex align-items-center gap-2">
          <div className="icon-clock">
            <FontAwesomeIcon icon={faClockReg} />
          </div>
          <h2 className="text-time">{timeServ}</h2>
        </div>
        <div className="bottom-details-pro d-flex aling-items-center justify-content-between gap-2 pb-2">
          <div className="price-product">{price} ر.س</div>
          {/*  */}
          <ButtonCounter
            serviceId={serviceId}
            addToCart={() => addCartService()}
            removeCart={() => removeCartService()}
          />
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default CardProductDetails;

import SectionTitle from "Components/SectionTitle/SectionTitle";
import ProviderCard from "Components/Ui/CardProduct/ProviderCard";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { extractBranchesFromVendors } from "utlis";
import locationIcon from "../../../assets/images/icons/location.svg";
import starIcon from "../../../assets/images/icons/star.svg";
import imgCard from "../../../assets/images/products/01.png";
import Products from "../Products/Products";

const ServiceProvidersHome = ({ serviceProviders, assignedRandmonRatings }) => {
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!serviceProviders) {
      setIsLoading(false);
      return;
    }
    try {
      const branchesFromVendors = extractBranchesFromVendors(serviceProviders);
      setBranches(branchesFromVendors);
    } catch (err) {
      setError("Failed to extract branches from vendors");
    } finally {
      setIsLoading(false);
    }
  }, [serviceProviders]);

  const navigate = useNavigate();
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Products newClassection={"services-providers"}>
      <SectionTitle
        title={"مزودى الخدمات المميزين"}
        text={"احجز مع شركاء ذوي تصنيف عالٍ بأسعار تنافسية"}
        dataAos={"fade-down"}
      />
      <div className="main-products" data-aos="fade-up">
        <>
          <div className="main-products" data-aos="fade-up">
            <div className="row g-3">
              {branches.map((branch, index) => (
                <div
                  key={branch.id || index}
                  className="col-md-4 col-lg-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/servicesproviders/${branch.id}`);
                  }}>
                  <ProviderCard
                    bgColorBadge={"teal-badge"}
                    textBadge={"مميز"}
                    newClassBadge={"badge-product"}
                    imgCard={branch.photo ?? imgCard}
                    cardTitle={`${branch.name}`}
                    locationIcon={locationIcon}
                    textLocation={branch.address}
                    starIcon={starIcon}
                    numRate={
                      assignedRandmonRatings.find((rating) => rating.id === branch.id)?.rating
                    }
                    totalRate={
                      assignedRandmonRatings.find((rating) => rating.id === branch.id)?.totalRatings
                    }
                    routeCard={`/servicesproviders/${branch.id}`}
                    textButton={"القسم"}
                    isIconFavorite={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="button-all mt-4 d-flex justify-content-center align-items-center">
            <Link to="servicesproviders" className="main-btn main-outline-btn">
              اكتشف المزيد
            </Link>
          </div>
        </>
      </div>
    </Products>
  );
};

export default ServiceProvidersHome;

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getNotificationSettings, updateNotificationSettings } from "store/admin/adminActions";

const AdminNotificationSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({
    email: "0",
    sms: "0",
    whatsapp: "0",
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const location = useLocation();
  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(getNotificationSettings()).unwrap();
      setSettings({
        email: response.data.email,
        sms: response.data.sms,
        whatsapp: response.data.whatsapp,
      });
    } catch (error) {
      toast.error("حدث خطأ في جلب الإعدادات");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await dispatch(updateNotificationSettings(settings)).unwrap();
      toast.success("تم تحديث إعدادات الإشعارات بنجاح");
      navigate("/dashboard/admin/adminSettings");
    } catch (error) {
      toast.error("حدث خطأ في تحديث الإعدادات");
    } finally {
      setIsLoading(false);
    }
  };

  const notificationTypes = [
    {
      id: "email",
      title: "البريد الإلكتروني",
      iconColor: "#EA4335",
    },
    {
      id: "sms",
      title: "الرسائل النصية",
      iconColor: "#4285F4",
    },
    {
      id: "whatsapp",
      title: "واتساب",
      iconColor: "#25D366",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          opacity: 0.6,
          pointerEvents: "none",
          filter: "grayscale(20%)",
        }}>
        <HelmetInfo titlePage="إعدادات الإشعارات" />

        <div className="header-page-w" data-aos="fade-left">
          <HeaderPageInfo
            title="إعدادات الإشعارات"
            isShowLeftContent={true}
            contentPageLeft={
              <div className="link-back">
                <Link
                  className="size-text-color-dark main-text-color fs-5"
                  to="/dashboard/admin/adminSettings"
                  style={{ pointerEvents: "auto" }}>
                  عودة
                </Link>
              </div>
            }
          />
        </div>

        <form onSubmit={handleSubmit} className="all-cards-pay mt-3" data-aos="fade-up">
          {notificationTypes.map((type) => (
            <label
              key={type.id}
              className="card-pay-1 mb-3 d-flex align-items-center gap-3"
              htmlFor={type.id}>
              <input
                type="checkbox"
                className="form-check-input"
                id={type.id}
                checked={settings[type.id] === "1"}
                onChange={(e) =>
                  setSettings((prev) => ({
                    ...prev,
                    [type.id]: e.target.checked ? "1" : "0",
                  }))
                }
                disabled={true}
              />
              <div className="card-content d-flex align-items-center flex-wrap gap-3">
                <h2 className="title mb-0">تفعيل إشعارات {type.title}</h2>
              </div>
            </label>
          ))}

          <button
            type="submit"
            className="main-btn w-20 mt-4"
            disabled={true}
            style={{ cursor: "not-allowed" }}>
            حفظ التغييرات
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminNotificationSettings;

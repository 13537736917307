import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import MultiSelect from "Components/Forms/MultiSelect";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { getServiceCategories } from "store/general/generalActions";

import {
  addService as addVendorService,
  getBranches as getVendorBranches,
  getSections as getVendorSections,
  getService as getVendorService,
  updateService as updateVendorService,
} from "store/vendor/vendorActions";

import {
  addService as addAdminService,
  getBranches as getAdminBranches,
  getSections as getAdminSections,
  getService as getAdminService,
  updateService as updateAdminService,
} from "store/admin/adminActions";

import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("اسم الخدمة مطلوب"),
  description_ar: Yup.string().required("وصف الخدمة مطلوب"),
  price: Yup.number().required("سعر الخدمة مطلوب"),
  section_id: Yup.string().required("تبويب الخدمة مطلوب"),
  // category_id: Yup.string().required("القسم الرئيسيى مطلوب"),
  featured: Yup.string().required("هل الخدمة مميزة؟ مطلوب"),
  extra_time: Yup.string().required("الوقت الأضافى لاعداد الخدمة مطلوب"),
  service_time: Yup.string().required("الوقت المستغرق لاتمام الخدمة مطلوب"),
  branch_id: Yup.array().min(1).required("الفروع للخدمة مطلوب"),
});

const actionTypes = [
  {
    name: "#/new-section",
    for: "add new section",
  },
];

const EditAddServicesDetails = () => {
  const [categories, setCategories] = useState([]);
  const [branches, setBranches] = useState([]);
  const [sections, setSections] = useState([]);
  const [formValues, setFormValues] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { hash: action } = useLocation();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const isAdmin = user?.type === "admin";

  const initialValues = {
    name_ar: "",
    price: "",
    description_ar: "",
    category_id: "",
    featured: "",
    extra_time: "",
    service_time: "",
    branch_id: "",
    // employee_id: "",
  };

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (values, { resetForm, setErrors }) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const serviceAction = isAdmin
      ? id
        ? updateAdminService
        : addAdminService
      : id
        ? updateVendorService
        : addVendorService;

    const formattedData = {
      ...values,
      id: id,
      category_id: values.category_id,
      ...(id
        ? {}
        : {
            name_en: "dummy_name_en",
            description_en: "dummy_descriptions_en",
          }),
    };

    const category_id = parseInt(categories[0].value);
    formattedData.category_id = category_id;
    formattedData.sub_category_id = category_id;

    dispatch(serviceAction(formattedData))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم التعديل بنجاح.");
        setIsDataLoading(true);
        getServiceDetails();
        setTimeout(() => {
          navigate(isAdmin ? "/dashboard/admin/servicespage" : "/dashboard/vendor/servicespage");
        }, 1000);
      })
      .catch((error) => {
        setErrors(error.errors);
        console.log(error, "error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const fetchServiceCategoriesList = () => {
    const params = {
      limit: 100,
      offset: 0,
      sort: "DESC",
      paginate: "true",
      field: "id",
      deleted: "undeleted",
      columns: ["is_active"],
      operand: ["="],
      column_values: ["1"],
    };
    dispatch(getServiceCategories(params))
      .then(unwrapResult)
      .then((res) => {
        console.log(res, "res");
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setCategories(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchBranchesList = () => {
    const params = {
      has: "vendor",
      with: ["vendor"],
      deleted: "undeleted",
    };

    const branchesAction = isAdmin ? getAdminBranches : getVendorBranches;
    if (!isAdmin) {
      params.deleted = "undeleted";
      params.columns = ["vendor_id", "is_active"];
      params.operand = ["=", "="];
      params.column_values = [user.model_id, "1"];
    }

    dispatch(branchesAction(params))
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name,
          value: cat.id,
        }));
        setBranches(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchSectionsList = () => {
    const sectionsAction = isAdmin ? getAdminSections : getVendorSections;
    const params = {
      with: [],
      limit: 100,
      offset: 0,
      sort: "DESC",
      paginate: "true",
      field: "id",
      deleted: "undeleted", //all,deleted
      columns: ["vendor_id", "is_active"],
      operand: ["=", "="],
      column_values: [user.model_id, "1"],
    };

    dispatch(sectionsAction(params))
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setSections(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getServiceDetails = () => {
    const serviceAction = isAdmin ? getAdminService : getVendorService;
    dispatch(serviceAction(id))
      .then(unwrapResult)
      .then((res) => {
        const branch_id = res.data.branches.map((branch) => branch.id);
        const employee_id = res.data.employees.map((branch) => branch.id);
        const updatedData = {
          ...res.data,
          branch_id,
          employee_id,
          category_id: res.data.category_id.toString(),
        };
        setFormValues(updatedData);
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchServiceCategoriesList();
    fetchBranchesList();
    fetchSectionsList();
    if (id) {
      setIsDataLoading(true);
      getServiceDetails();
    }
  }, [id]);

  return (
    <>
      <HelmetInfo titlePage={"اضافة تعديل بيانات الخدمة"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={
            isAdmin ? "/dashboard/admin/homedashboard" : "/dashboard/vendor/homedashboard"
          }
          titleInfoPage={"اضافة / تعديل بيانات الخدمة"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to={isAdmin ? "/dashboard/admin/servicespage" : "/dashboard/vendor/servicespage"}>
            عودة
          </Link>
        </div>
      </div>

      <div className="form-edit-services" data-aos="fade-up">
        {isDataLoading ? (
          <Loader />
        ) : (
          <FormField
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            <div className="row g-1 g-sm-3">
              <div className="col-12">
                <InputField
                  name="name_ar"
                  type="text"
                  placeholder={"اسم الخدمة بالعربية"}
                  label={"اسم الخدمة بالعربية"}
                  success
                />
              </div>

              <div className="col-12 col-md-12">
                <InputField
                  name="description_ar"
                  placeholder={"وصف الخدمة بالعربية"}
                  label={"وصف الخدمة بالعربية"}
                  success
                />
              </div>

              <div className="col-12 col-md-6">
                <InputField
                  label={"سعر الخدمة"}
                  name="price"
                  type="number"
                  placeholder={"سعر الخدمة"}
                  success
                />
              </div>
              {action !== actionTypes[0].name && (
                <div className="col-12 col-md-6">
                  <label htmlFor="exampleFormControlInput24" className="form-label">
                    تبويب الخدمة
                  </label>
                  <SelectField
                    name="section_id"
                    label={false}
                    options={sections}
                    valueSelected={"تبويب الخدمة"}
                  />
                </div>
              )}

              <div className="col-12 col-md-6">
                <label htmlFor="exampleFormControlInput26" className="form-label">
                  هل الخدمة مميزة؟
                </label>
                <SelectField
                  name="featured"
                  label={false}
                  options={[
                    { value: 1, label: "نعم" },
                    { value: 0, label: "لا" },
                  ]}
                  valueSelected={"هل الخدمة مميزة؟"}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label={" الوقت المستغرق لاتمام الخدمة بالدقائق"}
                  placeholder={"الوقت المستغرق لاتمام الخدمة"}
                  name="service_time"
                  type="number"
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  label={"الوقت الأضافى لاعداد الخدمة بالدقائق"}
                  placeholder={"الوقت الأضافى لاعداد الخدمة"}
                  name="extra_time"
                  type="number"
                  success
                />
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="exampleFormControlInput28" className="form-label">
                  الفروع للخدمة
                </label>
                <MultiSelect
                  name="branch_id"
                  label={false}
                  placeholder={"الفروع للخدمة"}
                  options={branches}
                />
              </div>

              {/* <div className="col-12 col-md-6">
                <label htmlFor="exampleFormControlInput29" className="form-label">
                  اختيار الموظفين للخدمة
                </label>
                <MultiSelect
                  name="employee_id"
                  label={false}
                  placeholder={"اختيار الموظفين للخدمة"}
                  options={employees.map((employee) => ({
                    value: employee.id,
                    label: `${employee.user.first_name} ${employee.user.last_name}`,
                  }))}
                />
              </div> */}
            </div>

            <button type="submit" className="main-btn btn-submit px-5 mt-4" disabled={isSubmitting}>
              {isSubmitting ? "جاري الحفظ..." : "حفظ"}
            </button>
          </FormField>
        )}
      </div>
    </>
  );
};

export default EditAddServicesDetails;

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listBookings } from "store/customer/customerActions";
import { setCartBookingDay, setCartBookingTime } from "store/customer/customerSlice";
import Loader from "../../../../Components/Loader/Loader";
import { default as DaySelection } from "./DateTime";
import "./StepThreeServices.css";

const StepThree_3_Services = ({
  bookingDetails,
  setBookingDetails,
  employeeInfo,
  currentVendorSettings,
}) => {
  const dispatch = useDispatch();

  const [activeButton, setActiveButton] = useState(null);
  const [selectedDay, setSelectedDay] = useState(bookingDetails.booking_day || "");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDayBookings, setSelectedDayBookings] = useState([]);

  const vendorSupportedPayment = currentVendorSettings.accept_payment;
  const isBothPayment = vendorSupportedPayment === "both";

  useEffect(() => {
    setBookingDetails((prev) => ({
      ...prev,
      booking_time: "",
    }));
    dispatch(setCartBookingTime(""));
  }, [bookingDetails.booking_day]);

  useEffect(() => {
    const getSelectedDayBookingsList = async () => {
      if (!bookingDetails.booking_day) return;

      setIsLoading(true);
      const params = {
        with: ["bookingService", "bookingService.service"],
        limit: 50,
        offset: 0,
        sort: "DESC",
        paginate: "true",
        field: "id",
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["is_active", "booking_day", "booking_day"],
        operand: ["=", ">=", "<="],
        column_values: ["1", bookingDetails.booking_day, bookingDetails.booking_day], // use this because of the format 2024-12-10
      };
      try {
        const bookings = await dispatch(listBookings(params)).unwrap();
        setSelectedDayBookings(bookings.data.data);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
      setIsLoading(false);
    };
    getSelectedDayBookingsList();
  }, [bookingDetails.booking_day]);

  const [timeSlots, setTimeSlots] = useState([]);
  const [slotsCumilativeTime, setSlotsCumilativeTime] = useState([]);

  useEffect(() => {
    const slotsCumilativeTime = timeSlots.map((_, index) => {
      return index * currentVendorSettings.booking_differance;
    });
    setSlotsCumilativeTime(slotsCumilativeTime);
  }, [timeSlots]);

  const employeeOfficialHours = employeeInfo?.official_hours || [];
  const employeeDays = employeeInfo?.official_hours?.map((item) => item.day) || [];

  const generateTimeSlots = useCallback(
    (start, end) => {
      const slots = [];
      let current = new Date(`2000-01-01T${start}`);
      const endTime = new Date(`2000-01-01T${end}`);
      const bookingDifference = currentVendorSettings.booking_differance;

      while (current < endTime) {
        slots.push(
          current.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        );
        current.setMinutes(current.getMinutes() + bookingDifference);
      }

      return slots;
    },
    [bookingDetails.services, currentVendorSettings.booking_differance],
  );

  const handleDaySelect = useCallback(
    (dateInfo) => {
      setSelectedDay(dateInfo);

      const [dayDate, dayOfWeek] = dateInfo.split("*");
      const dateObj = new Date(dayDate);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const year = dateObj.getFullYear();

      const formattedDate = `${year}-${month}-${day}`;
      dispatch(setCartBookingDay(formattedDate));

      setBookingDetails((prev) => ({
        ...prev,
        booking_day: formattedDate,
      }));

      const { start_time, end_time } = employeeOfficialHours.find(
        (hours) => hours.day === dayOfWeek,
      );

      if (start_time && end_time) {
        const slots = generateTimeSlots(start_time, end_time);
        setTimeSlots(slots);
      } else {
        setTimeSlots([]);
      }
    },
    [bookingDetails.booking_day, employeeOfficialHours],
  );

  const getServciceTotalTime = (service) => {
    const serviceTime = Number(service?.service_time || 0);
    const extraTime = Number(service?.extra_time || 0);
    return (serviceTime + extraTime) * 60 * 1000;
  };

  const isTimeSlotBooked = useCallback(
    (timeSlot) => {
      const { booking_day, services, confirmation } = bookingDetails;
      const selectedConfirmationType = confirmation;

      if (!selectedDayBookings?.length || !booking_day) return false;
      const [selectedYear, selectedMonth, selectedDay] = booking_day.split("-").map(Number);
      const [selectedHours, selectedMinutes] = convertTo24Hour(timeSlot).split(":").map(Number);

      const startTime = new Date(
        selectedYear,
        selectedMonth - 1,
        selectedDay,
        selectedHours,
        selectedMinutes,
      );
      const service = services[0];
      const totalDurationInMilliseconds = getServciceTotalTime(service);
      const endTime = new Date(startTime.getTime() + totalDurationInMilliseconds);

      const isBooked = selectedDayBookings.some((booking) => {
        const isActive = booking.is_active === "1";
        const isConfirmed = booking.confirmation === "confirmed";
        const canBeBookdOver = selectedConfirmationType === "confirmed" && isConfirmed === false;

        const notActiveAndCanBeBookedOver = isActive && canBeBookdOver;
        if (notActiveAndCanBeBookedOver) return false;

        const isCancelled = booking.status === "canceled";
        if (isCancelled) return false;

        try {
          const [bookingHours, bookingMinutes] = booking.booking_time.split(":").map(Number);
          const bookingStartTime = new Date(
            selectedYear,
            selectedMonth - 1,
            selectedDay,
            bookingHours,
            bookingMinutes,
          );

          const bookingService = booking.booking_service?.[0]?.service;
          if (!bookingService) {
            console.warn("No booking service found for booking:", booking.id);
            return false;
          }

          const bookingDuration =
            (Number(bookingService.service_time || 0) + Number(bookingService.extra_time || 0)) *
            60 *
            1000;
          const bookingEndTime = new Date(bookingStartTime.getTime() + bookingDuration);

          // Check if the new booking would overlap with existing booking
          const hasOverlap =
            (startTime < bookingEndTime && startTime >= bookingStartTime) || // New booking starts during existing booking
            (endTime > bookingStartTime && endTime <= bookingEndTime) || // New booking ends during existing booking
            (startTime <= bookingStartTime && endTime >= bookingEndTime); // New booking completely encompasses existing booking

          return hasOverlap;
        } catch (error) {
          console.error("Error checking booking:", booking.id, error);
          return false;
        }
      });
      return isBooked;
    },
    [
      selectedDayBookings,
      bookingDetails.booking_day,
      bookingDetails.services,
      generateTimeSlots,
      bookingDetails.confirmation,
    ],
  );

  const handleTimeSlotSelect = (buttonId, time) => {
    if (isTimeSlotBooked(time)) return;
    const convertedTime = convertTo24Hour(time);
    setActiveButton(buttonId);
    setBookingDetails((prev) => ({
      ...prev,
      booking_time: convertedTime,
    }));
    dispatch(setCartBookingTime(convertedTime));
  };

  return (
    <div className="step-three-content">
      <div className="row g-3">
        <div className="col-12 col-lg-8">
          <div className="right-info-content-step">
            <div className="header-one-step">
              <h2 className="title size-text-color-dark">اختر التاريخ والوقت</h2>
            </div>

            <DaySelection
              selectedDay={selectedDay}
              employeeDays={employeeDays}
              setSelectedDay={handleDaySelect}
            />

            <div className="right-main-info pt-4">
              <div className="button-check-pay pt-3 border-bottom pb-3">
                {isLoading ? (
                  <Loader className="size-text-color-dark" />
                ) : (
                  <>
                    <div className="time-slots-grid">
                      {timeSlots.map((timeSlot, index) => {
                        const isBooked = isTimeSlotBooked(timeSlot);
                        return (
                          <div
                            key={index}
                            className={`time-slot-button ${
                              activeButton === index ? "active" : ""
                            } ${isBooked ? "disabled" : ""}`}
                            onClick={() => handleTimeSlotSelect(index, timeSlot)}
                            style={{
                              cursor: isBooked ? "not-allowed" : "pointer",
                              opacity: isBooked ? 0.5 : 1,
                            }}>
                            <div className="time-text">{timeSlot}</div>
                            {activeButton === index && !isBooked && (
                              <div className="icon-check">
                                <FontAwesomeIcon icon={faCheck} />
                              </div>
                            )}
                            {isBooked && <span className="text-danger">محجوز</span>}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const convertTo24Hour = (time) => {
  const [timePart, modifier] = time.split(" ");
  let [hours, minutes] = timePart.split(":").map(Number);
  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }
  const formatted = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  return formatted;
};

export default StepThree_3_Services;

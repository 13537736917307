import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaymentModal from "./PaymentModal";

import { unwrapResult } from "@reduxjs/toolkit";
import { createBooking, payForBooking } from "store/customer/customerActions";
import StepFourServices from "./StepFour_4_Services";
import StepOneServices from "./StepOne_1_Services";
import StepThreeServices from "./StepThree_3_Services";
import StepTwoServices from "./StepTwo_2_Services";

const StepsServicesContent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { bookedServices, currentBranch, vendorSettings } = location.state || {};

  const [currentVendorSettings] = useState(vendorSettings);

  const [paymentMethod, setPaymentMethod] = useState(currentVendorSettings.accept_payment); // vendor accepted types [online, cash, both]
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);

  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const activeEmployees = useSelector((state) => state.customer.employees);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const allCartSvsEmployeesIds = cartDetails.services.flatMap((service) =>
      service.employees.map((employee) => employee.id),
    );

    const filteredEmployees = Object.values(activeEmployees).filter((employee) => {
      return (
        employee.vendor_id === currentBranch.vendor_id &&
        allCartSvsEmployeesIds.includes(parseInt(employee.id))
      );
    });

    const emps = filteredEmployees.reduce((acc, employee) => {
      acc[employee.id] = employee;
      return acc;
    }, {});
    setEmployees(emps);
  }, [activeEmployees, currentBranch, cartDetails]);

  const [bookingDetails, setBookingDetails] = useState({
    address: "",
    employee_info: {},
    confirmation: "",
  });

  const isStepValid = (step) => {
    switch (step) {
      case 1: {
        const stepOneValid =
          bookingDetails.employee_info && Object.keys(bookingDetails.employee_info).length > 0;
        return stepOneValid;
      }
      case 2: {
        const stepTwoValid =
          bookingDetails.confirmation &&
          ["confirmed", "not_confirmed"].includes(bookingDetails.confirmation);
        return stepTwoValid;
      }
      case 3: {
        const stepThreeValid =
          bookingDetails.booking_day !== "" && bookingDetails.booking_time !== "";
        return stepThreeValid;
      }
      case 4: {
        const stepFourValid =
          cartDetails &&
          cartDetails.payment_way &&
          ["online", "cash"].includes(cartDetails.payment_way);
        return stepFourValid;
      }
      default:
        return true;
    }
  };

  const [bookingId, setBookingId] = useState(null);
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    setBookingDetails((prev) => {
      return {
        ...prev,
        ...cartDetails,
        address: currentBranch.address,
        branchDetails: currentBranch,
      };
    });
    setPaymentMethod(vendorSettings.accept_payment);
  }, [location, cartDetails, currentBranch, vendorSettings]);

  const [currentStep, setCurrentStep] = useState(1);
  const [activeSteps, setActiveSteps] = useState([]);

  const handleNext = () => {
    if (currentStep < 4) {
      const nextStep = currentStep === 1 && paymentMethod !== "both" ? 3 : currentStep + 1;
      setCurrentStep(nextStep);
      setActiveSteps([...activeSteps, currentStep]);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      const prevStep = currentStep === 3 && paymentMethod !== "both" ? 1 : currentStep - 1;
      setCurrentStep(prevStep);
      setActiveSteps(activeSteps.filter((step) => step !== prevStep));
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const formData = createBookingRequestFrom(cartDetails, paymentMethod);
      const bookingResult = await dispatch(createBooking(formData));
      const booking = unwrapResult(bookingResult);

      if (cartDetails.payment_way === "online") {
        const paymentResult = await dispatch(payForBooking({ booking_id: booking.data.id }));
        const paymentData = unwrapResult(paymentResult);
        const url = paymentData.data;

        if (url) {
          setIsModalOpen(true);
          setPaymentUrl(url);
          setBookingId(booking.data.id);
        }
      } else if (cartDetails.payment_way === "cash") {
        setTimeout(() => {
          navigate("/successpay");
        }, 1000);
      }
    } catch (e) {
      const { errors } = e;
      const errorFields = Object.keys(errors);
      toast.error(errorFields.map((field) => errors[field].join(", ")).join("\n"), {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPaymentUrl(null);
  };

  if (!bookedServices || !currentBranch || !employees) return <></>;

  return (
    <>
      <HelmetInfo titlePage={"خطوات الحجز والدفع"} />
      <PaymentModal
        show={isModalOpen}
        closeModal={closeModal}
        paymentUrl={paymentUrl}
        bookingId={bookingId}
        setIsPaid={setIsPaid}
      />

      <div className="container">
        <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
          <HeaderPageTitle
            isActiveLinkTwo={true}
            routePage={`/servicesproviders`}
            titlePage={"الخدمات"}
            routeHomePage={"/"}
            titleInfoPage={"خطوات الحجز والدفع"}
          />

          <div className="link-back">
            <Link
              className="size-text-color-dark main-text-color fs-5"
              to={`/servicesproviders/${currentBranch.id}`}>
              عودة
            </Link>
          </div>
        </div>
      </div>

      <div className="all-steps-info padding-bottom-70">
        <div className="container">
          <div
            className={`step-${currentStep}`}
            id="checkout-progress"
            data-current-step={currentStep}>
            <div className="progress-bar">
              {[1, 2, 3, 4].map((stepNum) => (
                <div
                  key={stepNum}
                  className={`step step-${stepNum}  ${activeSteps.includes(stepNum) ? "active1" : ""}  ${
                    currentStep === stepNum ? "active" : ""
                  } `}>
                  <div className="num-step position-relative ">
                    <span>{stepNum}</span>
                  </div>
                  <div className="opaque">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="main-info-steps-content">
            {[1, 2, 3, 4].map((stepNum) => (
              <section
                key={stepNum}
                id={`section${stepNum}`}
                className={`section${stepNum}`}
                style={{ display: currentStep === stepNum ? "block" : "none" }}>
                {stepNum === 1 && (
                  <StepOneServices employees={employees} setBookingDetails={setBookingDetails} />
                )}

                {stepNum === 2 && paymentMethod === "both" && (
                  <StepTwoServices
                    setBookingDetails={setBookingDetails}
                    bookingDetails={bookingDetails}
                  />
                )}

                {stepNum === 3 && (
                  <StepThreeServices
                    bookingDetails={bookingDetails}
                    setBookingDetails={setBookingDetails}
                    employeeInfo={bookingDetails.employee_info}
                    currentVendorSettings={currentVendorSettings}
                  />
                )}

                {stepNum === 4 && (
                  <StepFourServices
                    bookingDetails={bookingDetails}
                    setBookingDetails={setBookingDetails}
                    paymentMethod={paymentMethod}
                  />
                )}
              </section>
            ))}
          </div>
          <div className="button-container">
            <div
              className={`btn main-btn btn-prev ${currentStep === 1 ? "disabled" : ""}`}
              style={{ display: currentStep !== 1 ? "inline-block" : "none" }}
              onClick={handlePrev}>
              السابق
            </div>
            {currentStep !== 4 && (
              <div
                className={`btn main-btn btn-next ${!isStepValid(currentStep) ? "disabled" : ""}`}
                onClick={isStepValid(currentStep) ? handleNext : undefined}
                style={{ cursor: !isStepValid(currentStep) ? "not-allowed" : "pointer" }}>
                التالى
              </div>
            )}
            <button
              className="btn main-btn btn-submit"
              style={{ display: currentStep === 4 ? "inline-block" : "none" }}
              onClick={handleSubmit}>
              الدفع والحجز
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const createBookingRequestFrom = (cartDetails, paymentMethod) => {
  const formData = new FormData();
  const appendServicesToFormData = (formData, services) => {
    services.forEach((service, index) => {
      formData.append(`services[${index}]`, service.id);
    });
  };

  const appendOtherDetailsToFormData = (formData, cartDetails) => {
    Object.keys(cartDetails).forEach((key) => {
      if (key !== "services") {
        formData.append(key, cartDetails[key]);
      }
    });
  };

  const handlePricing = (formData) => {
    const total = formData.get("totalPrice");
    const sub_total = formData.get("subTotalPrice");

    formData.delete("totalPrice");
    formData.delete("subTotalPrice");
    formData.set("total", total);
    formData.set("sub_total", sub_total);
  };

  const handleDiscount = (formData) => {
    const discount = formData.get("discount");
    const promo_code = formData.get("promocode_id");
    formData.delete("discount");
    formData.delete("promocode_id");

    if (discount !== "" && discount !== null) {
      formData.set("discount", discount);
    }
    if (promo_code !== "" && promo_code !== null) {
      formData.set("promocode_id", promo_code);
    }
  };

  const handleConfirmation = (formData) => {
    const confirmation = formData.get("confirmation");
    if (!confirmation && paymentMethod != "both") {
      formData.set("confirmation", "confirmed"); // if payment method is not both, then confirmation is confirmed
    }
  };

  const setAdditionalDetails = (formData) => {
    if (!formData.get("notes")) {
      formData.set("notes", "no_notes");
    }

    formData.set("attendance", 1);
    if (
      formData.get("offer_id") === "" ||
      formData.get("offer_id") === null ||
      formData.get("offer_id") === "null" ||
      formData.get("offer_id") === undefined
    ) {
      formData.delete("offer_id");
    }
  };

  appendServicesToFormData(formData, cartDetails.services);
  appendOtherDetailsToFormData(formData, cartDetails);
  handlePricing(formData);
  handleDiscount(formData);

  setAdditionalDetails(formData);
  handleConfirmation(formData);

  return formData;
};

export default StepsServicesContent;

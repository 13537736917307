export const PaymentDetails = ({ detailsPay }) => {
  if (!detailsPay || detailsPay.length === 0) return null;

  return (
    <div className="details-pay">
      {detailsPay.map((item, index) => (
        <div
          key={index}
          className={`detalis-pay-one d-flex align-items-center justify-content-between gap-3 pb-3 pt-2 ${
            item.newClass || ""
          }`}>
          <h2 className={`title ${item.newClassText || ""}`}>{item.title}</h2>
          <p className="num-info">{`${item.price}ر.س`}</p>
        </div>
      ))}
    </div>
  );
};

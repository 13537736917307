import { faEye } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSubscriptions } from "store/admin/adminActions";
import TableDataInfo from "../../Components/UiDashboard/TablesData/TableDataInfo";
import "./ServicesDetails.css";

const ServiceProviderSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const fetchSubscriptions = (params) => {
    setIsLoading(true);
    dispatch(getSubscriptions(params))
      .then(unwrapResult)
      .then((res) => {
        const allSubscriptions = res.data.data;
        setSubscriptions(allSubscriptions);
        setCurrentPageItemCounts(allSubscriptions?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.total || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    const subsParams = {
      per_page: limit,
      page: currentPage,
      search,
      field: ["id", "name_ar"],
      paginate: "true",
      sorting: "DESC",
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["created_at"],
      column: ["name_ar", "is_active"],
      operand: ["like", "="],
      column_values: [search, "1"],
    };
    fetchSubscriptions(subsParams);
  }, [limit, currentPage, search]);

  return (
    <>
      <HelmetInfo titlePage={"اشتراكات مزودي الخدمة"} />
      <div data-aos="fade-left">
        <HeaderPageInfo
          title={"اشتراكات مزودي الخدمة"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              typeIcon={false}
              textButton={false}
              functionButton={false}
              isButtonActive={false}
              onSearchChange={setSearch}
            />
          }
        />
      </div>
      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">اسم المزود</th>
                <th scope="col">اسم الباقه </th>
                <th scope="col">نوع الاشتراك</th>
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {subscriptions?.map((item) => {
                  console.log(item, "item");
                  return (
                    <tr key={item.id}>
                      <td>{item?.vendor?.name}</td>
                      <td>{item?.name_ar}</td>
                      <td>{item?.subscription_type}</td>
                      <td>
                        <IconsTable
                          typeIcon1={faEye}
                          functionEye={() => {
                            navigate(`/dashboard/admin/serviceprovidersubscriptions/${item.id}`);
                          }}
                          isDeleteIcon={false}
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default ServiceProviderSubscriptions;

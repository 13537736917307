import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { generatDataForVAT } from "utlis";
import amiriRegular from "./Amiri-Regular.ttf";

// Register the custom font
Font.register({
  family: "Amiri",
  src: amiriRegular,
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Amiri",
    fontSize: 12,
    direction: "rtl",
    color: "#333",
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 12,
    color: "#333",
  },
  box: {
    border: "1px solid #ddd",
    borderRadius: 6,
    padding: 8,
    textAlign: "center",
    marginBottom: 8,
    backgroundColor: "#f9f9f9",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 6,
  },
  infoBox: {
    border: "1px solid #ddd",
    borderRadius: 6,
    padding: 6,
    width: "48%",
    textAlign: "center",
    backgroundColor: "#f0f0f0",
  },
  infoBox2: {
    border: "1px solid #ddd",
    borderRadius: 6,
    padding: 6,
    width: "48%",
    textAlign: "right",
    backgroundColor: "#f0f0f0",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 11,
    color: "#555",
    direction: "rtl",
  },
  table: {
    width: "100%",
    marginTop: 12,
    border: "1px solid #ccc",
    borderRadius: 6,
    direction: "rtl",
  },
  tableHeader: {
    flexDirection: "row-reverse",
    backgroundColor: "#f8f8f8",
    borderBottom: "1px solid #ddd",
    fontWeight: "bold",
    fontSize: 11,
    color: "#555",
    direction: "rtl",
  },
  tableRow: {
    flexDirection: "row-reverse",
    borderBottom: "1px solid #ddd",
    direction: "rtl",
    fontSize: 11,
    color: "#555",
  },
  tableCell: {
    padding: 6,
    fontSize: 11,
    textAlign: "right",
    borderLeft: "1px solid #ddd",
    width: "33.33%",
  },
  tableCellCenter: {
    padding: 6,
    fontSize: 11,
    textAlign: "center",
    borderLeft: "1px solid #ddd",
    width: "33.33%",
  },
  totalsBox: {
    marginTop: 10,
    borderRadius: 6,
    border: "1px solid #ddd",
    backgroundColor: "#f9f9f9",
    padding: 8,
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 2,
  },
  footerContainer: {
    marginTop: 12,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px dashed #ddd",
    paddingTop: 6,
  },
  footerText: {
    fontSize: 10,
    textAlign: "right",
    flex: 1,
  },
  qrCodeContainer: {
    width: 100,
    height: 100,
    marginTop: 30,
  },

  qrCode: {
    width: "100%",
    height: "100%",
  },
});

const QRCodeComponent = (qrCodeDataUrl) => (
  <View style={styles.qrCodeContainer}>
    <Image style={styles.qrCode} src={qrCodeDataUrl} />
  </View>
);

export const useQRCode = (value) => {
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");

  useEffect(() => {
    const generateQRCode = async () => {
      const qrCodeDataUrl = await QRCode.toDataURL(value, {
        width: 100,
        height: 100,
        margin: 0,
        color: {
          dark: "#000",
          light: "#FFF",
        },
      });
      setQrCodeDataUrl(qrCodeDataUrl);
    };
    generateQRCode();
  }, [value]);

  if (!qrCodeDataUrl) return null;

  return qrCodeDataUrl;
};

const InvoicePDF = ({ booking }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };
  const calculateTax = (amount) => {
    const taxRate = booking.tax || 15;
    return (parseFloat(amount) * (taxRate / 100)).toFixed(2);
  };
  const calculateTotal = (subTotal) => {
    const tax = calculateTax(subTotal);
    return (parseFloat(subTotal) + parseFloat(tax)).toFixed(2);
  };

  const qrFields = {
    sellerName: booking.vendor.name,
    vatNumber: booking.vendor.tax_number.toString(),
    timestamp: new Date().toISOString(),
    total: booking.sub_total.toString(),
    vatTotal: calculateTax(booking.sub_total).toString(),
  };

  const qrData = generatDataForVAT(qrFields);
  const QrCode = useQRCode(qrData);
  const QrCodeComponent = QRCodeComponent(QrCode);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Title */}
        <Text style={styles.title}>فاتورة ضريبية مبسطة</Text>

        {/* Invoice Number */}
        <View style={styles.box}>
          <Text>
            <Text style={styles.boldText}>رقم الفاتورة: </Text>
            {`INV${booking.id}`}
          </Text>
        </View>

        <View style={styles.box}>
          <Text style={styles.boldText}>اسم المتجر: </Text>
          <Text>{`${booking.vendor.name}`}</Text>
        </View>

        <View style={styles.row}>
          <View style={styles.infoBox2}>
            <Text style={styles.boldText}>الرقم الضريبي: </Text>
            <Text>{`${booking.vendor.tax_number}`}</Text>
          </View>
          <View style={styles.infoBox2}>
            <Text style={styles.boldText}>الرقم التجاري: </Text>
            <Text>{`${booking.vendor.commercial_no}`}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.infoBox2}>
            <Text>
              <Text style={styles.boldText}>يوم الحجز: </Text>
              {formatDate(booking.booking_day)}
            </Text>
          </View>
          <View style={styles.infoBox2}>
            <Text>
              <Text style={styles.boldText}>وقت الحجز: </Text>
              {booking.booking_time}
            </Text>
          </View>
        </View>

        {/* Table */}
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCell, styles.boldText]}>الخدمة</Text>
            <Text style={[styles.tableCellCenter, styles.boldText]}>الوقت</Text>
            <Text style={[styles.tableCellCenter, styles.boldText]}>السعر</Text>
          </View>

          {booking.booking_service.map((service) => (
            <View style={styles.tableRow} key={service.id}>
              <Text style={styles.tableCell}>{service.service.name_ar}</Text>
              <Text style={styles.tableCellCenter}>{service.service.service_time}</Text>
              <Text style={styles.tableCellCenter}>{service.price}</Text>
            </View>
          ))}
        </View>

        {/* Totals Section */}
        <View style={styles.totalsBox}>
          <View style={styles.totalRow}>
            <Text>{booking.sub_total} ر.س</Text>
            <Text>المبلغ الإجمالي</Text>
          </View>

          <View style={styles.totalRow}>
            <Text>{calculateTax(booking.sub_total)} ر.س</Text>
            <Text>قيمة الضريبة ({booking.tax || 15}%)</Text>
          </View>

          <View style={styles.totalRow}>
            <Text>{calculateTotal(booking.sub_total)} ر.س</Text>
            <Text>المجموع النهائي</Text>
          </View>
        </View>

        <View style={styles.footerContainer}>
          <View>
            <Text style={styles.footerText}>تاريخ الإنشاء: {formatDate(booking.created_at)}</Text>
          </View>

          <View style={styles.qrCodeContainer}>{QrCodeComponent}</View>
        </View>
      </Page>
    </Document>
  );
};

export const VendorInvoicePDF = ({ booking, vendorCommision }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };

  const calculateTax = (amount) => {
    const taxRate = booking.tax || 15;
    return (parseFloat(amount) * (taxRate / 100)).toFixed(2);
  };

  const qrFields = {
    sellerName: booking.vendor.name,
    vatNumber: booking.vendor.tax_number.toString(),
    timestamp: new Date().toISOString(),
    total: booking.sub_total.toString(),
    vatTotal: calculateTax(booking.sub_total).toString(),
  };

  const qrData = generatDataForVAT(qrFields);
  const QrCode = useQRCode(qrData);
  const QrCodeComponent = QRCodeComponent(QrCode);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>فاتورة مزود الخدمة</Text>

        <View style={styles.box}>
          <Text>
            <Text style={styles.boldText}>رقم الفاتورة: </Text>
            {`INV${booking.id}`}
          </Text>
        </View>

        <View style={styles.box}>
          <Text style={styles.boldText}>اسم المتجر: </Text>
          <Text>{`${booking.vendor.name}`}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCell, styles.boldText]}>الخدمة</Text>
            <Text style={[styles.tableCellCenter, styles.boldText]}>السعر</Text>
            <Text style={[styles.tableCellCenter, styles.boldText]}>العمولة</Text>
          </View>

          {booking.booking_service.map((service) => (
            <View style={styles.tableRow} key={service.id}>
              <Text style={styles.tableCell}>{service.service.name_ar}</Text>
              <Text style={styles.tableCellCenter}>{service.price}</Text>
              <Text style={styles.tableCellCenter}>
                {(service.price * (vendorCommision / 100)).toFixed(2)}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.totalsBox}>
          <View style={styles.totalRow}>
            <Text>{booking.sub_total} ر.س</Text>
            <Text>المبلغ الإجمالي</Text>
          </View>

          <View style={styles.totalRow}>
            <Text>{(booking.sub_total * (vendorCommision / 100)).toFixed(2)} ر.س</Text>
            <Text>قيمة العمولة ({vendorCommision}%)</Text>
          </View>

          <View style={styles.totalRow}>
            <Text>
              {(booking.sub_total - booking.sub_total * (vendorCommision / 100)).toFixed(2)} ر.س
            </Text>
            <Text>المبلغ المستحق</Text>
          </View>
        </View>

        <View style={styles.footerContainer}>
          <View>
            <Text style={styles.footerText}>تاريخ الإنشاء: {formatDate(booking.created_at)}</Text>
          </View>
          <View style={styles.qrCodeContainer}>{QrCodeComponent}</View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;

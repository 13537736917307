import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReportPDF from "../PDF/ReportPDF";
import { default as ClientsReport } from "./ClientsReport";
import { default as EmployeesReport } from "./EmployeesReport";
import { default as FinancialReport } from "./FinancialReport";
import "./ReportDetails.css";
import OffersReport from "./ReportOffers";
import PackagesReport from "./ReportPackages";
import ProductsReport from "./ReportProducts";
import ServicesReport from "./ReportServices";

const reportsTranslation = {
  services: "تقرير الخدمات",
  offers: "تقرير العروض",
  packages: "تقرير الباقات",
  products: "تقرير المنتجات",
  customers: "تقرير العملاء",
  employees: "تقرير الموظفين",
  financial: "تقرير مالي",
  sales: "تقرير المبيعات",
};

const ReportDetails = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();

  // Set default duration to one month
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(today.getDate() - 30);
  const defaultBookingDay = oneMonthAgo.toISOString().split("T")[0];

  const [bookingDay, setBookingDay] = useState(defaultBookingDay);
  const [reportData, setReportData] = useState([]);
  const [reportHeaders, setReportHeaders] = useState([]);

  const generatePDF = useCallback(async () => {
    try {
      const timestamp = Date.now();
      const pdfComp = (
        <ReportPDF
          key={timestamp}
          title={reportsTranslation[reportId]}
          data={reportData}
          headers={reportHeaders}
          timestamp={timestamp} // Pass timestamp as prop
        />
      );

      const instance = pdf(pdfComp);
      const blob = await instance.toBlob();
      FileSaver.saveAs(blob, `${reportId}_report_${timestamp}.pdf`);
      instance.updateContainer(null);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }, [reportData, reportHeaders, reportId]);

  const renderReport = () => {
    switch (reportId) {
      case "services":
        return (
          <ServicesReport
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "offers":
        return (
          <OffersReport
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "packages":
        return (
          <PackagesReport
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "products":
        return (
          <ProductsReport
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "customers":
        return (
          <ClientsReport
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "employees":
        return (
          <EmployeesReport
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "financial":
        return (
          <FinancialReport
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      default:
        return <div className="text-center p-4">التقرير غير موجود</div>;
    }
  };

  return (
    <div className="report-details-container">
      <div className="report-details-content">
        <div className="report-header">
          <h1 className="report-title">{reportsTranslation[reportId]}</h1>
          <div className="d-flex gap-2 flex-wrap flex-row align-items-center">
            <div className="select-reg">
              <select
                id="bookingDuration"
                accessKey="bookingDuration"
                className="form-select"
                defaultValue="30"
                onChange={(e) => {
                  const today = new Date();
                  const bookingDay = new Date();
                  bookingDay.setDate(today.getDate() - e.target.value);
                  const day = bookingDay.toISOString().split("T")[0];
                  setBookingDay(day);
                }}>
                <option value="">اختر الفترة</option>
                <option value="7">أسبوع</option>
                <option value="30">شهر</option>
                <option value="90">3 أشهر</option>
                <option value="180">6 أشهر</option>
                <option value="365">سنة</option>
              </select>
            </div>
            <button
              className="export-btn"
              style={{ marginLeft: "20px", borderRadius: "10px" }}
              onClick={() => generatePDF()}>
              تصدير PDF
            </button>
            <button className="back-button" onClick={() => navigate(-1)}>
              <span>عودة</span>
            </button>
          </div>
        </div>
        <div className="report-body">{renderReport()}</div>
      </div>
    </div>
  );
};

export default ReportDetails;

import { faBox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPackages } from "../../../../store/admin/adminActions";

const AdminReportPackages = ({ bookingDay, setReportData, setReportHeaders }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [totalPackagesCount, setTotalPackagesCount] = useState(0);

  const fetchPackagesReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: [],
      per_page: 500,
      limit: 500,
      sort: "DESC",
      paginate: "true",
      from_date: bookingDay,
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name_ar"],
      columns: ["created_at"],
      operand: [">="],
      column_values: [bookingDay],
    };
    dispatch(getPackages(params))
      .then(unwrapResult)
      .then((res) => {
        setPackages(res.data.data);
        setTotalPackagesCount(res.data.total);
        console.log(res.data.data);
        // Prepare data for PDF
        const reportData = res.data.data.map((pkg) => ({
          name: pkg.name_ar || "",
          days: pkg.days || "",
          month_price: pkg.month_price || "",
          year_price: pkg.year_price || "",
          branches: pkg.branches || "",
          employees: pkg.employees || "",
          subscriptions_count: pkg.subscriptions_count || "",
          commission: pkg.commission || "",
          status: pkg.is_active === "1" ? "نشط" : "غير نشط",
        }));

        setReportData(reportData);
        setReportHeaders([
          "الباقة",
          "عدد الأيام",
          "سعر الشهر",
          "سعر السنة",
          "عدد الفروع",
          "عدد الموظفين",
          "العمولة",
          "الحالة",
        ]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, bookingDay, setReportData, setReportHeaders]);

  useEffect(() => {
    fetchPackagesReport();
  }, [fetchPackagesReport]);

  if (isLoading) return <Loader />;
  if (!packages.length) return null;

  const sortedPackages = [...new Map(packages.map((p) => [p.id, p])).values()].sort(
    (a, b) => b.bookings_count - a.bookings_count,
  );
  const newPackages = sortedPackages.filter(
    (pkg) => new Date(pkg.created_at) >= new Date(bookingDay),
  );

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{newPackages.length}</h3>
            <p>عدد الباقات</p>
          </div>
          <FontAwesomeIcon icon={faBox} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">الباقات</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الباقة</th>
              <th>عدد الأيام</th>
              <th>سعر الشهر</th>
              <th>سعر السنة</th>
              <th>عدد الفروع</th>
              <th>عدد الموظفين</th>
              <th>العمولة</th>
              <th>الحالة</th>
            </tr>
          </thead>
          <tbody>
            {newPackages.map((pkg, index) => (
              <tr key={pkg.id}>
                <td>{index + 1}</td>
                <td>{pkg.name_ar}</td>
                <td>{pkg.days}</td>
                <td>{pkg.month_price}</td>
                <td>{pkg.year_price}</td>
                <td>{pkg.branches}</td>
                <td>{pkg.employees}</td>
                <td>{pkg.commission}</td>
                <td>{pkg.is_active === "1" ? "نشط" : "غير نشط"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportPackages;

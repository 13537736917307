import "./PrivacyContent.css";
const PrivacyContent = ({ titleContent, textContent }) => {
  return (
    <main>
      <div className="bottom-text">
        <div className="all-privacy" data-aos="fade-up">
          <h2 className="title">{titleContent}</h2>
          <div className="info-content-privacy text">{textContent}</div>
        </div>
      </div>
    </main>
  );
};

export default PrivacyContent;

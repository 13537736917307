import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getClients } from "../../../../store/admin/adminActions";

const AdminReportClients = ({ bookingDay, setReportData, setReportHeaders }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [totalClientsCount, setTotalClientsCount] = useState(0);

  const fetchClientsReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["booking"],
      per_page: 500,
      limit: 500,
      sort: "DESC",
      paginate: "true",
      from_date: bookingDay,
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name"],
      columns: ["created_at"],
      operand: [">="],
      column_values: [bookingDay],
    };
    dispatch(getClients(params))
      .then(unwrapResult)
      .then((res) => {
        setClients(res.data.data);
        setTotalClientsCount(res.data.total);

        // Prepare data for PDF
        const reportData = res.data.data.map((client) => ({
          name: client.name || "",
          email: client.email || "",
          phone: client.phone || "",
          bookings_count: client.bookings_count || 0,
        }));

        setReportData(reportData);
        setReportHeaders(["العميل", "البريد الإلكتروني", "رقم الهاتف", "عدد الحجوزات"]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, bookingDay, setReportData, setReportHeaders]);

  useEffect(() => {
    fetchClientsReport();
  }, [fetchClientsReport]);

  if (isLoading) return <Loader />;
  if (!clients.length) return null;

  const sortedClients = [...new Map(clients.map((c) => [c.id, c])).values()].sort(
    (a, b) => b.bookings_count - a.bookings_count,
  );
  const newClients = sortedClients.filter(
    (client) => new Date(client.created_at) >= new Date(bookingDay),
  );

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{newClients.length}</h3>
            <p>عدد العملاء</p>
          </div>
          <FontAwesomeIcon icon={faUsers} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">العملاء</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>العميل</th>
              <th>البريد الإلكتروني</th>
              <th>رقم الهاتف</th>
              <th>عدد الحجوزات</th>
            </tr>
          </thead>
          <tbody>
            {newClients.map((client, index) => (
              <tr key={client.id}>
                <td>{index + 1}</td>
                <td>{client.name}</td>
                <td>{client.email}</td>
                <td>{client.phone}</td>
                <td>{client.bookings_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportClients;

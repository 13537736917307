import Loader from "Components/Loader/Loader";
import CardProduct from "Components/Ui/CardProduct/ProviderCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { extractBranchesFromVendors } from "utlis";
import locationIcon from "../../../assets/images/icons/location.svg";
import starIcon from "../../../assets/images/icons/star.svg";
import imgCard from "../../../assets/images/products/01.png";
import "./CardServices.css";

const ServiceProvidersCards = ({ searchProviders, vendors, isLoading }) => {
  const [branches, setBranches] = useState([]);
  const isLoggedIn = useSelector((state) => state.auth.isLogedIn);
  useEffect(() => {
    const branchesFromVendors = extractBranchesFromVendors(vendors ?? []);
    setBranches(branchesFromVendors);
  }, [vendors]);

  const navigate = useNavigate();

  const handleClickCard = (id) => {
    if (isLoggedIn) {
      navigate(`/servicesproviders/${id}`);
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="all-cards-services" data-aos="fade-up" style={{ minHeight: "60vh" }}>
      <div className="container">
        <div className="row g-3">
          {isLoading ? (
            <Loader />
          ) : (
            branches.length > 0 &&
            branches.map((branch, index) => {
              return (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
                  <div
                    onClick={() => {
                      handleClickCard(branch.id);
                    }}>
                    <CardProduct
                      bgColorBadge={"red-badge"}
                      textBadge={"50%"}
                      newClassBadge={"badge-product-services"}
                      isIconFavorite={false}
                      imgCard={branch.photo ?? imgCard}
                      cardTitle={branch.vendor.name + " - " + branch.name}
                      locationIcon={locationIcon}
                      textLocation={branch.address}
                      starIcon={starIcon}
                      numRate={"4.5"}
                      totalRate={"(120 تقيم)"}
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>

        {/* <div className="button-more mt-5 d-flex justify-content-center align-items-center">
          <button
            className="main-btn main-outline-btn"
            onClick={(e) => {
              console.log("show more services");
            }}
          >
            عرض المزيد
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ServiceProvidersCards;

export const JeddahCoordinates = {
  lat: "21.4225",
  lng: "39.8262",
};

export const RiyadhCoordinates = {
  lat: "24.74745518966098",
  lng: "46.62732484514198",
};

export const defaultImage = "https://dummyimage.com/600x400/000/fff&text=default";

import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import Loader from "Components/Loader/Loader";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword, logoutAction } from "store/auth/authActions";
import * as Yup from "yup";
const ChangePassword = () => {
  const navigate = useNavigate();
  const initialValues = {
    current_password: "",
    password: "",
    password_confirmation: "",
  };

  const dispatch = useDispatch();

  const handleResetForm = () => {
    setPasswordLengthValid(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    dispatch(changePassword(values))
      .then(unwrapResult)
      .then((res) => {
        handleResetForm();
        resetForm();
        toast.success(res.message);
        dispatch(logoutAction());
        navigate("/loginDashboard");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  // SHOW AND HIDE PASSWORD
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required("كلمة المرور الحالية مطلوبة"),
    password: Yup.string()
      .required("كلمة المرور الجديدة مطلوبة")
      .min(8, "كلمة المرور يجب أن تكون على الأقل 8 أحرف")
      .test("password-length", "كلمة المرور يجب أن تكون على الأقل 8 أحرف", (val) => {
        setPasswordLengthValid(val.length >= 8);
        return true;
      }),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "يجب أن تتطابق كلمة المرور الجديدة مع تأكيد كلمة المرور")
      .required("تأكيد كلمة المرور مطلوب"),
  });
  return (
    <>
      {/* ========== START FORM FIELD ========= */}
      {isLoading ? (
        <Loader />
      ) : (
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          <div className="row g-1 g-sm-3">
            <div className="col-12 col-md-12 mb-3">
              <div className="input-pass position-relative">
                <InputFiled
                  name="current_password"
                  placeholder="كلمة المرور الحالية"
                  success
                  type={showPassword ? "text" : "password"}
                  handleOnChange={(v) => {
                    console.log(v, "current_password");
                  }}
                />

                <button
                  type="button"
                  className="icon-eye-button"
                  onClick={() => togglePasswordVisibility("password")}>
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </button>
              </div>
            </div>
            <div className="col-12 col-md-12 mb-3">
              <div className="input-pass position-relative">
                <InputFiled
                  name="password"
                  placeholder="كلمة المرور الجديدة"
                  success={passwordLengthValid}
                  type={showNewPassword ? "text" : "password"}
                  handleOnChange={(v) => {
                    console.log(v, "new");
                  }}
                />

                <button
                  type="button"
                  className="icon-eye-button"
                  onClick={() => togglePasswordVisibility("newPassword")}>
                  {showNewPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </button>
              </div>
            </div>
            <div className="col-12 col-md-12 mb-3">
              <div className="input-pass position-relative">
                <InputFiled
                  name="password_confirmation"
                  placeholder="تأكيد كلمة المرور"
                  success={passwordLengthValid}
                  type={showConfirmPassword ? "text" : "password"}
                  handleOnChange={(v) => {
                    console.log(v, "confirm new");
                  }}
                />

                <button
                  type="button"
                  className="icon-eye-button"
                  onClick={() => togglePasswordVisibility("confirmPassword")}>
                  {showConfirmPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="all-check mt-5 d-flex flex-column gap-3">
            <div
              style={{ color: passwordLengthValid ? "green" : "#666666" }}
              className="check-input-one d-flex align-items-center gap-3">
              <div
                className="icon-check"
                style={{ color: passwordLengthValid ? "green" : "#666666" }}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
              كلمة المرور لا تقل عن 8 أحرف
            </div>
            {/* <div
              style={{ color: passwordCaseValid ? "green" : "#666666" }}
              className="check-input-one d-flex align-items-center gap-3"
            >
              <div className="icon-check" style={{ color: passwordCaseValid ? "green" : "#666666" }}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
              تحتوى على أحرف كبيرة وأحرف صغيرة
            </div>
            <div
              style={{ color: passwordSpecialCharsValid ? "green" : "#666666" }}
              className="check-input-one d-flex align-items-center gap-3"
            >
              <div
                className="icon-check"
                style={{
                  color: passwordSpecialCharsValid ? "green" : "#666666",
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </div>
              تحتوي على رموز خاصة ` ! @ # $ % & ^
            </div> */}
          </div>
          <button
            type="submit"
            className="main-btn btn-submit px-5 mt-4"
            style={{
              cursor: passwordLengthValid ? "pointer" : "not-allowed",
            }}>
            حفظ
          </button>
        </FormField>
      )}
      {/* ========== END FORM FIELD ========= */}
    </>
  );
};

export default ChangePassword;

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import imgCardSlider2 from "../../../assets/images/main/05.png";

const ProviderImages = ({ currentBranch }) => {
  return (
    <>
      <div className="slider-card my-3 position-relative">
        <Swiper
          navigation={true}
          spaceBetween={15}
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: true,
          }}
          className="mySwiper">
          <SwiperSlide>
            <div className="img-slider">
              <img
                src={currentBranch.photo ?? imgCardSlider2}
                alt="img card slider"
                className="w-100 object-fit-cover"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default ProviderImages;

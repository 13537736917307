import { createSlice } from "@reduxjs/toolkit";
import {
  addPackage,
  getBooking,
  getBookingCountChart,
  getBookings,
  getBookingStatisticsMonth,
  getBookingTotalChart,
  getCustomerDataThunk,
  getCustomersListThunk,
  getFAQ,
  getFAQs,
  getHelpCenter,
  getHomeData,
  getNotificationSettings,
  getOffer,
  getOffers,
  getPackage,
  getPackages,
  getPaymentSettings,
  getService,
  getServices,
  getSubscription,
  getSubscriptions,
  getVendor,
  getVendors,
  updateFAQ,
  updatePaymentSettings,
} from "./adminActions";

const InitialState = {
  isLoading: false,
  hasError: false,
  errorMessage: {},
  admin_vendors: [],
  admin_branches: [],
  admin_services: [],
  admin_offers: [],
  admin_employees: [],
  admin_customers: [],
  admin_bookings: [],
  admin_sections: [],
  admin_categories: [],
};

const AdminSlice = createSlice({
  name: "admin",
  initialState: InitialState,
  reducers: {
    setAdminVendors: (state, action) => {
      state.admin_vendors = action.payload;
    },
    setAdminBranches: (state, action) => {
      state.branches = action.payload;
    },
    setAdminServices: (state, action) => {
      state.admin_services = action.payload;
    },
    setAdminOffers: (state, action) => {
      state.admin_offers = action.payload;
    },
    setAdminEmployees: (state, action) => {
      state.admin_employees = action.payload;
    },
    setAdminCustomers: (state, action) => {
      state.admin_customers = action.payload;
    },
    setAdminBookings: (state, action) => {
      state.admin_bookings = action.payload;
    },
    setAdminSections: (state, action) => {
      state.admin_sections = action.payload;
    },
    setAdminCategories: (state, action) => {
      state.admin_categories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getVendors.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getVendors.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getVendor.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getVendor.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getVendor.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getPackages.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getPackages.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getPackages.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getPackage.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getPackage.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getPackage.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getServices.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getServices.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getServices.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getService.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getService.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getService.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getSubscriptions.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getSubscriptions.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getSubscriptions.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getSubscription.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getSubscription.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getSubscription.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getCustomersListThunk.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCustomersListThunk.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getCustomersListThunk.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getCustomerDataThunk.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCustomerDataThunk.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getCustomerDataThunk.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBookings.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBookings.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBookings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBooking.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBooking.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBooking.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getHomeData.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getHomeData.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getHomeData.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBookingCountChart.pending, (state) => {
        state.hasError = false;
      })
      .addCase(getBookingCountChart.fulfilled, (state) => {
        state.hasError = false;
      })
      .addCase(getBookingCountChart.rejected, (state) => {
        state.hasError = true;
      })
      .addCase(getBookingTotalChart.pending, (state) => {
        state.hasError = false;
      })
      .addCase(getBookingTotalChart.fulfilled, (state) => {
        state.hasError = false;
      })
      .addCase(getBookingTotalChart.rejected, (state) => {
        state.hasError = true;
      })
      .addCase(addPackage.pending, (state) => {
        state.hasError = false;
      })
      .addCase(addPackage.fulfilled, (state) => {
        state.hasError = false;
      })
      .addCase(addPackage.rejected, (state) => {
        state.hasError = true;
      })
      .addCase(getOffers.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getOffers.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getOffers.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getOffer.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getOffer.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getOffer.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(updatePaymentSettings.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updatePaymentSettings.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updatePaymentSettings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getPaymentSettings.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getPaymentSettings.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getPaymentSettings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getHelpCenter.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getHelpCenter.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getHelpCenter.rejected, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getFAQs.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getFAQs.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getFAQs.rejected, (state) => {
        state.hasError = true;
        // state.isLoading = false;
      })
      .addCase(updateFAQ.pending, (state) => {
        // state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateFAQ.fulfilled, (state) => {
        // state.isLoading = false;
        state.hasError = false;
      })
      .addCase(updateFAQ.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getFAQ.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getFAQ.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getFAQ.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getBookingStatisticsMonth.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getBookingStatisticsMonth.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getBookingStatisticsMonth.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(getNotificationSettings.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getNotificationSettings.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getNotificationSettings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default AdminSlice.reducer;
export const {
  setAdminVendors,
  setAdminBranches,
  setAdminServices,
  setAdminOffers,
  setAdminEmployees,
  setAdminCustomers,
  setAdminBookings,
  setAdminSections,
  setAdminCategories,
} = AdminSlice.actions;

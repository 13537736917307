import DatePickerField from "Components/Forms/DatePickerField";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import MultiSelect from "Components/Forms/MultiSelect";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import ImageUpload from "Dashboard/Components/UiDashboard/ImageUpload/ImageUpload";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";

import {
  addEmployee,
  getBranches,
  getEmployee,
  getServices,
  updateEmployee,
} from "store/vendor/vendorActions";
import { convertTimeToDate, formatDateToTime24 } from "utlis";

import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import AddDateTime from "Dashboard/Components/UiDashboard/AddDateTime/AddDateTime";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("ادخل الأسم الاول"),
  last_name: Yup.string().required("ادخل الأسم الاخير"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "يجب ان يكون ارقام")
    .min(10, "يجب أن لا يقل رقم الهاتف عن 10 ارقام")
    .max(12, "يجب ألا يتجاوز رقم الهاتف 12 رقاما")
    .required("رقم الهاتف مطلوب"),
  email: Yup.string().email("ايميل خاطئ").required("هذا الحقل مطلوب"),
  service_id: Yup.array().min(1).required("ادخل الخدمات"),
  salary: Yup.string().required("ادخل الراتب"),
  branch_id: Yup.array().min(1).required("عنوان الفرع"),
  // password: Yup.string()
  //   .required("كلمة السر مطلوبة")
  //   .min(8, "يجب ان تكون كلمة السر اكثر من 8 احرف وارقام"),
  // password_confirmation: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "كلمة السر غير متطابقة")
  //   .required("تأكيد كلمة السر"),
  officialHours: Yup.array()
    .of(
      Yup.object().shape({
        start_time: Yup.date().required("هذا الحقل مطلوب"),
        end_time: Yup.date().required("هذا الحقل مطلوب"),
        day: Yup.string().required("هذا الحقل مطلوب"),
      }),
    )
    .min(1, "يشترط وجود ساعة رسمية واحدة على الأقل"),
  job_title: Yup.string().required("ادخل الوظيفة"),
});

export const convertToFormData = (data) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (key === "service_id" || key === "branch_id") {
        value.forEach((item) => {
          formData.append(`${key}[]`, item);
        });
      } else {
        value.forEach((item, index) => {
          if (typeof item === "object") {
            Object.entries(item).forEach(([subKey, subValue]) => {
              formData.append(`${key}[${index}][${subKey}]`, subValue);
            });
          } else {
            formData.append(`${key}[${index}]`, item);
          }
        });
      }
    } else if (value instanceof File) {
      formData.append(key, value, value.name);
    } else if (value != null) {
      formData.append(key, value);
    }
  });

  return formData;
};

const EditAddEmployee = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [branches, setBranches] = useState([]);

  const [services, setServices] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const [employeeDate, setEmployeeDate] = useState({
    id: "",
    password: "12345678",
    password_confirmation: "12345678",
    first_name: "",
    last_name: "",
    branch_id: [],
    email: "",
    end_date: "",
    officialHours: [
      {
        day: null,
        end_time: null,
        start_time: null,
      },
    ],
    phone: "",
    salary: "",
    image: "",
    service_id: [],
    start_date: "",
    job_title: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const updatedValues = { ...employeeDate };
    const officialHours = updatedValues.officialHours
      .filter((item) => item && item.day && item.start_time && item.end_time)
      .map((item) => ({
        day: item.day,
        start_time: item.start_time ? formatDateToTime24(item.start_time) : null,
        end_time: item.end_time ? formatDateToTime24(item.end_time) : null,
      }));

    console.log("officialHours", officialHours);
    updatedValues.officialHours = officialHours;

    // Ensure service_id and branch_id are arrays
    updatedValues.service_id = Array.isArray(updatedValues.service_id)
      ? updatedValues.service_id
      : [updatedValues.service_id];
    updatedValues.branch_id = Array.isArray(updatedValues.branch_id)
      ? updatedValues.branch_id
      : [updatedValues.branch_id];

    try {
      const formData = convertToFormData(updatedValues);
      if (id) {
        await dispatch(updateEmployee({ id, formData })).then(unwrapResult);
        toast.success("تم التعديل بنجاح.");
      } else {
        await dispatch(addEmployee(formData)).then(unwrapResult);
        toast.success("تم الاضافه بنجاح.");
      }
      navigation("/dashboard/vendor/employees");
    } catch (error) {
      console.error("Error submitting employee data:", error);
      toast.error("حدث خطأ أثناء حفظ البيانات.");
    } finally {
      setIsSubmitting(false);
    }
  }, [dispatch, id, navigation, employeeDate, isSubmitting]);

  const handleChange = (name, value) => {
    const newValue = (() => {
      if (name === "start_date" || name === "end_date") {
        return new Date(value).toISOString().split("T")[0];
      }
      if (name === "service_id" || name === "branch_id") {
        return Array.isArray(value) ? value : [value]; // Ensure it's always an array
      }
      return value;
    })();

    setEmployeeDate((prev) => ({ ...prev, [name]: newValue }));
  };

  useEffect(() => {
    const fetchBranches = () => {
      dispatch(
        getBranches({
          deleted: "undeleted",
          columns: ["vendor_id", "is_active"],
          operand: ["=", "="],
          column_values: [user?.model_id, "1"],
        }),
      )
        .then(unwrapResult)
        .then((res) => {
          const branches = res.data?.data;
          setBranches(branches);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const fetchServices = (params) => {
      dispatch(getServices(params))
        .then(unwrapResult)
        .then((res) => {
          const currentBranchServices = res.data.data.filter(
            (svc) => svc.vendor_id === user?.model_id,
          );
          if (currentBranchServices.length > 0) {
            setServices(currentBranchServices);
          } else {
            setServices(res.data?.data);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };
    const serviceParams = {
      deleted: "undeleted",
      columns: ["vendor_id", "is_active"],
      operand: ["=", "="],
      column_values: [user?.model_id, "1"],
    };
    fetchServices(serviceParams);
    fetchBranches();
  }, [dispatch]);

  const getEmployeeDetails = () => {
    setIsDataLoading(true);
    dispatch(getEmployee(id))
      .then(unwrapResult)
      .then((res) => {
        if (res.data) {
          const data = { ...res.data };

          const emp = {
            id: data.id,
            first_name: data.user?.first_name,
            last_name: data.user?.last_name,
            branch_id: data.branches.map((item) => item.id),
            email: data.user?.email,
            end_date: data.end_date,
            officialHours: res.data.official_hours.map((hour) => ({
              start_time: convertTimeToDate(hour.start_time),
              end_time: convertTimeToDate(hour.end_time),
              day: hour.day,
            })),
            phone: data.user?.phone,
            // image: data.user.photo,
            salary: data.salary,
            service_id: data.services.map((item) => item.id),
            start_date: data?.start_date,
            job_title: data.job_title ?? "موظف",
          };
          setEmployeeDate(emp);
          setIsDataLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    if (id) getEmployeeDetails();
  }, [id]);

  const handleImageChange = (file) => {
    setEmployeeDate({ ...employeeDate, image: file });
  };

  return (
    <>
      <HelmetInfo titlePage={"اضافة تعديل بيانات الموظف"} />

      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={false}
          titleInfoPage={"اضافة / تعديل بيانات الموظف"}
          routeHomePage={"/dashboard/homedashboard"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/vendor/employees">
            عودة
          </Link>
        </div>
      </div>

      <div className="details-add-edite-PROFILE mt-3" data-aos="fade-up">
        <ImageUpload handleImageChange={handleImageChange} />
        <div className="info-user-edit">
          {isDataLoading ? (
            <Loader />
          ) : (
            <FormField
              initialValues={employeeDate}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              <div className="row g-1 g-sm-3">
                <div className="col-12 col-md-6">
                  <InputFiled
                    label={"الاسم الأول"}
                    name="first_name"
                    type="text"
                    placeholder={"الاسم الأول"}
                    success
                    handleOnChange={(value) => handleChange("first_name", value)}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputFiled
                    label={"الاسم الأخير"}
                    name="last_name"
                    type="text"
                    placeholder={"الاسم الأخير"}
                    success
                    handleOnChange={(value) => handleChange("last_name", value)}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputFiled
                    label={"البريد الألكتروني"}
                    name="email"
                    type="text"
                    placeholder={"example@gmail.com"}
                    success
                    handleOnChange={(value) => handleChange("email", value)}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputFiled
                    label={"رقم الجوال"}
                    name="phone"
                    type="number"
                    placeholder={"رقم الجوال"}
                    success
                    handleOnChange={(value) => handleChange("phone", value)}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <div className="date-birth">
                    <label htmlFor="exampleFormControlInputBirh" className="form-label">
                      تاريخ بداية التوظيف
                    </label>
                    <DatePickerField
                      name="start_date"
                      placeholder={"تاريخ بداية التوظيف"}
                      handleOnChange={(value) => handleChange("start_date", value)}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="date-birth">
                    <label htmlFor="exampleFormControlInputBirh" className="form-label">
                      تاريخ نهاية التوظيف
                    </label>
                    <DatePickerField
                      name="end_date"
                      placeholder={"تاريخ نهاية التوظيف"}
                      handleOnChange={(value) => handleChange("end_date", value)}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="select-reg">
                    <label htmlFor="exampleFormControlInput2" className="form-label">
                      تعين الفرع
                    </label>
                    <MultiSelect
                      name="branch_id"
                      label={false}
                      placeholder={"تعين الفرع"}
                      options={branches.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      value={employeeDate.branch_id}
                      handleOnChange={(values) => handleChange("branch_id", values)}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="select-reg">
                    <label htmlFor="exampleFormControlInput22" className="form-label">
                      الخدمات التى يقدمها الموظف
                    </label>
                    <MultiSelect
                      name="service_id"
                      label={false}
                      placeholder={"الخدمات التى يقدمها الموظف"}
                      options={services.map((service) => ({
                        value: service.id,
                        label: service.name_ar,
                      }))}
                      value={employeeDate.service_id}
                      handleOnChange={(values) => handleChange("service_id", values)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <InputFiled
                    label={"الراتب"}
                    name="salary"
                    type="number"
                    placeholder={"الراتب"}
                    value={employeeDate.salary}
                    handleOnChange={(value) => handleChange("salary", value)}
                    success
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputFiled
                    label={"الوظيفة"}
                    name="job_title"
                    type="text"
                    placeholder={"الوظيفة"}
                    value={employeeDate.job_title}
                    handleOnChange={(value) => handleChange("job_title", value)}
                    success
                  />
                </div>

                {/* <div className="col-12 col-md-6">
                  <div className="input-pass position-relative">
                    <InputFiled
                      label="كلمة المرور"
                      name="password"
                      placeholder="كلمة المرور"
                      success
                      handleOnChange={(value) => handleChange("password", value)}
                      type={showPassword ? "text" : "password"}
                    />

                    <button
                      className="icon-eye-button"
                      type="button"
                      onClick={() => togglePasswordVisibility("password")}>
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="input-pass position-relative">
                    <InputFiled
                      label="تاكيد كلمة المرور "
                      name="password_confirmation"
                      placeholder="تأكيد كلمة المرور"
                      success
                      handleOnChange={(value) => handleChange("password_confirmation", value)}
                      type={showRePassword ? "text" : "password"}
                    />

                    <button
                      className="icon-eye-button"
                      type="button"
                      onClick={() => togglePasswordVisibility("rePassword")}>
                      {showRePassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </button>
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="main-time-work">
                    <label htmlFor="exampleFormControlInputDate_1" className="form-label">
                      ساعات العمل الرسمية
                    </label>
                    <div>
                      <AddDateTime
                        name="officialHours"
                        value={employeeDate.officialHours}
                        handleOnChange={(index, key, value) => {
                          const data = {};
                          if (key === "end_time") {
                            data.end_time = value;
                          } else if (key === "start_time") {
                            data.start_time = value;
                          } else if (key === "delete") {
                            // Handle deletion
                            setEmployeeDate((prevState) => ({
                              ...prevState,
                              officialHours: prevState.officialHours.filter((_, i) => i !== index),
                            }));
                            return;
                          } else {
                            data.day = value;
                          }
                          setEmployeeDate((prevState) => {
                            const updatedOfficialHours = [...prevState.officialHours];
                            if (!updatedOfficialHours[index]) {
                              updatedOfficialHours[index] = {
                                day: null,
                                start_time: null,
                                end_time: null,
                              };
                            }
                            updatedOfficialHours[index] = {
                              ...updatedOfficialHours[index],
                              ...data,
                            };
                            return {
                              ...prevState,
                              officialHours: updatedOfficialHours,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="main-btn btn-submit px-5 mt-4"
                disabled={isSubmitting}>
                {isSubmitting ? "جاري الحفظ..." : "حفظ"}
              </button>
            </FormField>
          )}
        </div>
      </div>
    </>
  );
};

export default EditAddEmployee;

import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getEmployees } from "../../../../store/admin/adminActions";

const AdminReportEmployees = ({ bookingDay, setReportData, setReportHeaders }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [totalEmployeesCount, setTotalEmployeesCount] = useState(0);

  const fetchEmployeesReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["booking"],
      per_page: 500,
      limit: 500,
      sort: "DESC",
      paginate: "true",
      from_date: bookingDay,
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name"],
      columns: ["created_at"],
      operand: [">="],
      column_values: [bookingDay],
    };
    dispatch(getEmployees(params))
      .then(unwrapResult)
      .then((res) => {
        setEmployees(res.data.data);
        setTotalEmployeesCount(res.data.total);

        // Prepare data for PDF
        const reportData = res.data.data.map((employee) => ({
          name: employee.user?.name || "",
          email: employee.user?.email || "",
          phone: employee.user?.phone || "",
          salary: employee.salary || "",
          services_count: employee.services_count || 0,
          status: employee.is_active === "1" ? "نشط" : "غير نشط",
        }));

        setReportData(reportData);
        setReportHeaders([
          "#",
          "اسم الموظف",
          "البريد الإلكتروني",
          "رقم الهاتف",
          "الراتب",
          "عدد الخدمات",
          "الحالة",
        ]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, bookingDay, setReportData, setReportHeaders]);

  useEffect(() => {
    fetchEmployeesReport();
  }, [fetchEmployeesReport]);

  if (isLoading) return <Loader />;
  if (!employees.length) return null;

  const sortedEmployees = [...new Map(employees.map((e) => [e.id, e])).values()].sort(
    (a, b) => b.bookings_count - a.bookings_count,
  );
  const newEmployees = sortedEmployees.filter(
    (employee) => new Date(employee.created_at) >= new Date(bookingDay),
  );

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{newEmployees.length}</h3>
            <p>عدد الموظفين</p>
          </div>
          <FontAwesomeIcon icon={faUsers} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">الموظفين</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>اسم الموظف</th>
              <th>البريد الإلكتروني</th>
              <th>رقم الهاتف</th>
              <th>الراتب</th>
              {/* <th>تاريخ البدء</th>
              <th>تاريخ الانتهاء</th> */}
              <th>عدد الخدمات</th>
              <th>الحالة</th>
            </tr>
          </thead>
          <tbody>
            {newEmployees.map((employee, index) => (
              <tr key={employee.id}>
                <td>{index + 1}</td>
                <td>{employee.user?.name}</td>
                <td>{employee.user?.email}</td>
                <td>{employee.user?.phone}</td>
                <td>{employee.salary}</td>
                {/* <td>{employee.start_date}</td>
                <td>{employee.end_date}</td> */}
                <td>{employee.services_count}</td>
                <td>{employee.is_active === "1" ? "نشط" : "غير نشط"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportEmployees;

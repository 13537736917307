import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReportPDF from "../PDF/ReportPDF";
import AdminReportClients from "./AdminReportClients";
import AdminReportEmployees from "./AdminReportEmployees";
import AdminReportOffers from "./AdminReportOffers";
import AdminReportPackages from "./AdminReportPackages";
import AdminReportServices from "./AdminReportServices";
import AdminReportVendors from "./AdminReportVendors";

const adminReportsTranslation = {
  "services-admin": "تقرير الخدمات",
  "offers-admin": "تقرير العروض",
  "packages-admin": "تقرير الباقات",
  "employees-admin": "تقرير الموظفين",
  "vendors-admin": "تقرير الموردين",
  "clients-admin": "تقرير العملاء",
};

const AdminReportDetails = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();

  // Set default duration to one month
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setDate(today.getDate() - 180);
  const defaultBookingDay = sixMonthsAgo.toISOString().split("T")[0];

  const [bookingDay, setBookingDay] = useState(defaultBookingDay);
  const [reportData, setReportData] = useState([]);
  const [reportHeaders, setReportHeaders] = useState([]);

  const generatePDF = async () => {
    try {
      const pdfComp = (
        <ReportPDF
          key={Date.now()}
          title={adminReportsTranslation[reportId]}
          data={reportData}
          headers={reportHeaders}
        />
      );
      const blob = await pdf(pdfComp).toBlob();
      FileSaver.saveAs(blob, `${reportId}_report.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const renderReport = () => {
    switch (reportId) {
      case "services-admin":
        return (
          <AdminReportServices
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "offers-admin":
        return (
          <AdminReportOffers
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "packages-admin":
        return (
          <AdminReportPackages
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "employees-admin":
        return (
          <AdminReportEmployees
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "vendors-admin":
        return (
          <AdminReportVendors
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      case "clients-admin":
        return (
          <AdminReportClients
            bookingDay={bookingDay}
            setReportData={setReportData}
            setReportHeaders={setReportHeaders}
          />
        );
      default:
        return <div className="text-center p-4">التقرير غير موجود</div>;
    }
  };

  return (
    <div className="report-details p-3">
      <div className="report-header">
        <div className="report-title mb-5">{adminReportsTranslation[reportId]}</div>
        <div className="d-flex gap-2 flex-wrap flex-row align-items-center">
          <div className="select-reg">
            <select
              id="bookingDuration"
              accessKey="bookingDuration"
              className="form-select"
              defaultValue="180"
              onChange={(e) => {
                const today = new Date();
                const bookingDay = new Date();
                bookingDay.setDate(today.getDate() - e.target.value);
                const day = bookingDay.toISOString().split("T")[0];
                setBookingDay(day);
              }}>
              <option value="">اختر الفترة</option>
              <option value="7">أسبوع</option>
              <option value="30">شهر</option>
              <option value="90">3 أشهر</option>
              <option value="180">6 أشهر</option>
              <option value="365">سنة</option>
            </select>
          </div>
          <button
            className="export-btn"
            style={{ marginLeft: "20px", borderRadius: "10px" }}
            onClick={generatePDF}>
            تصدير PDF
          </button>
          <button className="back-button" onClick={() => navigate(-1)}>
            <span>رجوع</span>
          </button>
        </div>
      </div>
      {renderReport()}
    </div>
  );
};

export default AdminReportDetails;

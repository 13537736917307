import { faPercentage, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "Components/Loader/Loader";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getVendorCustomer as getClients } from "store/vendor/vendorActions";
import { formatDateDDMMYYYY } from "utlis";
import "./ReportDetails.css";

const HEADERS = ["العملاء", "عدد الحجوزات", "نسبة العملاء الجدد", "نسبة العملاء النشطين"];
const ONE_MONTH_IN_DAYS = 30;

const ClientsReport = ({ bookingDay, setReportData, setReportHeaders }) => {
  const user = useGetAuthUser();
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const today = new Date().toISOString().split("T")[0];

  const startDate = formatDateDDMMYYYY(new Date(bookingDay));
  const endDate = formatDateDDMMYYYY(new Date(today));

  const fetchClientsData = useCallback(async () => {
    setIsLoading(true);
    try {
      const clientsResponse = await dispatch(
        getClients({
          per_page: 500,
          limit: 500,
          offset: 0,
          date_after: startDate,
          date_before: endDate,
          vendor_id: user.model_id,
        }),
      ).unwrap();
      setClients(clientsResponse.data.data);
      console.log({ clientsResponse });
    } catch (error) {
      console.error("Failed to fetch clients:", error);
    } finally {
      setIsLoading(false);
    }
  }, [user.model_id, dispatch, bookingDay]);

  const calculateStats = useCallback(() => {
    const oneMonthAgo = new Date(new Date().setDate(new Date().getDate() - ONE_MONTH_IN_DAYS));
    const totalClients = clients.length;

    const newClients = clients.filter((client) => new Date(client.created_at) > oneMonthAgo).length;

    const activeClients = clients.filter((client) => {
      const lastBooking = client.bookings[0]; // Assuming bookings are already sorted
      return lastBooking && new Date(lastBooking.created_at) > oneMonthAgo;
    }).length;

    const calculatePercentage = (value) =>
      totalClients ? ((value / totalClients) * 100).toFixed(2) : "0.00";

    return {
      totalClients,
      newClients,
      newClientsPercentage: calculatePercentage(newClients),
      activeClientsPercentage: calculatePercentage(activeClients),
    };
  }, [clients]);

  useEffect(() => {
    fetchClientsData();
  }, [fetchClientsData]);

  useEffect(() => {
    const stats = calculateStats();

    setReportData([
      {
        clients: stats.totalClients,
        bookings: stats.newClients,
        newClientsPercent: stats.newClientsPercentage,
        activeClientsPercent: stats.activeClientsPercentage,
      },
    ]);

    setReportHeaders(HEADERS);
  }, [clients, calculateStats]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="clients-report">
      <div className="report-summary">
        <div className="summary-card">
          <div className="card-content">
            <h3>{calculateStats().totalClients}</h3>
            <p>عدد العملاء الكلي</p>
          </div>
          <FontAwesomeIcon icon={faUsers} className="icon" />
        </div>
        <div className="summary-card">
          <FontAwesomeIcon icon={faPercentage} className="icon" />
          <div className="card-content">
            <h3>{calculateStats().activeClientsPercentage}%</h3>
            <p>نسبة العملاء النشطين</p>
          </div>
        </div>
        <div className="summary-card">
          <FontAwesomeIcon icon={faUserPlus} className="icon" />
          <div className="card-content">
            <h3>{calculateStats().newClients}</h3>
            <p>عدد العملاء الجدد</p>
          </div>
        </div>
        <div className="summary-card">
          <FontAwesomeIcon icon={faPercentage} className="icon" />
          <div className="card-content">
            <h3>{calculateStats().newClientsPercentage}%</h3>
            <p>نسبة العملاء الجدد</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsReport;

import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getPromo } from "store/general/generalActions";
import { formatDateToArabic } from "utlis";

const DetailsDiscountCodes = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const isLoading = useSelector((state) => state.general.isLoading);
  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(getPromo(id))
      .then(unwrapResult)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const user = useSelector((state) => state.auth.user);
  const userType = user.role;

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);
  console.log(data, "data");
  return (
    <>
      <HelmetInfo titlePage={"تفاصيل"} />
      <div
        className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3"
        data-aos="fade-left">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={"/dashboard/vendor/homedashboard"}
          titleInfoPage={"تفاصيل"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to={
              userType === "vendor"
                ? "/dashboard/vendor/discountCodes"
                : "/dashboard/admin/discountCodes"
            }>
            عودة
          </Link>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="basic-information-content account-info-details mt-3" data-aos="fade-up">
          {/* =========== START ALL BASIC INFO ============= */}
          <div className="all-basic-info all-details-discount">
            {/* ======== START HEADER INFO ========== */}
            <div className="header-info-profile ">
              <div className="content-user-info content-info-deails-offer">
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">كود الخصم</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data?.code} </p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">تاريخ الانشاء</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data ? formatDateToArabic(data?.created_at) : ""}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">نوع الخصم</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data?.discount_type}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">قيمة الخصم</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data?.value} ر.س</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
              </div>

              <div className="content-user-info content-info-deails-offer border-top  pt-2">
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">عدد مرات الاستخدام المسموح بها</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data?.uses} مرات</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">صلاحية الخصممن تاريخ</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data ? data?.start_date : ""}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
                {/* ======== START ALL INFO CONTENT ONE ========= */}
                <div className="all-info-content  d-flex">
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">صلاحية الخصم الى تاريخ</h2>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                  {/* ========= START MAIN INFO ONE ========= */}
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{data ? data?.end_date : ""}</p>
                  </div>
                  {/* ========= END MAIN INFO ONE ========= */}
                </div>
                {/* ======== END ALL INFO CONTENT ONE ========= */}
              </div>
            </div>
            {/* ======== END HEADER INFO ========== */}
          </div>
          {/* =========== END ALL BASIC INFO ============= */}
        </div>
      )}
    </>
  );
};

export default DetailsDiscountCodes;

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import MapLocation from "Components/MapLocation/MapLocation";
import HeaderInfoPage from "Components/Ui/HeaderInfoPage/HeaderInfoPage";
import "./AddEditAddress.css";
import FormEditAddress from "./FormEditAddress";

const AddEditAddress = () => {
  return (
    <>
      <HelmetInfo titlePage={"اضافة/تعديل العنوان"} />
      <div className="edit-add-address padding-bottom-70">
        <div className="container">
          {/* =========== START HEADER UPDATE PROFILE ========= */}
          <HeaderInfoPage
            title={"اضافة/تعديل العنوان"}
            isActiveLink={true}
            routePage={"/profile"}
            textLink={"عودة"}
            isNewContent={false}
            Newcontent={false}
          />

          {/* =========== END HEADER UPDATE PROFILE ========= */}
          {/* =========== START ALL EDIT ADD ADDRESS ========== */}
          <div className="all-edit-add-address mt-5">
            {/* ============ START ROW ============ */}
            <div className="row g-3 flex-wrap-reverse">
              {/* =========== START COL ========== */}
              <div className="col-12 col-md-6">
                <FormEditAddress />
              </div>
              {/* =========== END COL ========== */}
              {/* =========== START COL ========== */}
              <div className="col-12 col-md-6">
                <div className="map-address">
                  <h2 className="title">العنوان على الخريطة</h2>
                  <MapLocation />
                </div>
              </div>
              {/* =========== END COL ========== */}
            </div>
            {/* ============ END ROW ============ */}
          </div>
          {/* =========== END ALL EDIT ADD ADDRESS ========== */}
        </div>
      </div>
    </>
  );
};

export default AddEditAddress;

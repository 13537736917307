import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "Components/Loader/Loader";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getEmployees } from "store/vendor/vendorActions";
import "./ReportDetails.css";

const EmployeesReport = ({ bookingDay, setReportData, setReportHeaders }) => {
  const user = useGetAuthUser();
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mostBookedEmployees, setMostBookedEmployees] = useState([]);
  const dispatch = useDispatch();

  const fetchEmployeesData = useCallback(async () => {
    try {
      setIsLoading(true);
      const employeesParams = {
        with: ["bookings", "user"],
        limit: 200,
        per_page: 200,
        offset: 0,
        sort: "ASC",
        paginate: true,
        field: "id",
        deleted: "all",
        resource: "all",
        resource_columns: ["id", "name"],
        columns: ["vendor_id", "created_at"],
        operand: ["=", ">="],
        column_values: [user.model_id, bookingDay],
      };
      const employeesResponse = await dispatch(getEmployees(employeesParams)).unwrap();
      setEmployees(employeesResponse.data.data);
      const mostBookedEmployees = employeesResponse.data.data
        .sort((a, b) => b.bookings?.length - a.bookings?.length)
        .slice(0, 5);

      setMostBookedEmployees(mostBookedEmployees);
    } catch (error) {
      console.error("Error fetching employees data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, user.model_id, bookingDay]);

  useEffect(() => {
    fetchEmployeesData();
  }, [fetchEmployeesData]);

  useEffect(() => {
    const reportData = mostBookedEmployees.map((employee) => ({
      name: employee.user.name,
      bookings: employee.bookings?.length,
    }));
    setReportData(reportData);
    setReportHeaders(["اسم الموظف", "عدد الحجوزات"]);
  }, [mostBookedEmployees]);

  if (isLoading) return <Loader />;

  return (
    <div className="employees-report">
      <div className="report-summary">
        <div className="summary-card">
          <div className="card-content">
            <h3>{employees.length}</h3>
            <p>عدد الموظفين</p>
          </div>
          <FontAwesomeIcon icon={faUsers} className="icon" />
        </div>
      </div>

      <div className="report-table">
        <h3 className="mb-4">الموظفون الأكثر طلبا</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>اسم الموظف</th>
              <th>عدد الحجوزات</th>
            </tr>
          </thead>
          <tbody>
            {mostBookedEmployees.map((employee, index) => (
              <tr key={employee.name}>
                <td>{index + 1}</td>
                <td>{employee.user.name}</td>
                <td>{employee.bookings?.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeesReport;

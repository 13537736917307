import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addServiceToCart, removeServiceFromCart } from "store/vendor/vendorSlice";
import imgProduct from "../../../../assets/images/products/01.png";
import CardProductDetails from "./CardProductDetails";

const CategoryFilter = ({ sections, services }) => {
  const dispatch = useDispatch();
  const addToCart = (service) => dispatch(addServiceToCart(service));
  const removeFromCart = (serviceId) => dispatch(removeServiceFromCart(serviceId));

  const [selectedSection, setSelectedSection] = useState(null);
  const [filteredServicesList, setFilteredServicesList] = useState(services);

  useEffect(() => {
    const filterServices = () => {
      if (selectedSection) {
        return services.filter((item) => item.section_id === selectedSection.id);
      }
      return services;
    };

    setFilteredServicesList(filterServices());
  }, [services, selectedSection, setSelectedSection]);

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };

  if (!sections || sections.length === 0 || services.length === 0) return <Loader />;

  return (
    <div className="main-filter-category">
      <h2 className="title-top-1">الأقسام</h2>
      <div className="all-filter-buttons">
        <div className="filetr-buttons d-flex align-items-center gap-2">
          <button
            className={`main-btn main-outline-btn ${selectedSection === null ? "active" : ""}`}
            onClick={() => handleSectionChange(null)}>
            جميع المنتجات
          </button>
          {sections.length &&
            sections.map((section, index) => (
              <button
                className={`main-btn main-outline-btn ${selectedSection === section ? "active" : ""}`}
                key={index}
                onClick={() => handleSectionChange(section)}>
                {section.name_ar}
              </button>
            ))}
        </div>
      </div>

      <div className="all-products mt-3">
        <div className="row g-3">
          {filteredServicesList?.length === 0 ? (
            <div className="col-12 text-center">
              <p className="text-muted">لا توجد منتجات في هذا القسم.</p>
            </div>
          ) : (
            filteredServicesList?.map((service, index) => {
              return (
                <div className="col-12 col-sm-6 col-xl-4" key={index}>
                  <CardProductDetails
                    serviceDetails={service}
                    serviceId={service.id}
                    imgProduct={imgProduct}
                    titleService={service.name_ar}
                    timeServ={`${service.service_time} دقيقة`}
                    price={service.price}
                    handleAddToCart={addToCart}
                    handleRemoveFromCart={removeFromCart}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryFilter;

import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOffers } from "../../../../store/admin/adminActions";

const AdminReportOffers = ({ bookingDay, setReportData, setReportHeaders }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [totalOffersCount, setTotalOffersCount] = useState(0);

  const fetchOffersReport = useCallback(() => {
    setIsLoading(true);
    const params = {
      with: ["booking"],
      per_page: 500,
      limit: 500,
      sort: "DESC",
      paginate: "true",
      from_date: bookingDay,
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name_ar"],
      columns: ["created_at"],
      operand: [">="],
      column_values: [bookingDay],
    };
    dispatch(getOffers(params))
      .then(unwrapResult)
      .then((res) => {
        setOffers(res.data.data);
        setTotalOffersCount(res.data.total);

        // Prepare data for PDF
        const reportData = res.data.data.map((offer) => ({
          name: offer.name_ar || "",
          discount: offer.discount_percentage || 0,
          bookings_count: offer.bookings_count || 0,
          offer_price: offer.offer_price || 0,
          status: offer.is_active === "1" ? "نشط" : "غير نشط",
        }));

        setReportData(reportData);
        setReportHeaders(["#", "العرض", "نسبة الخصم", "عدد الحجوزات", "سعر العرض", "الحالة"]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, bookingDay, setReportData, setReportHeaders]);

  useEffect(() => {
    fetchOffersReport();
  }, [fetchOffersReport]);

  if (isLoading) return <Loader />;
  if (!offers.length) return null;

  const sortedOffers = [...new Map(offers.map((o) => [o.id, o])).values()].sort(
    (a, b) => b.bookings_count - a.bookings_count,
  );
  const newOffers = sortedOffers.filter(
    (offer) => new Date(offer.created_at) >= new Date(bookingDay),
  );

  return (
    <div>
      <div className="report-summary">
        <div className="summary-card card-shadow card-hover">
          <div className="card-content">
            <h3>{newOffers.length}</h3>
            <p>عدد العروض</p>
          </div>
          <FontAwesomeIcon icon={faPercent} className="icon" />
        </div>
      </div>

      <div className="report-table" data-aos="fade-up">
        <h3 className="mb-4">العروض</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>العرض</th>
              <th>نسبة الخصم</th>
              <th>عدد الحجوزات</th>
              <th>سعر العرض</th>
              <th>الحالة</th>
            </tr>
          </thead>
          <tbody>
            {newOffers.map((offer, index) => (
              <tr key={offer.id}>
                <td>{index + 1}</td>
                <td>{offer.name_ar}</td>
                <td>{String(offer.discount_percentage).split(".")[0] ?? 0}%</td>
                <td>{offer.bookings_count}</td>
                <td>{offer.offer_price}</td>
                <td>{offer.is_active === "1" ? "نشط" : "غير نشط"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReportOffers;

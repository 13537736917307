import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getProfileDetails } from "store/customer/customerActions";
import { setProfileInfo } from "store/customer/customerSlice";
import imgUser from "../../../assets/images/userImg/user.png";
import DropMenuNav from "../DropMenuNav/DropMenuNav";

const profileLinks = [
  { id: 0, textLink: "حسابى", RouteLink: "/profile" },
  { id: 1, textLink: "حجوزاتي", RouteLink: "/bookings" },
  { id: 2, textLink: "المفضلة", RouteLink: "/favoriteproducts" },
  { id: 3, textLink: "تسجيل الخروج", RouteLink: "/logout" },
];

const DropMenuUser = ({ handleUserLogout }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const profileInfo = useSelector((state) => state.customer.profileInfo);

  useEffect(() => {
    if (Object.keys(profileInfo).length === 0) {
      dispatch(getProfileDetails())
        .unwrap()
        .then((res) => {
          dispatch(
            setProfileInfo({
              first_name: res.data.name.split(" ")[0],
              last_name: res.data.name.split(" ")[1],
              email: res.data.email,
              phone: res.data.phone,
              image: res.data.image,
            }),
          );
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [profileInfo, dispatch]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  if (Object.keys(profileInfo).length === 0) return null;
  return (
    <section>
      <DropMenuNav
        iconImg={
          <img
            onClick={toggleMenu}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            src={imgUser}
            className=" object-fit-cover  img-user-nav"
            alt="Dropdown Trigger"
          />
        }
        children={
          <div>
            {profileLinks.map((item) => {
              if (item.textLink === "تسجيل الخروج") {
                return (
                  <button
                    key={item.id}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUserLogout();
                      setIsOpen(false);
                    }}
                    onTouchStart={(e) => {
                      e.preventDefault();
                      if (typeof handleUserLogout === "function") {
                        handleUserLogout();
                      }
                      // handleUserLogout();
                      setIsOpen(false);
                    }}
                    className="nav-link">
                    {item.textLink}
                  </button>
                );
              }

              return (
                <li className="nav-item" key={item.id}>
                  <NavLink className="nav-link" to={item.RouteLink}>
                    {item.textLink}
                  </NavLink>
                </li>
              );
            })}
          </div>
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        NewClassDrop={"dropmenu-user-nav"}
      />
    </section>
  );
};

export default DropMenuUser;

import { createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosClient from "Apis/axiosService";
import generalAxiosService from "./generalAxiosService";

const countriesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});
const regionsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});
const citiesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const InitialCountries = countriesAdapter.getInitialState();
const InitialRegions = regionsAdapter.getInitialState();
const InitialCities = regionsAdapter.getInitialState();

export const getCountries = createAsyncThunk(
  "general/getCountries",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/countries", {
        params,
      });
      countriesAdapter.setAll(InitialCountries, response.data || []);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCountry = createAsyncThunk(
  "general/getCountry",
  async (id, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get(`/countries/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const addCountry = createAsyncThunk(
  "general/addCountry",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/countries", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCountry = createAsyncThunk(
  "general/updateCountry",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/countries/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRegions = createAsyncThunk(
  "general/getRegions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/regions", {
        params,
      });
      regionsAdapter.setAll(InitialRegions, response.data || []);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRegion = createAsyncThunk(
  "general/updateRegion",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/regions/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRegion = createAsyncThunk("general/getRegion", async (id, { rejectWithValue }) => {
  try {
    const response = await generalAxiosService.get(`/regions/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addRegion = createAsyncThunk(
  "general/addRegion",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/regions", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCities = createAsyncThunk(
  "general/getCities",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/cities", {
        params,
      });
      citiesAdapter.setAll(InitialCities, response.data || []);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addCity = createAsyncThunk("general/addCity", async (values, { rejectWithValue }) => {
  try {
    const response = await generalAxiosService.post("/cities", values);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateCity = createAsyncThunk(
  "general/updateCity",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/cities/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCity = createAsyncThunk("general/getCity", async (id, { rejectWithValue }) => {
  try {
    const response = await generalAxiosService.get(`/cities/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getCanncelationReasons = createAsyncThunk(
  "general/getCanncelationReasons",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/cancellation_reasons", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getClientCanncelationReasons = createAsyncThunk(
  "general/getClientCanncelationReasons",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/client_cancellation_reasons", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const editCanncelationReasons = createAsyncThunk(
  "general/editCanncelationReasons",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(
        `/cancellation_reasons/${values.id}`,
        values,
        {
          params: { _method: "PUT" },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const editClientCanncelationReasons = createAsyncThunk(
  "general/editClientCanncelationReasons",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(
        `/client_cancellation_reasons/${values.id}`,
        values,
        {
          params: { _method: "PUT" },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const addCanncelationReasons = createAsyncThunk(
  "general/addCanncelationReasons",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/cancellation_reasons", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const addClientCanncelationReasons = createAsyncThunk(
  "general/addClientCanncelationReasons",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/client_cancellation_reasons", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const getTermConditions = createAsyncThunk(
  "general/getTermConditions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/term_conditions");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const editTermConditions = createAsyncThunk(
  "general/editTermConditions",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/term_conditions", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addCategory = createAsyncThunk(
  "general/addCategory",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post("/categories", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCategories = createAsyncThunk(
  "general/getCategories",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/categories", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCategoryById = createAsyncThunk(
  "general/getCategoryById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get(`/categories/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateCategory = createAsyncThunk(
  "general/updateCategory",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/categories/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteCategory = createAsyncThunk(
  "general/deleteCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.delete(`/categories/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getServiceCategories = createAsyncThunk(
  "general/getServiceCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/service_categories");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPromos = createAsyncThunk(
  "general/getPromos",
  async (params, { rejectWithValue }) => {
    // const token =
    // "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMzVjNDBlYjk3ZjM4N2ExODQyZDM1YzY4NzY0NzFiMzkzNWU0ZTBmNWY4YTgxYWY4MjdhMmU5Nzk4M2EzZTRkNTcwNzA0NjZkODVlYmFhMzEiLCJpYXQiOjE3Mjc5NDUzMTIuNDU2ODMxLCJuYmYiOjE3Mjc5NDUzMTIuNDU2ODM2LCJleHAiOjE3NTk0ODEzMTIuNDU0ODkxLCJzdWIiOiIxNyIsInNjb3BlcyI6W119.eBu_6_WtTzCy-0V2R9a8At_EjJPeXlmenKu4wNMhyARBH1Sv35Tn54WrNyMc7rUzbPKDYrAU5JpI2QXSuvSOPtu0Bdm9ticBtgsSJIydwGI-ok9ChR6DeTmkHI5-TsMUmcEHP7MEUZDuBYRLbg694GZOLgakTo2RmX_cSH7IRRSVZ3wFtH2-mWYhQFchXpfzdXx-CDYqC5O0H0c5rYu3d0qEZ-4ezOt7L3dORrLk-ISYfaTVR0fcASIcq6s8KYVLHorQJaF7fRVmwaYfmgicQfUV6YonwCYaovVFRn4ZzcM6IT10DCydaIQXesnxpUP9-G-xP2iyvLDnLBj_j-YcL1fPgh2DlEpPpPw8ETCgxEMRYdeedAk-KlpMhYaKps0ls1_EfxdC0_zMPWU0H-x4KIDza_Gyg9V9ML1hVBP4S8pgaJi5JDuHmuIZ3PjKm50t4tImzhlX7bOi9j_fpY2JmPhbqtYJ559yUQx3SwIsK8Iw7oayFvEMJqUUHHQWqhWuzVcnx5Nq83lqGae-nNPnP41q1fC6cYRbGXaIZIZ_H3T6nMH4qG4b5gdMCxBdek1izK4w1aXuvwdwIerNhbaYEKwwCM7gUwmsnDDcrSkNq4W9AbXsf2HeOcXGx-2P2u1FaIVSbBoSRT2yoMGqIVcSxR5oPbJqqTK1CUiARjem7ls";
    try {
      const response = await generalAxiosService.get("/promo_codes", {
        params,
      });
      return response.data;
    } catch (err) {
      console.log(err, "err");
      return rejectWithValue(err.response.data);
    }
  },
);

export const addPromo = createAsyncThunk(
  "general/addPromo",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/promo_codes`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePromo = createAsyncThunk(
  "general/updatePromo",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/promo_codes/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePromo = createAsyncThunk(
  "general/deletePromo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.delete(`/promo_codes/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPromo = createAsyncThunk("general/getPromo", async (id, { rejectWithValue }) => {
  try {
    const response = await generalAxiosService.get(`/promo_codes/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendorWallet = createAsyncThunk(
  "general/getVendorWallet",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get(`/wallets`, {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getWalletTransactions = createAsyncThunk(
  "general/getWalletTransactions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/wallet_transactions", { params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// same as above but for admin filters
export const getAdminWallets = createAsyncThunk(
  "general/getAdminWallets",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get(`/wallets`, {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateAdminWallet = createAsyncThunk(
  "general/updateAdminWallet",
  async (values, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.post(`/wallets/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAdminWallet = createAsyncThunk(
  "general/getAdminWallet",
  async (id, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get(`/wallets/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getWalletTxns = createAsyncThunk(
  "general/getWalletTxns",
  async (params, { rejectWithValue }) => {
    try {
      const response = await generalAxiosService.get("/wallet_transactions", { params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAllPackages = createAsyncThunk(
  "general/getAllPackages",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("https://api.streamline.com.sa/admin/v1/packages", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// get Wathq national address
export const getWathqNationalAddress = createAsyncThunk(
  "general/getWathqNationalAddress",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(
        `https://api.wathq.sa/spl/national/address/info/${params.id}`,
        {
          headers: {
            apiKey: "8EiUAjw6iyjM5l5uAlceILfWpJ1g3uEJ",
          },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

import { useDispatch } from "react-redux";
import { login as loginVendorUser } from "store/auth/authActions";

// used in RegisterServicesStepThree.jsx
export const useAuthActions = () => {
  const dispatch = useDispatch();

  const handleServiceProviderLogin = async (username, password, navigate) => {
    try {
      const response = await dispatch(loginVendorUser({ username, password })).unwrap();

      if (response.access_token) {
        navigate("/dashboard/vendor/homedashboard");
      } else {
        navigate("/logindashboard");
      }
    } catch (error) {
      console.error("Login failed:", error);
      navigate("/logindashboard");
    }
  };

  return { handleServiceProviderLogin };
};

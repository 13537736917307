import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addVendorToWishList, removeVendorFromWishList } from "store/customer/customerActions";
import { addItemToWishList, removeItemFromWishList } from "store/customer/customerSlice";
import iconLocation from "../../../assets/images/icons/location.svg";

const HeaderServicesName = ({ currentBranch }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.customer.wishList);

  const isCurrentBranchFavorite = wishlist?.some(
    (item) => item.vendor_id === currentBranch?.vendor_id,
  );

  const [isFavorite, setIsFavorite] = useState(isCurrentBranchFavorite);
  const assignedRandmonRatings = useSelector((state) => state.customer.assignedRandmonRatings);
  const rating = assignedRandmonRatings.find((rating) => rating.id === currentBranch.id)?.rating;
  const totalRatings = assignedRandmonRatings.find(
    (rating) => rating.id === currentBranch.id,
  )?.totalRatings;

  const addBranchToFavorite = (branchInfo) => {
    if (isFavorite) return;
    setIsFavorite(true);
    const params = { vendor_id: branchInfo.vendor_id };

    dispatch(addVendorToWishList(params))
      .unwrap()
      .then((res) => {
        setIsFavorite(true);
        toast.success("تمت إضافة المتجر إلى قائمة المفضلة");
        const newItem = {
          ...branchInfo,
          id: res.data.id,
          isProuctActive: "1",
          productName: currentBranch.vendor.name,
          proructImage: currentBranch.vendor.image,
          productBranchAddress: currentBranch.address,
          vendor: currentBranch.vendor,
        };
        dispatch(addItemToWishList(newItem));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const removeBranchFromFavorite = (branchInfo) => {
    if (!isFavorite) return;
    setIsFavorite(false);
    const wishItem = wishlist.find((item) => item.vendor_id === branchInfo.vendor_id);
    const id = wishItem.id;
    dispatch(removeVendorFromWishList(id))
      .unwrap()
      .then(() => {
        setIsFavorite(false);
        toast.success("تمت إزالة المتجر من قائمة المفضلة");
        dispatch(removeItemFromWishList(id));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  if (!currentBranch) return null;
  return (
    <section>
      <div
        data-aos="fade-up"
        className="main-header-services d-flex  justify-content-between  align-items-center gap-3 flex-wrap"
        style={{
          marginTop: "10px",
        }}>
        <div className="header-right">
          <h2 className="title size-text-color-dark mt-2">
            {currentBranch.name} - {currentBranch.vendor.name}
          </h2>
          <div className="info-head-serv d-flex align-items-center gap-4 flex-wrap">
            {/* <div className="rates d-flex align-items-center gap-2"> */}
            {/* <div className="stars-rate d-flex  align-items-center gap-1">
                {Array.from({ length: Math.floor(rating) }).map((_, index) => (
                  <div key={index} className="icon-star size-text-color-dark">
                    <FontAwesomeIcon icon={solidStar} />
                  </div>
                ))}
              </div>

              <div className="info-rate-details d-flex  align-items-center gap-1">
                <div className="num-rate size-text-color-gray">{rating}</div>
                <div className="all-rates main-text-color size-text-color-gray">
                  ({totalRatings} تقييم)
                </div>
              </div>
            </div> */}

            <div className="location-details d-flex align-items-center gap-2">
              <div className="icon-location">
                <img src={iconLocation} alt="icon location" width={"25px"} height={"25px"} />
              </div>
              <p className="text-location size-text-color-dark">{currentBranch.address}</p>
            </div>
          </div>
        </div>

        <div className="header-left d-flex  align-items-center gap-3">
          <div
            className={`favorite-button d-flex align-items-center justify-content-center ${isFavorite ? "active" : ""}`}
            onClick={() => {
              if (!user) {
                toast.error("يجب عليك تسجيل الدخول أولا");
                return;
              }

              if (isFavorite) {
                removeBranchFromFavorite(currentBranch);
              } else {
                addBranchToFavorite(currentBranch);
              }
            }}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "30%",
              transition: "all 0.3s ease",
              backgroundColor: isFavorite ? "#093A4E" : "transparent",
              border: `1px solid ${isFavorite ? "#093A4E" : "#ddd"}`,
            }}>
            <FontAwesomeIcon
              size="lg"
              icon={faHeart}
              style={{
                fill: isFavorite ? "white" : "#666",
                transition: "transform 0.3s ease",
                color: isFavorite ? "white" : "#666",
                transform: isFavorite ? "scale(1.2)" : "scale(1)",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderServicesName;

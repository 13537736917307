import { faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ServiceName.css";

const VendorSocials = ({ vendorInfo }) => {
  if (!vendorInfo) return null;

  // Safely access nested properties
  const website_url = vendorInfo.website_url || vendorInfo?.vendor?.website_url;
  const instagram = vendorInfo.instagram || vendorInfo?.vendor?.instagram;
  const twitter = vendorInfo.twitter || vendorInfo?.vendor?.twitter;
  const email = vendorInfo.user?.email || vendorInfo?.email;

  const formatSocialUrl = (url) => {
    if (!url) return null;

    const cleanUrl = url.trim().replace(/\/+$/, "");
    return cleanUrl.startsWith("http") ? cleanUrl : `https://${cleanUrl}`;
  };

  const hasSocialMedia = website_url || instagram || twitter || email;

  if (!hasSocialMedia) return null;

  return (
    <div className="vendor-socials mt-4">
      <div className="container">
        <h3 className="mb-3">تواصل معنا</h3>
        <div className="social-links">
          <ul className="list-social p-0 m-0 d-flex align-items-center gap-3">
            {instagram && (
              <li className="list-one">
                <a
                  href={formatSocialUrl(instagram)}
                  target="_blank"
                  className="icon-social"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
              </li>
            )}
            {twitter && (
              <li className="list-one">
                <a
                  href={formatSocialUrl(twitter)}
                  target="_blank"
                  className="icon-social"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faXTwitter} size="lg" />
                </a>
              </li>
            )}
            {website_url && (
              <li className="list-one">
                <a
                  href={formatSocialUrl(website_url)}
                  target="_blank"
                  className="icon-social"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faGlobe} size="lg" />
                </a>
              </li>
            )}
            {email && (
              <li className="list-one">
                <a
                  href={`mailto:${email}`}
                  target="_blank"
                  className="icon-social"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faEnvelope} size="lg" />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VendorSocials;

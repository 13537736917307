import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import amiriRegular from "../../../../Components/BookingsInfo/DetailsOrderBooking/BookingCoastDetails/Amiri-Regular.ttf";

Font.register({
  family: "Amiri",
  src: amiriRegular,
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Amiri",
    direction: "rtl",
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row-reverse",
    borderBottomWidth: 1,
    borderBottomColor: "#bfbfbf",
    minHeight: 35,
    alignItems: "center",
  },
  tableHeader: {
    backgroundColor: "#f8f9fa",
  },
  tableCell: {
    flex: 1,
    padding: 8,
    textAlign: "right",
    borderRightWidth: 1,
    borderRightColor: "#bfbfbf",
  },
});

const ReportPDF = ({ title, data, headers, timestamp }) => {
  console.log({ data, headers });

  return (
    <Document key={`doc-${timestamp}`}>
      <Page size="A4" style={styles.page} key={`page-${timestamp}`}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.table} key={`table-${timestamp}`}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            {headers.map((header, index) => (
              <Text key={index} style={styles.tableCell}>
                {header}
              </Text>
            ))}
          </View>

          {data.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.tableRow}>
              {Object.values(row).map((cell, cellIndex) => (
                <Text key={cellIndex} style={styles.tableCell}>
                  {cell}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default ReportPDF;

import { unwrapResult } from "@reduxjs/toolkit";
import DisplayField from "Components/Forms/DisplayField";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import TextAreaInput from "Components/Forms/TextArea";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getServiceCategories } from "store/general/generalActions";
import { addOffer, getSections, getServices } from "store/vendor/vendorActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("ادخل اسم العرض"),
  description_ar: Yup.string().required("ادخل وصف العرض"),
  section_id: Yup.string().required("اختار التبويب"),
  service_id: Yup.string().required("اختار الخدمات بالعرض"),
  price_type: Yup.string().required("اختار نوع التسعير"),
  discount_percentage: Yup.number().optional("ادخل الخصم").max(100, "الخصم يجب ان يكون اقل من 100"),
  offer_price: Yup.string().optional("ادخل سعر الخدمة"),
  service_price: Yup.string().optional("ادخل سعر الخدمة"),
});

const priceTypeOptions = [
  { value: "service", label: "نفس سعر الخدمة" },
  { value: "free", label: "مجانية" },
  { value: "special", label: "سعر خاص" },
  { value: "discount", label: "خصم" },
];

const EditAddOffer = () => {
  const navigate = useNavigate();

  const user = useGetAuthUser();
  const [categories, setCategories] = useState([]);
  const [sections, setSections] = useState([]);
  const [services, setServices] = useState([]);

  // Initialize states
  const [selectedService, setSelectedService] = useState(null);
  const [offerValues, setOfferValues] = useState({
    name_ar: "",
    description_ar: "",
    section_id: "",
    service_id: "",
    price_type: "",
    category_id: "",
    discount_percentage: "",
    offer_price: "",
    service_price: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (name, value) => {
    if (name === "service_id") {
      const service = services.find((s) => s.value.toString() === value.toString());
      if (service) {
        setSelectedService(service);
        const servicePrice = service.price;

        setOfferValues((prev) => {
          const updatedValues = {
            ...prev,
            [name]: value,
            service_price: servicePrice,
          };

          // Update offer price based on current price type
          switch (prev.price_type) {
            case "free":
              updatedValues.offer_price = "0";
              break;
            case "service":
              updatedValues.offer_price = servicePrice;
              break;
            case "special":
              updatedValues.offer_price = prev.offer_price || servicePrice;
              break;
            case "discount": {
              const discount = parseFloat(prev.discount_percentage) || 0;
              updatedValues.offer_price = ((servicePrice * (100 - discount)) / 100).toString();
              break;
            }
            default:
              updatedValues.offer_price = servicePrice;
          }

          return updatedValues;
        });
      }
      return;
    }

    if (name === "price_type") {
      const servicePrice = selectedService?.price || offerValues.service_price;

      let updatedValues = {
        ...offerValues,
        [name]: value,
        service_price: servicePrice, // Keep service price consistent
      };

      // Reset values when switching types
      switch (value) {
        case "free":
          updatedValues.offer_price = "0";
          updatedValues.discount_percentage = "";
          break;
        case "service":
          console.log("service price: ", servicePrice);
          updatedValues.offer_price = servicePrice;
          updatedValues.discount_percentage = "";
          break;
        case "special":
          updatedValues.offer_price = "";
          updatedValues.discount_percentage = "";
          break;
        case "discount":
          updatedValues.discount_percentage = "";
          updatedValues.offer_price = servicePrice;
          break;
        default:
          updatedValues.offer_price = servicePrice;
          updatedValues.discount_percentage = "";
      }

      console.log("updatedValues: ", updatedValues);
      setOfferValues(updatedValues);
      return;
    }

    if (name === "discount_percentage") {
      if (offerValues.price_type === "discount") {
        const servicePrice = selectedService?.price || offerValues.service_price;
        const discount = parseFloat(value) || 0;
        const offerPrice = ((servicePrice * (100 - discount)) / 100).toString();

        setOfferValues((prev) => ({
          ...prev,
          [name]: value,
          service_price: servicePrice, // Keep service price consistent
          offer_price: offerPrice,
        }));
        return;
      }
    }

    if (name === "offer_price" && offerValues.price_type === "special") {
      const servicePrice = selectedService?.price || offerValues.service_price;
      console.log("service price: ", servicePrice);
      setOfferValues((prev) => ({
        ...prev,
        [name]: value,
        service_price: servicePrice, // Keep service price consistent
        offer_price: value,
        discount_percentage: "",
      }));
      return;
    }

    setOfferValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (values, { resetForm, setErrors }) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const catId = parseInt(categories[0].value);

    const updatedValues = {
      ...values,
      name_en: values.name_ar,
      description_en: values.description_ar,
      sub_category_id: catId.toString(),
      category_id: catId.toString(),
      service_id: [values.service_id],
      offer_price: Number(offerValues.offer_price),
      service_price: Number(offerValues.service_price),
      discount_percentage: Number(offerValues.discount_percentage),
      price_type: offerValues.price_type,
      section_id: offerValues.section_id,
    };

    // if the price type is free, set the discount percentage to 100
    if (offerValues.price_type === "free") {
      updatedValues.discount_percentage = "100";
    }

    // if the price type is service, set the discount percentage to 0
    if (offerValues.price_type === "service") {
      updatedValues.discount_percentage = "0";
    }

    // if the price type is special, take the offer price and calculate the discount percentage
    if (offerValues.price_type === "special") {
      const offerPrice = Number(offerValues.offer_price);
      const servicePrice = Number(offerValues.service_price);
      const discountPercentage = ((servicePrice - offerPrice) / servicePrice) * 100;
      updatedValues.discount_percentage = discountPercentage.toFixed(2);
    }
    console.log("updatedValues: ", updatedValues);

    dispatch(addOffer(updatedValues))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم الاضافة بنجاح.");
        resetForm();
        navigate(
          user.type === "vendor" ? "/dashboard/vendor/offersInfo" : "/dashboard/admin/offersInfo",
        );
      })
      .catch((error) => {
        setErrors(error.errors);
        console.log(error, "error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const dispatch = useDispatch();

  const fetchServiceCategories = () => {
    const params = {
      limit: 100,
      offset: 0,
      sort: "DESC",
      paginate: "true",
      per_page: 100,
      page: 1,
      columns: ["is_active"],
      operand: ["="],
      column_values: ["1"],
      deleted: "undeleted",
    };

    dispatch(getServiceCategories(params))
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setCategories(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchSections = () => {
    const params = {
      deleted: "undeleted",
      limit: 100,
      columns: ["is_active", "vendor_id"],
      operand: ["=", "="],
      column_values: ["1", user.model_id],
      offset: 0,
      sort: "DESC",
      paginate: "true",
      per_page: 100,
      page: 1,
    };
    dispatch(getSections(params))
      .then(unwrapResult)
      .then((res) => {
        const cloneCategories = res.data.data.map((cat) => ({
          label: cat.name_ar,
          value: cat.id,
        }));
        setSections(cloneCategories);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchServices = () => {
    const params = {
      deleted: "undeleted",
      with: ["branches"],
      limit: 100,
      offset: 0,
      sort: "DESC",
      paginate: "true",
      per_page: 100,
      page: 1,
      columns: ["is_active", "vendor_id"],
      operand: ["=", "="],
      column_values: ["1", user.model_id],
    };
    dispatch(getServices(params))
      .then(unwrapResult)
      .then((res) => {
        console.log("Raw API response:", res.data.data);
        const cloneServices = res.data.data.map((cat) => {
          console.log("Service price for", cat.name_ar, ":", cat.price);
          return {
            label: cat.name_ar,
            value: cat.id,
            price: cat.price,
          };
        });
        setServices(cloneServices);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchServiceCategories();
    fetchSections();
    fetchServices();
  }, []);

  return (
    <>
      <HelmetInfo titlePage={"اضافة تعديل عرض جديد"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          routeHomePage={
            user.type === "vendor"
              ? "/dashboard/vendor/homedashboard"
              : "/dashboard/admin/homedashboard"
          }
          titleInfoPage={"اضافة / تعديل عرض جديد"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to={
              user.type === "vendor"
                ? "/dashboard/vendor/offersInfo"
                : "/dashboard/admin/offersInfo"
            }>
            عودة
          </Link>
        </div>
      </div>
      <div className="form-edit-add-offer">
        <div className="row g-4">
          <div className="col-12">
            <div className="main-form-offer" data-aos="fade-up">
              <FormField
                initialValues={offerValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                <div className="row g-1 g-sm-3">
                  <div className="col-12 col-md-12">
                    <InputFiled
                      label={"اسم العرض"}
                      name="name_ar"
                      type="text"
                      placeholder={"اسم العرض"}
                      handleOnChange={(value) => handleChange("name_ar", value)}
                      success
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <TextAreaInput
                      label="وصف العرض"
                      name="description_ar"
                      placeholder="وصف العرض"
                      handleOnChange={(value) => handleChange("description_ar", value)}
                      success
                    />
                  </div>

                  <div className="col-12">
                    <div className="select-reg">
                      <label htmlFor="exampleFormControl3" className="form-label">
                        التبويب
                      </label>
                      <SelectField
                        name="section_id"
                        label={false}
                        options={sections}
                        valueSelected={"التبويب"}
                        handleOnChange={(value) => handleChange("section_id", value)}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="select-reg">
                      <label htmlFor="exampleFormControlInput28" className="form-label">
                        الخدمة
                      </label>
                      <SelectField
                        name="service_id"
                        label={false}
                        options={services}
                        valueSelected={"اختار الخدمة"}
                        handleOnChange={(value) => {
                          handleChange("service_id", value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="select-reg">
                      <label htmlFor="exampleFormControl5" className="form-label">
                        نوع التسعير
                      </label>
                      <SelectField
                        name="price_type"
                        label={false}
                        options={priceTypeOptions}
                        valueSelected={"نوع التسعير"}
                        handleOnChange={(value) => {
                          handleChange("price_type", value);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="col-12 col-md-6"
                    style={{ display: offerValues.price_type === "discount" ? "block" : "none" }}>
                    <InputFiled
                      label={"الخصم"}
                      name="discount_percentage"
                      type="number"
                      placeholder={"الخصم"}
                      success
                      disabled={offerValues.price_type !== "discount"}
                      handleOnChange={(value) => handleChange("discount_percentage", value)}
                    />
                  </div>
                  <div
                    className="col-12 col-md-6"
                    style={{ display: offerValues.price_type === "special" ? "block" : "none" }}>
                    <InputFiled
                      label={"سعر خاص بالعرض"}
                      name="offer_price"
                      type="number"
                      placeholder={"سعر خاص بالعرض"}
                      disabled={offerValues.price_type !== "special"}
                      handleOnChange={(value) => handleChange("offer_price", value)}
                      success
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <DisplayField
                      label={"سعر الخدمة"}
                      value={offerValues.service_price}
                      disabled
                      success
                    />
                  </div>

                  <div className="col-12 col-md-6">
                    <DisplayField
                      label={"سعر العرض"}
                      value={offerValues.offer_price}
                      disabled
                      success
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="main-btn btn-submit px-5 mt-4"
                  disabled={isSubmitting}>
                  {isSubmitting ? "جاري الحفظ..." : "حفظ"}
                </button>
              </FormField>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddOffer;

import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { addCity, getCity, getRegions, updateCity } from "store/general/generalActions";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("اسم المدينة مطلوب"),
  region_id: Yup.string().required("المنطقة مطلوبة"),
});

const CityForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [regions, setRegions] = useState([]);
  const [formData, setFormData] = useState({
    name_ar: "",
    name_en: "",
    region_id: "",
    is_active: "1",
  });

  const fetchRegions = useCallback(() => {
    setIsLoading(true);
    dispatch(getRegions())
      .then(unwrapResult)
      .then((res) => {
        if (!res.data?.data?.length) {
          toast.error("لا توجد مناطق متاحة");
          navigate("/dashboard/admin/cities");
          return;
        }
        setRegions(res.data.data || []);
      })
      .catch(() => {
        toast.error("فشل في تحميل المناطق");
        navigate("/dashboard/admin/cities");
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchRegions();
    if (isEditMode) {
      setIsLoading(true);
      dispatch(getCity(id))
        .then(unwrapResult)
        .then((res) => {
          if (res.success) {
            setFormData({
              name_ar: res.data.name_ar,
              name_en: res.data.name_en,
              region_id: res.data.region_id.toString(),
              is_active: res.data.is_active,
            });
          }
        })
        .catch(() => {
          toast.error("فشل في جلب بيانات المدينة");
          navigate(-1);
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, id, isEditMode, navigate, fetchRegions]);

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const action = isEditMode ? updateCity : addCity;
      const payload = isEditMode ? { id, ...formData } : formData;
      console.log({ payload });

      const res = await dispatch(action(payload)).unwrap();

      if (res.success) {
        toast.success(isEditMode ? "تم التعديل بنجاح" : "تمت الإضافة بنجاح");
        navigate(-1);
      }
    } catch (error) {
      toast.error(isEditMode ? "فشل في تعديل المدينة" : "فشل في إضافة المدينة");
    } finally {
      setIsSubmitting(false);
    }
  }, [dispatch, formData, id, isEditMode, navigate, isSubmitting]);

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const statusOptions = [
    { value: "1", label: "نشط" },
    { value: "0", label: "غير نشط" },
  ];

  if (isLoading) return <Loader />;

  return (
    <>
      <HelmetInfo titlePage={isEditMode ? "تعديل مدينة" : "إضافة مدينة"} />

      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          titleInfoPage={isEditMode ? "تعديل مدينة" : "إضافة مدينة"}
          routeHomePage="/dashboard/admin/homedashboard"
        />
        <div className="link-back">
          <Link className="size-text-color-dark main-text-color fs-5" to="/dashboard/admin/cities">
            عودة
          </Link>
        </div>
      </div>

      <div className="details-add-edite-PROFILE mt-3" data-aos="fade-up">
        <div className="info-user-edit">
          <FormField
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-md-6">
                <InputField
                  label="اسم المدينة بالعربية"
                  name="name_ar"
                  type="text"
                  placeholder="اسم المدينة بالعربية"
                  success
                  handleOnChange={(value) => {
                    handleChange("name_ar", value);
                    handleChange("name_en", value);
                  }}
                />
              </div>

              <div className="col-md-6">
                <SelectField
                  label="المنطقة"
                  name="region_id"
                  options={regions.map((region) => ({
                    value: region.id.toString(),
                    label: region.name_ar,
                  }))}
                  value={formData.region_id}
                  success
                  handleOnChange={(value) => handleChange("region_id", value)}
                />
              </div>
              <div className="col-md-6">
                <SelectField
                  label="الحالة"
                  name="is_active"
                  options={statusOptions}
                  value={formData.is_active}
                  success
                  handleOnChange={(value) => handleChange("is_active", value)}
                />
              </div>
            </div>

            <button type="submit" className="main-btn btn-submit px-5 mt-4" disabled={isSubmitting}>
              {isSubmitting ? "جاري الحفظ..." : "حفظ"}
            </button>
          </FormField>
        </div>
      </div>
    </>
  );
};

export default CityForm;

import { unwrapResult } from "@reduxjs/toolkit";
import Footer from "Components/Footer/Footer";
import NavBar from "Components/NavBar/NavBar";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { logoutAction } from "store/auth/authActions";
import { getSearchDetails, listWishList } from "store/customer/customerActions";
import {
  setCategories,
  setEmployees,
  setOffers,
  setSections,
  setServiceProviders,
  setServicesList,
  setVendorsBranches,
  setWishList,
} from "store/customer/customerSlice";
import {
  extractBranchesFromVendors,
  extractEmployees,
  extractOffers,
  extractSections,
} from "utlis";
import { getCategories } from "../../store/general/generalActions";

const Layout = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLogedIn);
  const user = useSelector((state) => state.auth.user);
  const role = useSelector((state) => state.auth.role);

  const fetchCustomerDetails = useCallback(
    (searchParams) => {
      dispatch(getSearchDetails(searchParams))
        .then(unwrapResult)
        .then((result) => {
          const vendors = result.data.vendors.data.filter((vendor) => vendor.is_active === "1");
          const services = result.data.services.data.filter((service) => service.is_active === "1");
          dispatch(setServiceProviders(vendors));
          dispatch(setServicesList(services));

          const employees = extractEmployees(result.data.services.data);
          dispatch(setEmployees(employees));

          const offers = extractOffers(vendors, services);
          dispatch(setOffers(offers));

          const branches = extractBranchesFromVendors(vendors);
          dispatch(setVendorsBranches(branches));

          const sections = extractSections(services);
          dispatch(setSections(sections));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const searchParams = {
      paginate: "true",
      per_page: 200,
      page: 1,
      order: "DESC",
      sort: "id",
      deleted: "undeleted",
      resource: "all",
      with: ["vendor", "vendor.branches", "vendor.branches.services"],
      columns: ["is_active"],
      operand: ["="],
      column_values: ["1"],
    };
    fetchCustomerDetails(searchParams);
    if (isLoggedIn) {
      getWishlist();
    }
    getCategoriesList();

    const notCustomer = role !== "customer";
    if (isLoggedIn && notCustomer) {
      dispatch(logoutAction());
    }
  }, [isLoggedIn, role, fetchCustomerDetails, dispatch]);

  const getWishlist = useCallback(() => {
    const params = {
      with: ["vendor", "vendor.branches"],
      limit: 50,
      offset: 0,
      sort: "DESC",
      paginate: "true",
      field: "id",
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name_ar"],
      columns: ["created_by", "deleted_at"],
      operand: ["=", "="],
      column_values: [user?.id, null],
    };
    dispatch(listWishList(params))
      .unwrap()
      .then((res) => {
        dispatch(setWishList(res.data.data));
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [dispatch, user?.id]);

  const getCategoriesList = useCallback(() => {
    dispatch(getCategories())
      .unwrap()
      .then((res) => {
        dispatch(setCategories(res.data.data));
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [dispatch]);

  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;

import { pdf } from "@react-pdf/renderer";
import { unwrapResult } from "@reduxjs/toolkit";
import FileSaver from "file-saver";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addReview,
  cancelBooking,
  createBooking,
  getVendorSettings,
} from "store/customer/customerActions";
import ModalEvaluationRateStar from "../ModalsBookings/ModalEvaluationRateStar";
import ModalReservationConfirmation from "../ModalsBookings/ModalReservationConfirmation";
import "./BookingCoastDetails.css";
import InvoicePDF from "./InvoicePDf";

const calculateServicesTotal = (servicesList) => {
  return servicesList.reduce((total, { service }) => total + parseFloat(service.price), 0);
};

const calculateOfferDiscount = (total, offer, promocode) => {
  if (offer) {
    return (parseFloat(offer.discount_percentage) / 100) * total;
  }
  if (promocode) {
    return parseFloat(promocode);
  }
  return 0;
};

const calculateFinalTotal = (servicesTotal, discountAmount) => {
  return servicesTotal - discountAmount;
};

const BookingCoastDetails = ({ booking }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModalReservation, setShowModalReservation] = useState(false);

  const servicesTotal = calculateServicesTotal(booking.servicesList);
  const discountAmount = calculateOfferDiscount(
    servicesTotal,
    booking.offer,
    booking.promocode_value,
  );
  const finalTotal = calculateFinalTotal(servicesTotal, discountAmount);

  const openModalReservation = () => setShowModalReservation(true);
  const hideModelReservation = () => setShowModalReservation(false);
  const user = useSelector((state) => state.auth.user);

  const generateCustomerInvoice = async () => {
    try {
      console.log({ booking });
      const name = `${user.first_name} ${user.last_name}`;
      const blob = await pdf(<InvoicePDF booking={booking} name={name} />).toBlob();
      FileSaver.saveAs(blob, `invoice_${booking.id}.pdf`);
    } catch (err) {
      toast.error("فشل إعادة الحجز. يرجى المحاولة مرة أخرى.");
    }
  };

  const handleRebook = async () => {
    try {
      const rebookingData = {
        employee_id: booking.employee_id,
        booking_day: booking.booking_day,
        booking_time: `${booking.booking_time.split(":")[0]}:${booking.booking_time.split(":")[1]}`,
        attendance: 1,
        sub_total: finalTotal,
        discount: discountAmount,
        total: servicesTotal,
        payment_way: booking.payment_way,
        branch_id: booking.branch_id,
        notes: booking.notes,
        services: booking.servicesList.map((service) => service.service_id),
        offer_id: booking.offer ? booking.offer.id : null,
      };
      const res = await dispatch(createBooking(rebookingData)).unwrap();
      console.log("res", res);
      hideModelReservation();
      toast.success("تم إعادة الحجز بنجاح", {
        autoClose: 1000,
        draggablePercent: 60,
      });
      navigate(`/bookings`);
    } catch (error) {
      console.error("Rebooking failed:", error);
      toast.error("فشل إعادة الحجز. يرجى المحاولة مرة أخرى.");
    }
  };

  const [showModalReview, setShowModalReview] = useState(false);
  const hideModelEvaluationRate = () => setShowModalReview(false);

  const handleReview = async (reviewData) => {
    const body = {
      ...reviewData,
      booking_id: booking.id,
    };

    dispatch(addReview(body))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم مراجعة الحجز بنجاح", {
          autoClose: 1000,
          draggablePercent: 60,
        });
        hideModelEvaluationRate();
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("فشل مراجعة الحجز. يرجى المحاولة مرة أخرى.");
      });
  };

  const handleCancelBooking = async () => {
    // first get the vendor condition for allowed time before cancel booking
    const vendor = await dispatch(getVendorSettings(booking.vendor_id)).unwrap();
    const differnceInMinutes = vendor.data.calender_differance;
    const bookingTime = new Date(`${booking.booking_day} ${booking.booking_time}`);
    const currentTime = new Date();
    const timeDifference = bookingTime - currentTime;
    const minutesDifference = Math.floor(timeDifference / 60000);
    if (minutesDifference < differnceInMinutes) {
      toast.error("لا يمكنك الغاء الحجز في هذا الوقت");
      return;
    }

    dispatch(cancelBooking(booking.id))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم الغاء الحجز بنجاح", {
          autoClose: 1000,
          draggablePercent: 60,
        });
        navigate(`/bookings`);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("فشل الغاء الحجز. يرجى المحاولة مرة أخرى.");
      });
  };

  return (
    <>
      <ModalReservationConfirmation
        showModalReservation={showModalReservation}
        hideModelReservation={hideModelReservation}
        onConfirm={handleRebook}
        title="إعادة الحجز"
        confirmText="تأكيد إعادة الحجز"
      />

      <ModalEvaluationRateStar
        showModalEvaluationRate={showModalReview}
        hideModelEvaluationRate={hideModelEvaluationRate}
        handleReview={handleReview}
        title="تقييم الحجز"
        confirmText="تأكيد تقييم الحجز"
        services={booking.booking_service}
        employee={booking.employee}
      />

      <div className="card-booking-coast style-card-details">
        <div className="title-header">
          <h2 className="title">تفاصيل</h2>
        </div>
        <div className="main-booking-details-card">
          <div className="header-top-det pb-3 border-bottom">
            {booking.servicesList.map(({ service }, index) => (
              <div key={index} className="head-details d-flex gap-3 justify-content-between pt-3">
                <div className="info-right">
                  <h2 className="title text-size">{service.name_ar}</h2>
                  <div className="time-info text-size pt-2">{service.service_time} دقيقة</div>
                </div>
                <div className="num-price text-size ">{service.price} ر.س</div>
              </div>
            ))}

            {booking.offer && (
              <div className="head-details d-flex gap-3 justify-content-between  pt-3">
                <div className="info-right">
                  <h2 className="title text-size">{booking.offer.name_ar}</h2>
                </div>
                <div className="num-price text-size ">{booking.offer.discount_percentage}%</div>
              </div>
            )}
          </div>
          <div className="center-info pb-3 border-bottom">
            <div className="head-details d-flex gap-3 justify-content-between  pt-3">
              <div className="info-right">
                <h2 className="title text-size">المجموع</h2>
              </div>
              <div className="num-price text-size ">{servicesTotal.toFixed(2)} ر.س</div>
            </div>
            {(booking.offer || booking.promocode_value) && (
              <div className="head-details discount-details d-flex gap-3 justify-content-between pt-3">
                <div className="info-right">
                  <h2 className="title text-size">
                    خصم {booking.offer ? `(${booking.offer.discount_percentage}%)` : ""}
                  </h2>
                </div>
                <div className="num-price text-size ">{discountAmount.toFixed(2)} ر.س</div>
              </div>
            )}
          </div>
          <div className="head-details d-flex gap-3 justify-content-between  pt-3">
            <div className="info-right">
              <h2 className="title text-size">الأجمالي</h2>
            </div>
            <div className="num-price text-size ">{finalTotal.toFixed(2)} ر.س</div>
          </div>

          {booking.status && (
            <>
              <div className="d-flex flex-column ">
                <button
                  onClick={openModalReservation}
                  className="main-btn main-outline-btn w-100 mt-3">
                  إعادة الحجز
                </button>
                <button onClick={generateCustomerInvoice} className="main-btn w-100 mt-3">
                  اصدار فاتورة
                </button>
              </div>
              <div className="d-flex flex-column ">
                <button
                  onClick={handleCancelBooking}
                  className="main-btn main-outline-btn w-100 mt-3">
                  الغاء الحجز
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingCoastDetails;

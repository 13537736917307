import { useField } from "formik";

const SelectField = ({ label, options, valueSelected, handleOnChange, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (e) => {
    const value = e.target.value;
    helpers.setValue(value);
    if (handleOnChange) handleOnChange(value);
  };

  console.log({ valueSelected });
  return (
    <div className="form-group">
      <label
        style={{
          marginBottom: "8px",
        }}
        htmlFor={props.id || props.name}>
        {label}
      </label>
      <select
        {...field}
        {...props}
        onChange={handleChange}
        className={`form-control ${meta.touched && meta.error ? "is-invalid" : ""}`}>
        <option value="">{valueSelected || "اختر..."}</option>
        {options?.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            selected={field.value === option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
};

export default SelectField;

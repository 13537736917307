import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "Components/CustomModal/CustomModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateBooking } from "store/vendor/vendorActions";

const UpdateBookingModal = ({ show, onHide, booking, onUpdate }) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState(booking?.notes || "");
  const [status, setStatus] = useState(booking?.status || "");
  const [paymentStatus, setPaymentStatus] = useState(booking?.payment_status || "0");

  const statusOptions = [
    { value: "compleated", text: "مكتمل" },
    { value: "canceled", text: "ملغي" },
    // { value: "confirmed", text: "مؤكد" },
    // { value: "notconfirmed", text: "غير مؤكد" },
    // { value: "notshown", text: "غير معروض" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updates = {
        id: booking.id,
        status: status,
        notes: notes,
      };

      if (status === "compleated") {
        updates.payment_status = "1";
      } else {
        updates.payment_status = paymentStatus;
      }

      const result = await dispatch(updateBooking(updates)).then(unwrapResult);
      toast.success("تم تحديث الحجز بنجاح");
      onUpdate(result);
      onHide();
    } catch (error) {
      console.error("Error updating booking:", error);
      toast.error("فشل تحديث الحجز");
    }
  };

  return (
    <CustomModal show={show} onHide={onHide} title="تحديث الحجز" newClass="modal-booking-update">
      <div className="form-modal">
        <form onSubmit={handleSubmit}>
          <div className="form-input-one mb-3">
            <label htmlFor="status" className="form-label">
              حالة الحجز
            </label>
            <select
              id="status"
              className="form-select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}>
              <option value="">اختر الحالة</option>
              {statusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          {booking?.payment_way === "cash" && (
            <div className="form-input-one mb-3">
              <label htmlFor="paymentStatus" className="form-label">
                حالة الدفع
              </label>
              <select
                id="paymentStatus"
                className="form-select"
                value={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.value)}>
                <option value="0">غير مدفوع</option>
                <option value="1">مدفوع</option>
              </select>
            </div>
          )}
          <div className="form-input-one mb-3">
            <label htmlFor="notes" className="form-label">
              لم يحضر العميل
            </label>
            <input
              type="checkbox"
              id="notShown"
              className="form-check-input"
              checked={notes === "did_not_show"}
              onChange={(e) => setNotes(e.target.checked ? "did_not_show" : "")}
            />
          </div>

          <button type="submit" className="main-btn w-100 mt-4">
            حفظ
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default UpdateBookingModal;

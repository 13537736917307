import { faCancel, faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "Components/CustomModal/CustomModal";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getBookings as getAdminBookings } from "store/admin/adminActions";
import { getBookings as getVendorBookings, updateBooking } from "store/vendor/vendorActions";
import { formatDateToArabic } from "utlis";
import UpdateBookingModal from "../Calendar/Modals/UpdateBookingModal";
import "./BookingDash.css";

const statusOptions = [
  { value: "compleated", text: "مكتمل" },
  { value: "canceled", text: "ملغي" },
];

const BookingsDashboard = () => {
  const user = useGetAuthUser();

  const [bookings, setBookings] = useState([]);
  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchVendorBookings = (params) => {
    setIsLoading(true);
    dispatch(getVendorBookings(params))
      .then(unwrapResult)
      .then((res) => {
        setBookings(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchAdminBookings = (params) => {
    setIsLoading(true);
    dispatch(getAdminBookings(params))
      .then(unwrapResult)
      .then((res) => {
        setBookings(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const action = user.type === "vendor" ? fetchVendorBookings : fetchAdminBookings;
    const paramsObject =
      user.type === "vendor"
        ? {
            with: ["vendor", "branch"],
            limit: limit,
            offset: (currentPage - 1) * limit,
            sort: "DESC",
            page: currentPage,
            paginate: "true",
            field: "id",
            deleted: "undeleted",
            resource: "all",
            resource_columns: ["id", "name_ar"],
            columns: ["vendor_id"],
            operand: ["="],
            column_values: [user?.model_id],
          }
        : {
            with: ["vendor", "branch"],
            limit: limit,
            offset: (currentPage - 1) * limit,
            sort: "DESC",
            sorting: "DESC",
            per_page: limit,
            page: currentPage,
            paginate: "true",
            field: "id",
            deleted: "undeleted",
            resource_columns: ["id", "name_ar"],
            resource: "all",
          };
    action(paramsObject);
  }, [limit, currentPage, user]);

  const toggleConfirmation = async (bookingId, confirmation) => {
    const updates = {
      id: bookingId,
      confirmation: confirmation === "confirmed" ? "not_confirmed" : "confirmed",
    };
    try {
      setIsLoading(true);
      dispatch(updateBooking(updates)).then(unwrapResult);
      fetchVendorBookings({
        with: ["vendor"],
        limit: limit,
        offset: (currentPage - 1) * limit,
        sort: "DESC",
        per_page: limit,
        page: currentPage,
        paginate: "true",
        field: "id",
        columns: ["vendor_id"],
        operand: ["="],
        column_values: [user?.model_id],
      });
    } catch (error) {
      toast.error("فشلت العملية");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelBooking = async (bookingId) => {
    setSelectedBookingId(bookingId);
    setShowCancelModal(true);
  };

  const confirmCancelBooking = async () => {
    try {
      setIsLoading(true);
      const updates = {
        id: selectedBookingId,
        status: "canceled",
        confirmation: "not_confirmed",
        is_active: "0",
      };

      await dispatch(updateBooking(updates)).then(unwrapResult);

      fetchVendorBookings({
        with: ["vendor"],
        limit: limit,
        offset: (currentPage - 1) * limit,
        sort: "DESC",
        order: "DESC",
        per_page: limit,
        page: currentPage,
        paginate: "true",
        field: "id",
        columns: ["vendor_id"],
        operand: ["="],
        column_values: [user?.model_id],
      });
      setShowCancelModal(false);
    } catch (error) {
      toast.error("فشلت العملية");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateBooking = (updatedBooking) => {
    const updatedBookings = bookings.map((booking) =>
      booking.id === updatedBooking.id ? updatedBooking : booking,
    );
    setBookings(updatedBookings);
    fetchVendorBookings({
      with: ["vendor"],
      limit: limit,
      offset: (currentPage - 1) * limit,
      sort: "DESC",
      per_page: limit,
      page: currentPage,
      paginate: "true",
      field: "id",
      columns: ["vendor_id"],
      operand: ["="],
      column_values: [user?.model_id],
    });
  };

  const handleOpenUpdateModal = (booking) => {
    setSelectedBooking(booking);
    setShowUpdateModal(true);
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <HelmetInfo titlePage={"الحجوزات"} />
      <div data-aos="fade-down">
        <HeaderPageInfo title={"الحجوزات"} isShowLeftContent={true} />
      </div>

      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">رقم الحجز</th>
                {user.type === "admin" && <th scope="col">مزود الخدمة</th>}
                <th scope="col">الفرع</th>
                <th scope="col">تاريخ انشاء الحجز</th>
                <th scope="col">حالة الحجز</th>
                <th scope="col">حالة الدفع</th>
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {bookings.length > 0 &&
                  bookings?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        {user.type === "admin" && <td>{item?.vendor?.name}</td>}
                        <td>{item?.branch?.name}</td>
                        <td>{formatDateToArabic(item?.created_at)}</td>
                        <td>
                          {statusOptions.find((status) => status.value === item.status)?.text ??
                            "--"}
                        </td>
                        <td>{item.payment_status === "0" ? "لم يتم" : "تم"}</td>
                        <td>
                          <div className="all-icons-info d-flex align-items-center gap-0">
                            <IconsTable
                              typeIcon1={faEye}
                              functionEye={() => {
                                navigate(`${item.id}`);
                              }}
                              typeIcon2={user.type === "vendor" ? faPenToSquare : null}
                              functionEdit={
                                user.type === "vendor" ? () => handleOpenUpdateModal(item) : null
                              }
                              typeIcon3={user.type === "vendor" ? faCancel : null}
                              functionDelete={
                                user.type === "vendor" ? () => handleCancelBooking(item.id) : null
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={(value) => setLimit(value)}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limitOptions={[5, 10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
      <CustomModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        title="تأكيد إلغاء الحجز">
        <div className="text-center">
          <p>هل أنت متأكد من إلغاء هذا الحجز؟</p>
          <div className="d-flex justify-content-center gap-3 mt-4">
            <button className="btn btn-danger" onClick={confirmCancelBooking}>
              تأكيد الإلغاء
            </button>
            <button className="btn btn-secondary" onClick={() => setShowCancelModal(false)}>
              إلغاء
            </button>
          </div>
        </div>
      </CustomModal>

      <UpdateBookingModal
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
        booking={selectedBooking}
        onUpdate={handleUpdateBooking}
      />
    </>
  );
};

export default BookingsDashboard;

import { unwrapResult } from "@reduxjs/toolkit";
import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOffersReport } from "../../../../store/vendor/vendorActions";
import "./ReportDetails.css";

const OffersReport = ({ bookingDay, setReportData, setReportHeaders }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [allBookings, setAllBookings] = useState([]);
  const [topOffers, setTopOffers] = useState({});
  const [totalOffersCount, setTotalOffersCount] = useState(0);

  useEffect(() => {
    const offerMap = new Map(offers.map((offer) => [offer.id, offer]));

    const offerCounts = new Map();
    allBookings.forEach((booking) => {
      const count = (offerCounts.get(booking.offer_id) || 0) + 1;
      offerCounts.set(booking.offer_id, count);
    });

    const topOffersArray = Array.from(offerCounts.entries())
      .map(([offerId, count]) => {
        const offer = offerMap.get(offerId);
        return {
          name: offer?.name_ar || "Unknown",
          bookings: count,
        };
      })
      .filter((x) => x.name !== "Unknown")
      .sort((a, b) => b.bookings - a.bookings)
      .slice(0, 5);

    setTopOffers(topOffersArray);
    setTotalOffersCount(topOffersArray.length);
    setReportData(topOffersArray);
    setReportHeaders(["العرض", "عدد الحجوزات"]);
  }, [offers, allBookings]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getOffersReport({
        with: ["bookings"],
        per_page: 500,
        // dateFrom: "2024-01-01",
        // dateTo: "2024-02-31",
        columns: ["created_at"],
        operand: [">="],
        column_values: [bookingDay],
      }),
    )
      .then(unwrapResult)
      .then((res) => {
        const offersList = res.data.data;
        setOffers(offersList);
        const vendorBookings = offersList[0].vendor.bookings;
        const filteredBookings = vendorBookings.filter(
          (booking) => booking.booking_day >= bookingDay,
        );
        setAllBookings(filteredBookings);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, bookingDay]);

  const offersReportData = {
    totalOffers: totalOffersCount,
    mostRequestedOffers: topOffers,
  };

  if (isLoading) return <Loader />;

  return (
    <div className="offers-report">
      <div className="report-summary">
        <div className="summary-card">
          <div className="card-content">
            <h3>{offersReportData.totalOffers}</h3>
            <p>عدد العروض</p>
          </div>
        </div>
      </div>

      <div className="report-table">
        <h3 className="mb-4">العروض الأكثر طلبا</h3>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>العرض</th>
              <th>عدد الحجوزات</th>
            </tr>
          </thead>
          <tbody>
            {offersReportData.mostRequestedOffers.map((offer, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{offer.name}</td>
                <td>{offer.bookings}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OffersReport;
